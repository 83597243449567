import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { alertService } from "services/alertService";
import { operationService } from "services/operationService";

import Alertdetail from "components/Alert/Alertdetail";

import ModalLayout from "./ModalLayout";
import WarningModel from "./WarningModel";

import { DeleteIcon, EditWhiteIcon, VideoIcon } from "constants/icons";

import { t } from "i18next";

import { uploadPlugin } from "utils/ckeditorUploader";

const QuizContent = ({
  quizQuestions,
  setQuizQuestions,
  setActiveQuiz,
  activeQuiz,
}) => {
  const handleDelete = (item) => {
    let newQuizes = quizQuestions;
    const index = newQuizes.indexOf(item);

    const del = newQuizes.splice(index, 1);

    setQuizQuestions((quizes) => quizes.filter((quiz) => quiz !== del));
  };

  return (
    <div className="quiz-content px-4">
      {quizQuestions?.map((item, id) => (
        <div
          key={id}
          className="quiz mt-4 border-primary p-4 position-relative"
        >
          <div
            className={`edit-quiz position-absolute d-flex py-2 ${
              item === activeQuiz ? "d-none" : ""
            }`}
          >
            <img
              src={EditWhiteIcon.SOURCE}
              alt="edit"
              className="cursor-pointer px-2"
              onClick={() => setActiveQuiz(item)}
              data-toggle="tooltip"
              data-placement="left"
              title="Edit"
            />
            <img
              src={DeleteIcon.WHITE}
              alt="delete"
              className="delete-icon cursor-pointer px-2"
              onClick={() => handleDelete(item)}
            />
          </div>
          <p className="quiz-title">{item?.question}</p>
          <div className="d-flex flex-wrap options mt-2">
            {Object.keys(item?.options).map((option, idx) => (
              <p
                key={idx}
                className={`me-4 ${
                  option === item.correct ? "correct-option" : ""
                }`}
              >{`${option}: ${item?.options[option]}`}</p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const QuizForm = ({
  quizQuestions,
  setQuizQuestions,
  activeQuiz,
  setActiveQuiz,
}) => {
  const [quizQuestion, setQuizQuestion] = useState("");
  const [correctOption, setCorrectOption] = useState("");
  const [isWarning, setIswarning] = useState(false);
  const [options, setOptions] = useState({
    A: "",
    B: "",
    C: "",
    D: "",
  });

  useEffect(() => {
    if (activeQuiz) {
      setQuizQuestion(activeQuiz?.question);
      setOptions(activeQuiz?.options);
      setCorrectOption(activeQuiz?.correct);
    }
  }, [activeQuiz]);

  const handleOption = (e) => {
    setOptions({
      ...options,
      [e.target.name]: e.target.value,
    });
  };

  const handleCorrectOption = (e, value) => {
    if (e.target.checked) {
      setCorrectOption(value);
    }
  };

  const clearForm = () => {
    setActiveQuiz(null);
    setCorrectOption("");
    setQuizQuestion("");
    setOptions({
      A: "",
      B: "",
      C: "",
      D: "",
    });
  };

  const addQuiz = (e) => {
    e.preventDefault();
    const quiz = {
      question: quizQuestion,
      options: options,
      correct: correctOption,
    };

    if (!quiz.correct) setIswarning(true);
    else {
      if (activeQuiz) {
        const index = quizQuestions.findIndex((item) => item === activeQuiz);
        let quizCopy = quizQuestions;

        quizCopy[index] = quiz;
        setActiveQuiz(quizCopy);
      } else {
        setQuizQuestions((quizQuestions) => [...quizQuestions, quiz]);
      }
      clearForm();
    }
  };

  return (
    <div className="quiz-setting px-4 border-top w-100">
      <h6 className="pt-4">
        {t("label.quiz")} {t("label.settings")}
      </h6>
      <div className="quiz-form">
        <div className="mb-3 row align-items-center">
          <Form.Group className="col-12 my-3">
            <Form.Label>
              {t("label.quiz")} {t("label.content")}
            </Form.Label>
            <Form.Control
              type="text"
              name="question"
              value={quizQuestion}
              onChange={(e) => setQuizQuestion(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2 col-lg-4 col-md-6 col-sm-12">
            <Form.Label>
              <Form.Check
                inline
                label="A"
                name="correct"
                type="radio"
                checked={correctOption === "A" ? true : false}
                onChange={() => {}}
                onClick={(e) => handleCorrectOption(e, "A")}
              />
            </Form.Label>
            <Form.Control
              type="text"
              name="A"
              value={options?.A}
              onChange={(e) => handleOption(e)}
            />
          </Form.Group>
          <Form.Group className="mt-2 col-lg-4 col-md-6 col-sm-12">
            <Form.Label>
              <Form.Check
                inline
                label="B"
                name="correct"
                type="radio"
                onChange={() => {}}
                checked={correctOption === "B" ? true : false}
                onClick={(e) => handleCorrectOption(e, "B")}
              />
            </Form.Label>
            <Form.Control
              type="text"
              name="B"
              value={options?.B}
              onChange={(e) => handleOption(e)}
            />
          </Form.Group>
          <Form.Group className="mt-2 col-lg-4 col-md-6 col-sm-12">
            <Form.Label>
              <Form.Check
                inline
                label="C"
                name="correct"
                type="radio"
                onChange={() => {}}
                checked={correctOption === "C" ? true : false}
                onClick={(e) => handleCorrectOption(e, "C")}
              />
            </Form.Label>
            <Form.Control
              type="text"
              name="C"
              value={options?.C}
              onChange={(e) => handleOption(e)}
            />
          </Form.Group>
          <Form.Group className="mt-2 col-lg-4 col-md-6 col-sm-12">
            <Form.Label>
              <Form.Check
                inline
                label="D"
                name="correct"
                type="radio"
                onChange={() => {}}
                checked={correctOption === "D" ? true : false}
                onClick={(e) => handleCorrectOption(e, "D")}
              />
            </Form.Label>
            <Form.Control
              type="text"
              name="D"
              value={options?.D}
              onChange={(e) => handleOption(e)}
            />
          </Form.Group>
        </div>
        <button
          className="col-12 alert-section pt-3 border-primary d-flex align-items-center justify-content-center"
          type="submit"
          onClick={addQuiz}
        >
          <h6>{`${
            activeQuiz
              ? `${t("action.update")} ${t("label.quiz")}`
              : `${t("action.add")} ${t("label.quiz")}`
          }`}</h6>
        </button>
      </div>
      <WarningModel
        isVisible={isWarning}
        setIsVisible={setIswarning}
        warningText={"you must select a correct answer"}
        confirmText="Okay"
      />
    </div>
  );
};

const OperationModel = ({
  isVisible,
  setIsVisible,
  modelDetails,
  setModelDetails,
  modelId,
  update,
  setUpdate,
  operationId,
}) => {
  const { t } = useTranslation();

  const [operation, setOperation] = useState({
    title: "",
    instruction: "",
    video: "",
    thumbnail: "",
  });
  const [oldVideo, setOldVideo] = useState();
  const [oldThumbnail, setOldThumbnail] = useState();

  const [instruction, setInstruction] = useState();
  const [editedModel, setEditedModel] = useState();
  const [quizQuestions, setQuizQuestions] = useState([]);

  const [activeQuiz, setActiveQuiz] = useState(null);

  const updateQuiz = (quizQuestions) => {
    const quizes = [];

    quizQuestions.map((item) => {
      const quiz = {
        question: item.question,
        correct: item.correct,
        options: {
          A: item.choice1,
          B: item.choice2,
          C: item.choice3,
          D: item.choice4,
        },
      };

      quizes.push(quiz);
    });

    return quizes;
  };

  useEffect(() => {
    setOldVideo();
    setOldThumbnail();
    setQuizQuestions([]);

    if (operationId) {
      operationService.getById(operationId).then((x) => {
        setOperation({
          title: x.title,
          instruction: x.instruction,
        });
        setOldVideo(x.video);
        setOldThumbnail(x.thumbnail);
        setQuizQuestions(updateQuiz(x.operations));
      });
    }
  }, [operationId]);

  useEffect(() => {
    if (modelDetails) {
      setEditedModel(modelDetails);
    }
  }, [modelDetails]);

  const handleChange = (e) => {
    setOperation({
      ...operation,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();

    if (operation?.thumbnail)
      form_data.append(
        "thumbnail",
        operation.thumbnail,
        operation.thumbnail.name
      );
    if (operation.video)
      form_data.append("video", operation.video, operation.video.name);
    form_data.append("title", operation.title);
    form_data.append("instruction", instruction);
    form_data.append("model", modelId);

    for (var i = 0; i < quizQuestions.length; i++) {
      const choices = {
        A: "choice1",
        B: "choice2",
        C: "choice3",
        D: "choice4",
      };

      Object.entries(quizQuestions[i]).map(([key, value]) => {
        if (typeof quizQuestions[i][key] === "object") {
          Object.keys(quizQuestions[i][key]).map((item) => {
            form_data.append(
              `quiz[${i}][${choices[item]}]`,
              quizQuestions[i][key][item]
            );
          });
        } else {
          form_data.append(`quiz[${i}][${key}]`, value);
        }
      });
    }

    if (operationId) {
      operationService
        .update(operationId, form_data)
        .then((res) => {
          alertService.success(
            `${t("label.operation")} ${t("message.addedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
          setModelDetails(editedModel);
          setOperation({
            title: "",
            instruction: "",
            video: "",
            thumbnail: "",
          });
          setQuizQuestions([]);
          setIsVisible(false);
        })
        .catch((err) => {
          alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        });
    } else {
      operationService
        .create(form_data)
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            alertService.success(
              `${t("label.operation")} ${t("message.addedSuccessfully")}`,
              {
                keepAfterRouteChange: true,
                autoClose: true,
              }
            );
            setUpdate(!update);
            setModelDetails(editedModel);
            setOperation({
              title: "",
              instruction: "",
              video: "",
              thumbnail: "",
            });
            setQuizQuestions([]);
            setIsVisible(false);
          } else {
            alertService.error(res.data, {
              keepAfterRouteChange: true,
              autoClose: true,
            });
          }
        })
        .catch((err) => {
          alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        });
    }
  };

  const handleFileUpload = (e) => {
    setOperation({
      ...operation,
      [e.target.name]: e.target.files[0],
    });
  };

  const inputHandler = (event, editor) => {
    const data = editor.getData();

    setInstruction(data);
  };

  const onVideoUpload = (e) => {
    let newData = { ...operation };

    newData["video"] = e.target.files[0];
    setOperation(newData);
  };

  const pickFile = (id) => {
    const inputField = document.getElementById(id);

    inputField.click();
  };

  function deleteOperation(id) {
    operationService.delete(id).then(() => {
      alertService.success("Deleted Successfully", {
        keepAfterRouteChange: true,
        autoClose: true,
      });
      setQuizQuestions([]);
      setIsVisible(false);
      setUpdate(!update);
    });
  }

  return (
    <ModalLayout
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      className="custom-modal box-shadow-primary"
    >
      <div className="modal-header py-3 px-5">
        <div className="title">{t("label.operation")}</div>
      </div>

      <Alertdetail msg="Alarm response for upstream blockage" />
      <div className="operation-modal flex-grow-1 h-100 content d-flex flex-column justify-content-end">
        <div className="p-4 row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <p>{t("label.searchForm.makerName")}</p>
            <p>{editedModel?.maker_name.name}</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <p>{t("label.searchForm.productName")}</p>
            <p>{editedModel?.product_name}</p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <p>{t("label.searchForm.modelNumber")} </p>
            <p>{editedModel?.model_number}</p>
          </div>
        </div>
        <hr />
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="edit-form">
            <div className="px-4">
              <div className="description-video row align-items-end mt-3">
                <Form.Group className="col-md-6 col-sm-12 mt-lg-2 mb-3">
                  <Form.Label>
                    {t("label.operation")} {t("label.form.name")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={operation?.title}
                    onChange={(e) => handleChange(e)}
                    accept="Image/*"
                  />
                </Form.Group>
                <Form.Group className="col-md-6 col-sm-12 mt-lg-2 mb-3">
                  <Form.Label>
                    {t("label.thumbnail")} {t("label.image")}
                  </Form.Label>
                  {operationId && (
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}${oldThumbnail}`}
                    >
                      {oldThumbnail}
                    </a>
                  )}
                  <InputGroup>
                    <Form.Control
                      name="thumbnail"
                      type="file"
                      onChange={(e) => handleFileUpload(e)}
                      id="thumbnail-image"
                    />
                    <InputGroup.Text
                      className="btn"
                      onClick={() => pickFile("thumbnail-image")}
                    >
                      {t("label.browse")}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <div className="text-editor col-lg-6 col-md-12 align-self-stretch">
                  <Form.Group className="mb-3">
                    <Form.Label className="sub-title">
                      {t("label.instruction")}
                    </Form.Label>
                    <CKEditor
                      id="inputText"
                      config={{
                        extraPlugins: [uploadPlugin],
                      }}
                      editor={ClassicEditor}
                      onChange={inputHandler}
                      data={operation?.instruction}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-md-12 align-self-stretch">
                  <Form.Group className="mb-3">
                    <Form.Label className="sub-title">
                      {t("label.uploadVideo")}
                    </Form.Label>
                    {operationId && (
                      <a href={`${process.env.REACT_APP_BASE_URL}${oldVideo}`}>
                        {oldVideo}
                      </a>
                    )}
                    <div className="upload-section cursor-pointer d-flex justify-content-center">
                      <input
                        type="file"
                        id="video-upload"
                        className="d-none"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={(e) => onVideoUpload(e)}
                        onDrop={(e) => onVideoUpload(e)}
                        onDragOver={(e) => e.preventDefault()}
                      />
                      <label htmlFor="video-upload">
                        <div className="text-center d-block w-100 py-3 d-flex align-items-center justify-content-center">
                          <div className="flex-grow-1">
                            <img
                              src={VideoIcon.SOURCE}
                              alt="upload file"
                              className="p-2"
                            />
                            <p>
                              {operation?.video?.name ||
                                t("message.clickhereOrDropVideo")}
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <QuizForm
                quizQuestions={quizQuestions}
                setQuizQuestions={setQuizQuestions}
                activeQuiz={activeQuiz}
                setActiveQuiz={setActiveQuiz}
              />
              <QuizContent
                quizQuestions={quizQuestions}
                setQuizQuestions={setQuizQuestions}
                setActiveQuiz={setActiveQuiz}
                activeQuiz={activeQuiz}
              />
            </div>
          </div>
          <div className="modal-footer px-5 py-3 d-flex flex-wrap jusitfy-content-end mt-4">
            {operationId && (
              <button
                className="btn btn-danger px-3 py-2 ms-2 mb-3"
                onClick={() => {
                  if (window.confirm("Are you sure you want delete?")) {
                    deleteOperation(operationId);
                  }
                }}
              >
                {t("action.delete")}
              </button>
            )}
            <button
              className="btn btn-secondary px-3 py-2 ms-2 mb-3"
              onClick={() => setIsVisible(false)}
            >
              {t("action.cancel")}
            </button>
            <button
              type="submit"
              className="btn btn-primary px-3 py-2 ms-2 mb-3"
            >
              {t("buttons.Submit")}
            </button>
          </div>
        </Form>
      </div>
    </ModalLayout>
  );
};

export default OperationModel;
