import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Image,
  Nav,
  Navbar,
  // NavDropdown,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BrandLogo, Flag_of_Japan, HamburgerIcon } from "constants/icons";
import { MEDIN, SITE } from "constants/routes";

import { AuthContext } from "context-api/AuthContext";
import { LanguageContext } from "context-api/LanguageContext";

import { t } from "i18next";

const Header = () => {
  const history = useHistory();
  const { isTokenValidated } = useContext(AuthContext);
  const { eng, handleLangChange } = useContext(LanguageContext);

  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);
  const [isNavOpened, setNavOpened] = useState(false);

  //here 96(px) - height of current header

  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 5);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const languageSelect = eng ? (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon={faGlobe} />
      <span className="ps-2">{t("label.language.english")}</span>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <Image src={Flag_of_Japan.SOURCE} width={12} />
      <span className="ps-2">{t("label.language.japanese")}</span>
    </div>
  );

  return (
    <div className="navbar-wrap">
      <Navbar
        expand="lg"
        fixed="top"
        className={`${
          isScrollValueMoreThanHeaderHeight ? "navbar-scrolled" : ""
        } ${isNavOpened ? "bg-light" : ""}`}
      >
        <Container>
          <Navbar.Brand className="navbar-brand" onClick={() => history.push(SITE.Index.path)}>
            <img src={BrandLogo.SOURCE} className="nav-logo cursor-pointer" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="kikisapoNavbar">
            <div
              className="hamburger-menu"
              onClick={() => setNavOpened(!isNavOpened)}
            >
              <img
                src={HamburgerIcon.SOURCE}
                alt="hamburger"
                className="hamburger-menu m-2"
              />
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="kikisapoNavbar">
            <Nav className="ms-auto align-items-lg-center">
              {isTokenValidated ? (
                <>
                  <li className="nav-item">
                    <Link to={SITE.ModelList.path} className="nav-link">
                      {t("label.modelList")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={SITE.LearningList.path} className="nav-link">
                      {t("label.learningList")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={SITE.Profile.path} className="nav-link">
                      {t("label.accountDetails")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      role={Button}
                      to={SITE.Logout.path}
                      className="nav-link login-btn"
                    >
                      {t("label.logout")}
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to={MEDIN.Index.path} className="nav-link">
                      {t("label.medicalInstitution")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={SITE.TermsofService.path} className="nav-link">
                      {t("label.termsofservice")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={SITE.Registration.path}
                      className="d-block signup-btn"
                    >
                      {t("label.SignUp")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={SITE.Login.path} className="d-block login-btn">
                      {t("label.Login")}
                    </Link>
                  </li>
                </>
              )}

              {/* <div className="lan-select ml-30">
                <NavDropdown
                  className="lang_select"
                  id="collasible-nav-dropdown"
                  align="end"
                  title={languageSelect}
                >
                  <NavDropdown.Item onClick={() => handleLangChange("en")}>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faGlobe} />
                      <span className="ps-2">
                        {t("label.language.english")}
                      </span>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleLangChange("ja")}>
                    <div className="d-flex align-items-center">
                      <Image src={Flag_of_Japan.SOURCE} width={12} />
                      <span className="ps-2">
                        {t("label.language.japanese")}
                      </span>
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
              </div> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="header-gutter"></div>
    </div>
  );
};

export default Header;
