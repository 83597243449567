import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import NotExistingPage from "pages/Site/404";

import { Alert } from "components/Alert/Alert";

import { ADMIN } from "constants/routes";

import { AuthContext } from "context-api/AuthContext";

const RouteWithLoader = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <>
        <Component {...props} />
      </>
    )}
  />
);

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const { userToken } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userToken) {
          return <Redirect to={{ pathname: ADMIN.Auth.path }} />;
        }

        return (
          <div className="position-relative">
            <main className="content-wrapper">
              <Component {...props} />
            </main>
            <Alert />
          </div>
        );
      }}
    />
  );
};

const AdminRoutes = () => (
  <Switch>
    <RouteWithLoader
      exact
      path={ADMIN.NotFound.path}
      component={NotExistingPage}
    />
    {/* pages */}
    {Object.keys(ADMIN).map((value, index) => {
      if (
        ADMIN[value] === "NotFound" ||
        ADMIN[value] === "Auth" ||
        ADMIN[value] === "Register" ||
        ADMIN[value] === "VerificationSent"
      )
        return null;

      return (
        <RouteWithSidebar
          exact
          path={ADMIN[value].path}
          component={ADMIN[value].component}
          key={index}
        />
      );
    })}

    <Redirect to={{ pathname: ADMIN.NotFound.path }} />
  </Switch>
);

export default AdminRoutes;
