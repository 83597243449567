import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { alertService } from "services/alertService";
import { genreService } from "services/genreService";
import { makerService } from "services/makerService";
import { modelService } from "services/modelService";
import { salesConditionService } from "services/salesConditionService";

import { BackIcon } from "constants/icons";
import { ADMIN } from "constants/routes";

import { t } from "i18next";

import { uploadPlugin } from "utils/ckeditorUploader";

const AddModal = () => {
  const history = useHistory();
  const [modelDetails, setModelDetails] = useState({
    maker: "",
    genre: "",
    product_name: "",
    model_number: "",
    service_duration: "",
    instruction: "",
    package_insert: "",
    sales_condition: "",
    product_inquiry: "",
    image: "",
  });
  const [makers, setMakers] = useState([]);
  const [genre, setGenres] = useState([]);
  const [instruction, setInstruction] = useState();
  const [salesConditions, setSalesConditions] = useState([]);

  const handleChange = (e) => {
    setModelDetails({
      ...modelDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    makerService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setMakers(x));
    genreService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setGenres(x));
    salesConditionService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setSalesConditions(x));
  }, []);

  const inputHandler = (event, editor) => {
    const data = editor.getData();

    setInstruction(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();

    if (modelDetails.image)
      form_data.append("image", modelDetails.image, modelDetails.image.name);
    if (modelDetails.instruction_mannual)
      form_data.append(
        "instruction_mannual",
        modelDetails.instruction_mannual,
        modelDetails.instruction_mannual.name
      );
    if (modelDetails.package_insert)
      form_data.append(
        "package_insert",
        modelDetails.instruction,
        modelDetails.instruction.name
      );
    form_data.append("instruction", instruction);
    form_data.append("product_name", modelDetails.product_name);
    form_data.append("model_number", modelDetails.model_number);
    form_data.append("service_duration", modelDetails.service_duration);
    form_data.append("product_inquiry", modelDetails.product_inquiry);
    form_data.append("maker", modelDetails.maker);
    form_data.append("genre", modelDetails.genre);
    form_data.append("sales_condition", modelDetails.sales_condition);

    modelService
      .create(form_data)
      .then((res) => {
        alertService.success(
          `${t("label.model")} ${t("message.addedSuccessfully")}`,
          {
            keepAfterRouteChange: true,
            autoClose: true,
          }
        );
        history.push(`${ADMIN.Modals.path}/view/${res.data.id}`);
      })
      .catch((err) => {
        alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
      });
  };

  const handleFileUpload = (e) => {
    setModelDetails({
      ...modelDetails,
      [e.target.name]: e.target.files[0],
    });
  };

  const makerOptions = makers?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  const genreOptions = genre?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  const salesConditionOptions = salesConditions?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  const pickFile = (id) => {
    const inputField = document.getElementById(id);

    inputField.click();
  };

  return (
    <div className="model-details box-shadow-primary p-2 p-md-3 p-lg-5  mt-5 mx-0 mx-lg-4">
      <div className="d-flex mb-xl-0 align-items-center">
        <img
          className="align-self-center mb-2 cursor-pointer"
          src={BackIcon.SOURCE}
          onClick={() => history.goBack()}
        />
        <h4 className="ms-3 user-name">
          {t("action.add")} {t("label.model")}
        </h4>
      </div>
      <div className="flex-grow-1 h-100 content d-flex flex-column justify-content-end px-3 px-md-2">
        <div className="edit-form pt-5 pb-1 pb-md-3">
          <Form className="pb-3 row" onSubmit={handleSubmit}>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.searchForm.maker")}</Form.Label>
              <Form.Select
                name="maker"
                defaultValue={modelDetails?.maker}
                onChange={(e) => handleChange(e)}
                required
              >
                <option value="">
                  {t("action.select")} {t("label.searchForm.maker")}
                </option>
                {makerOptions}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.searchForm.genre")}</Form.Label>
              <Form.Select
                name="genre"
                defaultValue={modelDetails?.genre}
                onChange={(e) => handleChange(e)}
                required
              >
                <option value="">
                  {t("action.select")} {t("label.searchForm.genre")}
                </option>
                {genreOptions}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.searchForm.productName")}</Form.Label>
              <Form.Control
                type="text"
                name="product_name"
                value={modelDetails?.product_name}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.searchForm.modelNumber")} </Form.Label>
              <Form.Control
                type="text"
                name="model_number"
                value={modelDetails?.model_number}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>
                {t("label.serviceDuration")} (in {t("label.years")})
              </Form.Label>
              <Form.Control
                type="number"
                min={0}
                name="service_duration"
                value={modelDetails?.service_duration}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.image")} </Form.Label>
              <InputGroup>
                <Form.Control
                  name="image"
                  type="file"
                  id="modal-image"
                  required
                  onChange={(e) => handleFileUpload(e)}
                />
                <InputGroup.Text
                  className="btn browse-btn"
                  onClick={() => pickFile("modal-image")}
                >
                  {t("label.browse")}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.instructionMannual")}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="file"
                  name="instruction"
                  id="instruction-mannual"
                  onChange={(e) => handleFileUpload(e)}
                />
                <InputGroup.Text
                  className="btn browse-btn"
                  onClick={() => pickFile("instruction-mannual")}
                >
                  {t("label.browse")}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.packageInsert")}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="file"
                  name="package_insert"
                  id="package-insert"
                  onChange={(e) => handleFileUpload(e)}
                />
                <InputGroup.Text
                  className="btn browse-btn"
                  onClick={() => pickFile("package-insert")}
                >
                  {t("label.browse")}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.salesCondition")}</Form.Label>
              <Form.Select
                name="sales_condition"
                defaultValue={modelDetails?.sales_condition}
                onChange={(e) => handleChange(e)}
                required
              >
                <option value="">
                  {t("action.select")} {t("label.salesCondition")}
                </option>
                {salesConditionOptions}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mb-3">
              <Form.Label>{t("label.productInquiry")}</Form.Label>
              <Form.Control
                type="text"
                name="product_inquiry"
                value={modelDetails?.product_inquiry}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <div className="text-editor col-lg-12 col-md-12 mb-3">
              <Form.Group className="mb-3">
                <Form.Label className="sub-title">
                  {t("label.instruction")}
                </Form.Label>
                <div className="form-control p-0 overflow-hidden">
                  <CKEditor
                    id="inputText"
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                    editor={ClassicEditor}
                    onChange={inputHandler}
                    data={modelDetails?.instruction}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="modal-footer py-3 mt-auto d-md-flex jusitfy-content-end">
              <button
                className="btn btn-primary px-3 py-2 ms-2 w-sm-100"
                type="submit"
              >
                {t("buttons.Submit")}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
