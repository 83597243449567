import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { alertService } from "services/alertService";
import { userService } from "services/userService";

import { BrandLogo, PasswordHideIcon, PasswordShowIcon } from "constants/icons";
import { SITE } from "constants/routes";

const ConfirmPasswordReset = ({ match }) => {
  const { t } = useTranslation();
  const location = useLocation();

  let user_id = "";
  let token = "";

  if (match !== undefined) {
    user_id = match.params.id;
    token = match.params.token;
  } else {
    user_id = null;
    token = null;
  }

  const { from } = location.state || { from: SITE.Login.path };

  const [isSubmitting, setSubmitting] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordShow, setNewPasswordShow] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [notMatchError, setNoMatchError] = useState(null);

  const submitNewPassword = (value) => {
    let valid =
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!#%/.*?&])[A-Za-z\d@$!#/%*.?&]{8,}$/.test(
        value
      );

    if (valid) {
      setNewPasswordError("");
      setNewPassword(value);
    } else {
      setNewPasswordError(t("message.mineightCharacter"));
      setNewPassword(value);
    }
  };

  const setRePassword = (value) => {
    if (value === newPassword) {
      setNoMatchError("");
      setConfirmPassword(value);
    } else {
      setNoMatchError(t("message.paswordMatchFailed"));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    userService
      .resetPassword({
        password1: newPassword,
        password2: confirmPassword,
        token: token,
        uidb64: user_id,
      })
      .then((res) => {
        alertService.success(
          `${t("label.form.password")} ${t("message.updatedSuccessfully")}`,
          {
            keepAfterRouteChange: true,
            autoClose: true,
          }
        );
        setSubmitting(false);
        window.location.href = from;
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setSubmitting(false);
      });
  };

  return (
    <div className="landing-page-wrapper">
      <div className="login-page d-block d-lg-flex justify-content-center align-items-center">
        <div className="container login-container">
          <div className="row">
            <div className="col-md-12 col-lg-6 p-0">
              <div className="company-branding d-flex justify-content-center align-items-center h-100">
                <div className="img-wrapper">
                  <img src={BrandLogo.SOURCE} alt="brand logo" />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="login-form-wrapper p-md-5 p-3">
                <div className="title-container text-center">
                  <h5>{t("label.changePassword")}</h5>
                </div>
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <Form.Group>
                    <Form.Label className="mt-2">
                      {t("label.form.newPassword")}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={newPasswordShow ? "text" : "password"}
                        name="newPassword"
                        placeholder="*******"
                        className="password-field"
                        onChange={(e) => submitNewPassword(e.target.value)}
                      />
                      <InputGroup.Text
                        className="icons"
                        onClick={() => setNewPasswordShow(!newPasswordShow)}
                      >
                        {newPasswordShow ? (
                          <img
                            src={PasswordHideIcon.SOURCE}
                            alt="hide password"
                          />
                        ) : (
                          <img
                            src={PasswordShowIcon.SOURCE}
                            alt="show password"
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    {newPasswordError !== "" && (
                      <Form.Text className="text-error">
                        {newPasswordError}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mt-2">
                      {t("label.form.confirmNewPassword")}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={confirmPasswordShow ? "text" : "password"}
                        placeholder="*******"
                        className="password-field"
                        onChange={(e) => setRePassword(e.target.value)}
                      />
                      <InputGroup.Text
                        className="icons"
                        onClick={() =>
                          setConfirmPasswordShow(!confirmPasswordShow)
                        }
                      >
                        {confirmPasswordShow ? (
                          <img
                            src={PasswordHideIcon.SOURCE}
                            alt="hide password"
                          />
                        ) : (
                          <img
                            src={PasswordShowIcon.SOURCE}
                            alt="show password"
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    {notMatchError !== "" && (
                      <Form.Text className="text-error">
                        {notMatchError}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <button
                    className="w-100 login-button mt-4"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("label.changePassword")}
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPasswordReset;
