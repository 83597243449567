import React from "react";

import ModalLayout from "./ModalLayout";

import { t } from "i18next";

const WarningModel = ({
  isVisible,
  setIsVisible,
  warningText,
  onConfirm,
  confirmText,
}) => (
  <ModalLayout
    isVisible={isVisible}
    onClose={() => setIsVisible(false)}
    className="custom-modal box-shadow-primary"
  >
    <div className="modal-header py-3 px-5 title">
      <h6>{t("action.warning")}</h6>
    </div>
    <div className="flex-grow-1 h-100 content d-flex flex-column justify-content-end">
      <div className="warning-text p-5">
        <p className="">{warningText}</p>
      </div>
      <div className="modal-footer px-5 py-3 mt-auto d-flex jusitfy-content-end">
        <button
          className="btn btn-secondary px-3 py-2 mx-2"
          onClick={() => setIsVisible(false)}
        >
          {t("action.cancel")}
        </button>
        <button className="btn btn-primary px-3 py-2 ms-2" onClick={onConfirm}>
          {confirmText}
        </button>
      </div>
    </div>
  </ModalLayout>
);

export default WarningModel;
