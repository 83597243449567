const translationJa = {
  label: {
    kiwi: "Kiwi紹介文",
    Kikisapo: "キキサポ",
    SignUp: "サインアップ",
    Login: "ログイン",
    advance: "前進",
    search: "検索",
    model: "機種",
    modelList: "機種一覧",
    learningList: "学習リスト",
    accountDetails: "アカウント詳細",
    modelDetail: "機種詳細",
    medicalInstitution: "医療機関",
    termsofservice: "利用規約",
    privacyPolicy: "プライバシーポリシー",
    logout: "ログアウト",
    user: "ユーザー",
    date: "日付",
    operation: "操作",
    dashboard: "ダッシュボード",
    changePassword: "パスワードを変更する",
    FAQ: "よくある質問",
    description: "説明",
    makers: "メーカー",
    termsOfService: "（仮）キキサポサービス利用規約",
    privacyPolicy: "仮）キキサポ プリバシーポリシー",
    date: "日付",
    updateAt: "に更新",
    inquiry: "お問い合わせ",
    id: "Id",
    profile: "プロフィール",
    Resultsfor: "の結果",
    signingUp: "サインアップ中",
    Male: "男",
    Female: "女",
    Others: "その他",
    form: {
      question: "質問",
      UserIdEmail: "ユーザーID（メールアドレス）必須",
      email: "メールアドレス",
      name: "名",
      surname: "姓",
      furigana: "フリガナ",
      namek: "名（カタカナ）",
      surnamek: "姓（カタカナ）",
      occupation: "職種",
      placeofwork: "義務先",
      med_lic_no: "医療者免許番号",
      address: "住所",
      dob: "生年月日",
      gender: "性別",
      phoneNum: "電話番号",
      password: "パスワード",
      confirmPassword: "パスワード確認",
      AgreeTandC: "利用規約に同意する",
      message: "メッセージを書く",
      action: "アクション",
      oldPassword: "以前のパスワード",
      newPassword: "新しいパスワード",
      confirmNewPassword: "新しいパスワードを確定する",
    },
    searchForm: {
      genre: "ジャンル",
      maker: "メーカー",
      makerName: "メーカー名",
      productName: "商品名",
      modelNumber: "モデル番号",
      learningCondition: "学習条件",
    },
    language: {
      english: "English",
      japanese: "日本",
    },
    forgotPass: "パスワードをお忘れですか？",
    dontHaveAccount: "まだアカウントを持っていませんか?",
    alreadyHaveAccount: "すでにアカウントをお持ちですか？",
    sendResetCode: "リセットコードを送信",
    ourFeatures: "キキサポとは",
    haveaQuery: "質問がある？",
    quickContact: "急連絡先",
    fillYourForm: "フォームに記入",
    aboutKikisapo: "キキサポとは",
    ourFeatures: "私たちの特徴",
    proceedWithCaptcha: "キャプチャーを続行する",
    captcha: "キャプチャ",
    dashboard: "ダッシュボード",
    overview: "概要",
    total: "合計",
    totalUsers: "合計ユーザー",
    totalNewUsers: "合計新ユーザー",
    totalModels: "合計機種",
    totalMedicalInstitutions: "合計医療機関",
    overallInformation: "全体的な情報",
    viewAll: "全部見る",
    achievement: "学習完了",
    makerName: "メーカー名",
    modelName: "機種名",
    personIncharge: "担当者",
    nameofMedicalInstitution: "医療機関名",
    occupations: "職業",
    serviceDuration: "サービス期間",
    image: "写真",
    changeImage: "写真変更",
    thumbnail: "サムネイル",
    browse: "ブラウズ",
    instruction: "指示",
    instructionMannual: "指示マニュアル",
    packageInsert: "パッケージをいれる",
    salesCondition: "販売条件",
    productInquiry: "製品問い合わせ",
    affilatedMedicalInstitute: "提携医療機関",
    resultPerPage: "ページごとの結果",
    showperPage: "ページごと表示",
    years: "年",
    uploadVideo: "動画アップロードする",
    quiz: "クイズ",
    settings: "設定",
    content: "コンテンツ",
    comment: "コメント",
    congratulations: "おめでとう",
    badge: "バッジ",
    Showing: "表示中",
    to: "に",
    of: "の",
    entries: "イントリ",
    viewDetails: "詳細を見る",
    faq: "よくある質問",
    productNameOrModelNumber: "機種名または型番を入力してください",
    rememberMe: "Remember me",
  },
  buttons: {
    Next: "次",
    Submit: "参加する",
    edit: "編集",
    Confirm: "確認",
  },
  action: {
    select: "選択する",
    add: "追加",
    cancel: "キャンセル",
    list: "リスト",
    update: "アップデート",
    warning: "警告",
    enter: "入力",
    filter: "フィルター",
    delete: "消去",
  },
  message: {
    Checkyourmail: "メールをチェックしてください",
    noResultsFound: "結果が見つかりません",
    startLearning: "学び始める",
    getMedicalToolsReadywithUs: "私たちと一緒に医療ツールを準備しましょう。",
    weareHereToHelpYou: "私たちはあなたを助けるためにここにいます。",
    editYourInformation: "あなたの情報を編集する",
    heroTitle: "KiwiのMission",
    heroContent:
      "私たちは、医療機器に関わるすべてのひとに安全と安心を届けます。",
    featureContent:
      "院内には古い機種から新しい機種まで様々な医療機器が蔓延しています。医療機器のわからないを解決するとともに、臨床の声を医療機器メーカーへ届け、より良い安全な医療機器開発へ繋げてもらうためのサービスです。",
    aboutContent:
      "キキサポは医療機器の正しい使い方をわかりやすく提供するサービスです。医療機器がなければ治療も診断もできない時代…。",
    verifyYourEmail: "あなたのメールを確認する",
    verificationMessage1: "にメールを送信しました",
    verificationMessage2:
      "メールアドレスを確認し、アカウントを有効にします。メール内のリンクは 24 時間以内にエクスポートされます。",
    warningonModalQuit:
      "本当に戻ってきますか。機種 リストに移動したい場合は、データが失われます。",
    pleaseEnterInformation: "情報を入力してください",
    frequentlyAskedQuestion: "お問い合わせ",
    copyright: "© 2022 株式会社 Kiwi All Rights Reserved",
    addedSuccessfully: "追加成功",
    updatedSuccessfully: "変新成功",
    deletedSuccessfuly: "削除成功",
    enrolledSuccesfully: "登録成功",
    clickhereOrDropVideo:
      "ここをクリックするか、ビデオをドラッグアンドドロップする",
    contactFormSubmitted: "お問い合わせフォーム送信成功",
    lockedStateMessage:
      "この製品のロックを解除する場合は、[学習の開始]ボタンをクリックして、",
    quizcompleted: "あなたはクイズを無事に完了し。。。。。獲得しました。",
    goldBadge: "シルバーバッジ",
    silverBadge: "ごルドバッジ",
    writeacomment: "コメントを書く",
    paswordMatchFailed: "パスワードが一致しませんでした",
    mineightCharacter: "最低 8 文字、最低 1 つの数字",
    medicallicensenoSpecialChar: "医療免許には特殊文字を使用できません",
    invalidEmail: "無効なメールアドレス",
    invalidPhoneNumber: "電話番号が無効",
    failed: "失敗した",
    sorryYouFailedQuiz:
      "申し訳ありませんが、クイズの合格点を獲得できませんでした",
    clickVerifyButtonToActivateYourAccoutnt:
      "確認ボタンをクリックして、アカウントを有効にする",
    pleaseEnterValidEmailorPassword:
      "有効なメールアドレスまたはパスワードを入力してください",
    loginSuccessfull: "ログイン成功",
    passwordResetLinkHasBeenSend:
      "We have send you the reset link in you email account. Please Verify from the link.",
  },
};

export default translationJa;
