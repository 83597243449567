import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { ROLES } from "constants/roles";
import { ADMIN, SITE } from "constants/routes";

import { AuthContext } from "context-api/AuthContext";

export function AdminProtectedRoute({ children, ...rest }) {
  const { userToken, currentUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={() => {
        if (userToken && currentUser?.roles?.key === ROLES.ADMIN.key) {
          return children;
        }
        if (!userToken) {
          // localStorage.removeItem("token");
          return <Redirect to={{ pathname: ADMIN.Auth.path }} />;
        }
      }}
    />
  );
}
export function UserProtectedRoute({ children, ...rest }) {
  const { userToken, currentUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={() => {
        if (userToken && currentUser?.roles?.key === ROLES.NORMAL.key) {
          return children;
        }
        if (!userToken) {
          // localStorage.removeItem("token");
          return <Redirect to={{ pathname: SITE.Index.path }} />;
        }

        return null;
      }}
    />
  );
}
