import React, { useState, createContext, useEffect } from "react";

import { authService } from "services/authService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userUpdated, setuserUpdated] = useState(false);
  const [userToken, setUserToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    if (userToken || isLoggedIn) {
      setIsTokenValidated(true);
    } else {
      setIsTokenValidated(false);
    }
  }, [userToken, isLoggedIn]);

  useEffect(() => {
    authService
      .getUserToken(userToken)
      .then((res) => {
        setCurrentUser(res);
        if (res.status === 401) {
          localStorage.removeItem("token");
          setUserToken();
        } else {
          let token = localStorage.getItem("token");

          setUserToken(token);
        }
      })
      .catch((err) => {
        localStorage.removeItem("token");

        return err;
      });
  }, [userUpdated]);

  return (
    <AuthContext.Provider
      value={{
        isTokenValidated,
        setUserToken,
        userToken,
        setIsLoggedIn,
        currentUser,
        setuserUpdated,
        userUpdated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
