import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import AdminHome from "pages/Admin/AdminRoutes";
import Login from "pages/Admin/Login/Login";
import Registration from "pages/Admin/Registration/Registration";

import ForgotPassword from "components/Auth/ForgotPassword";
import VerificationSent from "components/Auth/RegisterEmailSent";
import DashboardLayout from "components/layout/DashboardLayout";
import Logout from "components/Logout/Logout";

import { ADMIN } from "constants/routes";

import AdminContextContainer from "context-api/AdminContextContainer";

import { AdminProtectedRoute } from "lib/ProtectedRoute";

function AdminRoutes() {
  return (
    <Router>
      <AdminContextContainer>
        <AdminProtectedRoute path={ADMIN.Index.path}>
          <DashboardLayout>
            <AdminHome />
          </DashboardLayout>
        </AdminProtectedRoute>

        <Route path={ADMIN.VerificationSent.path} exact>
          <VerificationSent />
        </Route>
        <Route path={ADMIN.Auth.path} exact>
          <Login />
        </Route>
        <Route path={ADMIN.Register.path} exact>
          <Registration />
        </Route>
        <Route path={ADMIN.ForgotPassword.path} exact>
          <ForgotPassword />
        </Route>

        <Route path={ADMIN.Logout.path} exact>
          <Logout />
        </Route>
      </AdminContextContainer>
    </Router>
  );
}

const Admin = () => <AdminRoutes />;

export default Admin;
