import React, { useEffect, useState } from "react";

import { termsOfService } from "services/termsOfService";

import UserLayout from "components/UserLayout/UserLayout";

import { t } from "i18next";

const TermsOfService = () => {
  const [termsofService, setTermsofService] = useState();

  useEffect(() => {
    termsOfService
      .get(`?paginate=false&latest=true`)
      .then((x) => setTermsofService(x[0]));
  }, []);

  return (
    <>
      <UserLayout className="landing-page-wrapper">
        <div className="inquiry-container h-100">
          <div className="container">
            <div className="">
              <p className="title my-4 text-center">{t("label.termsOfService")}</p>
              <div
                className="description info p-3"
                dangerouslySetInnerHTML={{ __html: termsofService?.text }}
              ></div>
            </div>
          </div>
        </div>
      </UserLayout>
    </>
  );
};

export default TermsOfService;
