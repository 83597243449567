import React from "react";

import { AuthProvider } from "./AuthContext";
import { LanguageProvider } from "./LanguageContext";

const HomeContextContainer = ({ children }) => (
  <LanguageProvider>
    <AuthProvider>{children}</AuthProvider>
  </LanguageProvider>
);

export default HomeContextContainer;
