import React from "react";

export default ({ children, show, className }) =>
  show ? (
    <div
      id="preloader"
      className={`preloader ${
        show ? "d-flex justify-content-center align-items center" : "show"
      } ${className}`}
    >
      <div className="animation-preloader d-flex align-items-center flex-column">
        <div className="spinner"></div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
