import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { genreService } from "services/genreService";
import { makerService } from "services/makerService";

const SearchBox = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [makers, setMakers] = useState([]);
  const [genre, setGenres] = useState([]);

  useEffect(() => {
    makerService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setMakers(x));
    genreService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setGenres(x));
  }, []);

  const makerOptions = makers?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  const genreOptions = genre?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  return (
    <div className="search-container position-relative clearfix">
      <button onClick={() => history.goBack()} className="float-end">
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="d-flex justify-content-between pt-4">
        <input
          type="text"
          id="search"
          name="search"
          className="search-input"
          placeholder={t("label.search")}
        />
      </div>
      <div className="accordion my-3" id={`drop-search`}>
        <div className="">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button
              className="accordion-button advance-search py-2 px-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#dropdown-search`}
              aria-controls={`panelsStayOpen-collapse-search`}
            >
              <h6 className="mt-2">
                {t("label.advance")} {t("label.search")}
              </h6>
            </button>
          </h2>
          <div
            id={`dropdown-search`}
            className="accordion-collapse collapse "
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="accordion-body advance-search-body p-0">
              <div className="form-group row m-0">
                <div className="col-12">
                  <label>{t("label.searchForm.genre")}</label>
                  <br />
                  <select
                    className="univ-input"
                    placeholder={t("label.searchForm.genre")}
                    name="genre"
                  >
                    <option value="">
                      {t("action.select")} {t("label.searchForm.genre")}
                    </option>
                    {genreOptions}
                  </select>
                </div>
                <div className="col-12">
                  <label>{t("label.makers")}</label>
                  <br />
                  <select className="univ-input" name="maker">
                    <option value="">
                      {t("action.select")} {t("label.makers")}
                    </option>
                    {makerOptions}
                  </select>
                </div>

                <div className="col-12">
                  <label>
                    {t("label.searchForm.productName")}/
                    {t("label.searchForm.modelNumber")}
                  </label>
                  <input
                    className="univ-input"
                    name="productName"
                    placeholder={`${t("action.enter")} ${t(
                      "label.searchForm.productName"
                    )} / ${t("label.searchForm.modelNumber")}`}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button className="advance-search-btn  w-100 ms-0" type="submit">
            {t("label.search")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
