import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import { Alert } from "components/Alert/Alert";

import Admin from "./Admin";
import Site from "./Site";

import { ADMIN, SITE } from "constants/routes";

function AppRoutes() {
  return (
    <Router>
      <div className="position-relative">
        <Switch>
          <Route path={ADMIN.Index.path}>
            <Admin />
          </Route>
          <Route path={SITE.Index.path}>
            <Site />
          </Route>
        </Switch>
        <Alert />
      </div>
    </Router>
  );
}

const App = () => <AppRoutes />;

export default App;
