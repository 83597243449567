const translationJa = {
  label: {
    kiwi: "Kiwi紹介文",
    Kikisapo: "Kikisapo",
    SignUp: "Sign Up",
    Login: "Login",
    advance: "Advance",
    search: "Search",
    model: "Model",
    modelList: "Model List",
    learningList: "Learning List",
    accountDetails: "Account Details",
    modelDetail: "Model Detail",
    medicalInstitution: "Medical Institution",
    termsofservice: "Terms of Service",
    privacyPolicy: "Privacy and Policy",
    logout: "Logout",
    user: "User",
    date: "Date",
    operation: "Operation",
    dashboard: "Dashboard",
    changePassword: "Change Password",
    FAQ: "FAQ",
    description: "Description",
    makers: "Makers",
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    date: "Date",
    updateAt: "Update At",
    inquiry: "Inquiry",
    id: "Id",
    profile: "Profile",
    Resultsfor: "Results for",
    signingUp: "Signing Up",
    Male: "Male",
    Female: "Female",
    Others: "Others",
    form: {
      question: "Question",
      UserIdEmail: "Userid(Email)",
      email: "Email",
      name: "Name",
      surname: "Surname",
      furigana: "Furigana",
      namek: "Name (katakana)",
      surnamek: "Surname (katakana)",
      occupation: "Ocupation",
      placeofwork: "Place of Work",
      med_lic_no: "Medical license number",
      address: "Address",
      dob: "Date of Birth",
      gender: "Gender",
      phoneNum: "Phone Number",
      password: "Password",
      confirmPassword: "Confirm Password",
      AgreeTandC: "Agree terms and conditions",
      message: "Write Message",
      action: "Action",
      oldPassword: "Old Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
    },
    searchForm: {
      genre: "Genre",
      maker: "Maker",
      makerName: "Maker Name",
      productName: "Product Name",
      modelNumber: "Model Number",
      learningCondition: "Learning Condition",
    },
    language: {
      english: "English",
      japanese: "Japanese",
    },
    forgotPass: "Forgot Password?",
    dontHaveAccount: "Don't have an account yet?",
    alreadyHaveAccount: "Already have an account?",
    sendResetCode: "Send reset code",
    ourFeatures: "Our Features",
    haveaQuery: "Have a query ?",
    quickContact: "Quick Contact",
    fillYourForm: "Fill your form",
    aboutKikisapo: "About Kikisapo",
    ourFeatures: "Our Features",
    proceedWithCaptcha: "Proceed with captcha",
    captcha: "Captcha",
    dashboard: "Dashboard",
    overview: "Overview",
    total: "Total",
    totalUsers: "Total Users",
    totalNewUsers: "Total New Users",
    totalModels: "Total Models",
    totalMedicalInstitutions: "Total Medical Institutions",
    overallInformation: "Overall Information",
    viewAll: "View All",
    achievement: "Achievement",
    makerName: "Maker Name",
    modelName: "Model Name",
    personIncharge: "Person in charge",
    nameofMedicalInstitution: "Name of Medical Institution",
    occupations: "Occupations",
    serviceDuration: "Service Duration",
    image: "Image",
    changeImage: "Change Image",
    thumbnail: "Thumbnail",
    browse: "Browse",
    instruction: "Instruction",
    instructionMannual: "Instruction Mannual",
    packageInsert: "Package Insert",
    salesCondition: "Sales Condition",
    productInquiry: "Product Inquiry",
    affilatedMedicalInstitute: "Affiliated Medical Institution",
    resultPerPage: "Result per page",
    showperPage: "Show per Page",
    years: "years",
    uploadVideo: "Upload Video",
    quiz: "Quiz",
    settings: "settings",
    content: "Content",
    comment: "Comments",
    congratulations: "Congratulations",
    badge: "badge",
    Showing: "Showing",
    to: "to",
    of: "of",
    entries: "entries",
    viewDetails: "View Details",
    faq: "Frequently asked question",
    productNameOrModelNumber: "Enter Model Name or Model Number",
    rememberMe: "Remember me",
  },
  buttons: {
    Next: "Next",
    Submit: "Submit",
    edit: "Edit",
    Confirm: "Confirm",
  },
  action: {
    select: "Select",
    add: "Add",
    cancel: "Cancel",
    list: "List",
    update: "Update",
    warning: "Warning",
    enter: "Enter",
    filter: "Filter",
    delete: "Delete",
  },
  message: {
    Checkyourmail: "Check your mail",
    noResultsFound: "No Results Found",
    startLearning: "Start Learning",
    getMedicalToolsReadywithUs: "Get Medical Tools ready with us.",
    weareHereToHelpYou: "We are Here To Help You.",
    editYourInformation: "Edit your information",
    heroTitle: "KiwiのMission",
    heroContent:
      "We deliver safety and security to everyone involved in medical devices.",
    featureContent:
      "Resolving questions about medical devices and listening to clinical voices to medical device manufacturers and lead to the development of better and safer medical devices.",
    aboutContent:
      "Kikisapo is a service that provides easy-to-understand instructions on how to properly use medical devices. We live in an era where treatment and diagnosis are impossible without medical equipment. From old machines to new machines in the hospital Various medical devices are prevalent.",
    verifyYourEmail: "Verify your email",
    verificationMessage1: "We've sent and email to",
    verificationMessage2:
      "to verify your email address and activate your account. The link in the email will export in 24 hours.",
    warningonModalQuit:
      "Are you sure that you want back. If you want to go to the model list then your data will be lost.",
    pleaseEnterInformation: "Please enter the information",
    frequentlyAskedQuestion: "Frequently asked question",
    copyright: "© 2022 株式会社 Kiwi All Rights Reserved",
    addedSuccessfully: "added Successfuly",
    updatedSuccessfully: "updated Successfully",
    deletedSuccessfuly: "Deleted Successfully",
    enrolledSuccesfully: "Enrolled Successfuly",
    clickhereOrDropVideo: "Click here or drag and drop the video",
    contactFormSubmitted: "Contact form submitted successfully",
    lockedStateMessage:
      "If you want unlock this product you should click the Start Learning button and continue with video for the first operation and play the quiz and after completion of video.",
    quizcompleted: "You have completed the Quiz successfully and earned the",
    goldBadge: "Gold badge",
    silverBadge: "Silver badge",
    writeacomment: "Write a Comment",
    paswordMatchFailed: "Password didn't matched",
    mineightCharacter:
      "minimum eight characters, at least one number and special character",
    medicallicensenoSpecialChar:
      "Medical license should not have special characters",
    invalidEmail: "Invalid email address",
    invalidPhoneNumber: "Invalid phone number",
    failed: "Failed",
    sorryYouFailedQuiz:
      "Sorry, you have failed to score the pass marks of Quiz.",
    clickVerifyButtonToActivateYourAccoutnt:
      "Click the verify Button to activate your account.",
    pleaseEnterValidEmailorPassword: "Please enter valid email or password",
    loginSuccessfull: "Login Succssfull",
    passwordResetLinkHasBeenSend:
      "We have send you the reset link in you email account. Please Verify from the link.",
  },
};

export default translationJa;
