export const ROLES = {
  ADMIN: {
    name: "ADMIN_USER",
    key: "8g3UTfqPNZDBhtuu9EqV9mm5FScX0Fa4",
  },
  NORMAL: {
    name: "NORMAL_USER",
    key: "70V28QzmFEC47AN4gOAODP9Kku3MTukC",
  },
  MEDICAL: {
    name: "MEDICAL_INSTITUTION",
    key: "pmj5as2BkRmaNV5uOfV9OiCQDtq8sP2j",
  },
};
