import React from 'react';
import ReactDOM from 'react-dom/client';

import "assets/scss/index.scss";

import "i18n/root";

import App from './containers/App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
