import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { alertService } from "services/alertService";
import { authService } from "services/authService";

import { BrandLogo } from "constants/icons";
import { SITE } from "constants/routes";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const { from } = location.state || { from: SITE.ResetLinkSent.path };

    authService
      .forgotPassword(email)
      .then((res) => {
        alertService.success(res.detail, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setSubmitting(false);
        window.location.href = from;
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setSubmitting(false);
      });
  };

  return (
    <div className="landing-page-wrapper">
      <div className="login-page d-block d-lg-flex justify-content-center align-items-center">
        <div className="container login-container">
          <div className="row">
            <div className="col-md-12 col-lg-6 p-0">
              <div className="company-branding d-flex justify-content-center align-items-center h-100">
                <div className="img-wrapper">
                  <img src={BrandLogo.SOURCE} alt="brand logo" />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 ">
              <div className="login-form-wrapper p-md-5 p-3">
                <div className="mb-3">
                  <h3>{t("label.forgotPass")}</h3>
                  <p>{t("message.pleaseEnterInformation")}</p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>{t("label.form.UserIdEmail")}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={`${t("action.enter")} ${t(
                        "label.form.email"
                      )}`}
                      className="credentials-input"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <button
                    className="w-100 login-button mt-4"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("label.sendResetCode")}
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
