import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { alertService } from "services/alertService";
import { genreService } from "services/genreService";

import { CustomTable } from "./CustomTable";

import { DeleteIcon, EditIcon } from "constants/icons";

const TableRow = ({
  data,
  columns,
  deleteGenre,
  setActiveGenre,
  activeGenre,
  searchTerm
}) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.created_at}</td>
      <td className="text-wrap">{pt.name}</td>
      <td className="text-wrap">{pt.updated_at}</td>
      <td className="text-wrap">
        <div
          className={`action-icons ${pt.id === activeGenre ? "d-none" : ""}`}
        >
          <img
            src={EditIcon.SOURCE}
            className="ms-2 cursor-pointer"
            onClick={() => setActiveGenre(pt.id)}
          />
          <img
            src={DeleteIcon.SOURCE}
            className="mx-2  cursor-pointer"
            onClick={() => deleteGenre(pt.id)}
          />
        </div>
      </td>
    </tr>
  ));
};

const GenreTable = () => {
  const { t } = useTranslation();

  const [activeGenre, setActiveGenre] = useState("");
  const [newGenre, setNewGenre] = useState("");
  const [genres, setGenres] = useState([]);
  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    { name: t("label.date") },
    { name: t("label.form.name") },
    { name: t("label.updateAt") },
    { name: t("label.form.action") },
  ];

  useEffect(() => {
    genreService.get("?orderBy=desc&sortBy=id").then((x) => setGenres(x));
  }, [update]);

  function getUpdatedData(pagedata) {
    genreService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setGenres(x));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();

    form_data.append("name", newGenre);

    if (!activeGenre) {
      addGenre(form_data);
    } else {
      updateGenre(activeGenre, form_data);
    }
  };

  const addGenre = (fields) => {
    genreService
      .create(fields)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          alertService.success(
            `${t("label.searchForm.genre")} ${t("message.addedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setNewGenre("");
  };

  const updateGenre = (id, fields) => {
    genreService
      .update(id, fields)
      .then((res) => {
        if (res.status === 200) {
          alertService.success(
            `${t("label.searchForm.genre")} ${t(
              "message.updatedSuccessfully"
            )}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setActiveGenre("");
    setNewGenre("");
  };

  const deleteGenre = (id) => {
    genreService.delete(id).then(() => {
      alertService.success(t("message.deletedSuccessfuly"), {
        keepAfterRouteChange: true,
        autoClose: true,
      });
      setUpdate(!update);
    });
  };

  useEffect(() => {
    if (activeGenre) {
      genreService.getById(activeGenre).then((x) => setNewGenre(x.name));
    }
  }, [activeGenre]);

  useEffect(() => {
    if (searchInput)
      genreService.filter(`?search=${searchInput}`).then((x) => setGenres(x));
  }, [searchInput]);

  return (
    <div className="p-3 mt-5 h-100">
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end mb-3">
        <div className="d-block mb-4 mb-xl-0">
          <h4>{t("label.searchForm.genre")}</h4>
        </div>
      </div>
      <div className="border-bottom pb-3 mb-4">
        <Form className="d-flex " onSubmit={handleSubmit}>
          <Form.Group className="">
            <Form.Label className="me-2">{t("label.form.name")}:</Form.Label>
            <div className="d-flex">
              <Form.Control
                className="input-field w-100"
                type="text"
                name="name"
                value={newGenre}
                required
                onChange={(e) => setNewGenre(e.target.value)}
              />

              <div className="text-align-center ms-2 col-lg-5">
                <button className="btn add-btn px-3 py-2 w-100" type="submit">
                  {`${activeGenre ? t("action.update") : t("action.add")} ${t(
                    "label.searchForm.genre"
                  )}`}
                </button>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
      <CustomTable
        columns={columns}
        data={genres}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
        setSearchInput={setSearchInput}
      >
        <TableRow
          data={genres?.results}
          columns={columns}
          setActiveGenre={setActiveGenre}
          activeGenre={activeGenre}
          deleteGenre={deleteGenre}
          searchTerm={searchInput}
        />
      </CustomTable>
    </div>
  );
};

export default GenreTable;
