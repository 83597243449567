import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  DropDownIcon,
  LogoutIcon,
  PasswordIcon,
  Profile,
  ProfileIcon,
  SettingIcon,
} from "constants/icons";
import { ADMIN } from "constants/routes";

import { AuthContext } from "context-api/AuthContext";

export default function UserProfileDropdown() {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="user-section mt-2 me-4">
      <button
        className="toggle-dropdown dropdown-toggle d-flex align-items-center"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          src={currentUser?.image ? currentUser?.image : ProfileIcon.SOURCE}
          className="cursor-pointer profile-icon"
          alt="profile"
        />
        <div className="userdetail px-3 text-start">
          <p className="username">{currentUser?.name}</p>
          <p className="surname">{currentUser?.furigana}</p>
        </div>
        <img src={DropDownIcon.SOURCE} alt="dropdown" className="ms-2"/>
      </button>
      <ul className="dropdown-menu box-shadow-secondary profile-options mt-2">
        <li className="cursor-pointer p-2">
          <Link
            to={`${ADMIN.Profile.path}`}
            className="d-flex align-items-center"
          >
            <img
              src={Profile.SOURCE}
              className="dropdown-icon"
              alt="profile img"
            />
            <p className="ms-3 align-self-center">{t("label.profile")}</p>
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link
            to={"/admin/change-password"}
            className="d-flex align-items-center"
          >
            <img
              src={PasswordIcon.SOURCE}
              className="dropdown-icon"
              alt="profile img"
            />
            <p className="ms-3 align-self-center">{t("label.changePassword")}</p>
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link to={"/admin/settings"} className="d-flex align-items-center">
            <img
              src={SettingIcon.SOURCE}
              className="dropdown-icon"
              alt="profile img"
            />
            <p className="ms-3 align-self-center">{t("label.settings")}</p>
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link to={ADMIN.Logout.path} className="d-flex align-items-center">
            <img
              src={LogoutIcon.SOURCE}
              className="dropdown-icon"
              alt="profile img"
            />
            <p className="ms-3 align-self-center">{t("label.logout")}</p>
          </Link>
        </li>
      </ul>
    </div>
  );
}
