import React from "react";
import { useTranslation } from "react-i18next";

import ModalLayout from "./ModalLayout";

export default function CongratulationModel({
  badge,
  setIsVisible,
  isVisible,
  image,
}) {
  const { t } = useTranslation();

  return (
    <ModalLayout
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      className="custom-modal box-shadow-primary"
    >
      <div className="congratulations-modal my-5">
        <div className="text-center">
          <p className="title py-3">{t("label.congratulations")}</p>
          <p className="description text-center mx-5 px-5">
            {`${t("message.quizcompleted")} ${badge} `}
          </p>
        </div>
        <div className="text-center badge-container my-4">
          <img src={image} alt="badge" />
        </div>
        <div className="text-center">
          <button
            className="btn btn-primary py-2 px-4 text-center"
            onClick={() => setIsVisible(false)}
          >
            {t("buttons.Confirm")}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
}
