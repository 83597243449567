import React, { useEffect, useState } from "react";

import { privacyPolicyService } from "services/privacyPolicyService";

import UserLayout from "components/UserLayout/UserLayout";

import { t } from "i18next";

const PrivacyPolicy = () => {
  const [privacyPolicy, setprivacyPolicy] = useState();

  useEffect(() => {
    privacyPolicyService
      .get(`?paginate=false&latest=true`)
      .then((x) => setprivacyPolicy(x[0]));
  }, []);

  return (
    <UserLayout className="landing-page-wrapper">
      <div className="inquiry-container h-100">
        <div className="container">
          <div className="inquiry mt-4">
            <div className="">
              <p className="title my-4 text-center">{t("label.privacyPolicy")}</p>
              <div
                className="description info p-3"
                dangerouslySetInnerHTML={{ __html: privacyPolicy?.text }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default PrivacyPolicy;
