import React from "react";

import UserLayout from "components/UserLayout/UserLayout";

export default function Inquiry() {
  return (
    <UserLayout className="landing-page-wrapper">
      <div className="inquiry-container h-100">
        <div className="container">
          <div className="">
            <p className="title my-4">（仮）キキサポ プリバシーポリシー</p>
            <p className="description info p-3">
              ＿＿＿＿＿＿＿＿（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
              第1条（個人情報）
              「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
              第2条（個人情報の収集方法）
            </p>
          </div>
          <div className="inquiry mt-4">
            <p className="title">（仮）キキサポ プリバシーポリシー</p>
          </div>
          {[...Array(4)].map((item, id) => (
            <div className="accordion my-3" id={`drop${id}`}>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#dropdown${id}`}
                    aria-controls={`panelsStayOpen-collapse${id}`}
                  >
                    仮）キキサポ プリバシーポリシー
                  </button>
                </h2>
                <div
                  id={`dropdown${id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <p className="description">
                      ＿＿＿＿＿＿＿＿（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
                      第1条（個人情報）
                      「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
                      第2条（個人情報の収集方法）
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </UserLayout>
  );
}
