import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { alertService } from "services/alertService";
import { faqService } from "services/faqService";

import { CustomTable } from "./CustomTable";

import { DeleteIcon, EditIcon } from "constants/icons";

import { uploadPlugin } from "utils/ckeditorUploader";

const TableRow = ({
  data,
  columns,
  deleteFaq,
  setActiveFaq,
  activeFaq,
  searchTerm,
}) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.created_at}</td>
      <td className="text-wrap">{pt.question}</td>
      <td className="text-wrap">{pt.updated_at}</td>
      <td className="text-wrap">
        <div className={`action-icons ${pt.id === activeFaq ? "d-none" : ""}`}>
          <img
            src={EditIcon.SOURCE}
            className="ms-2 cursor-pointer"
            onClick={() => setActiveFaq(pt.id)}
          />
          <img
            src={DeleteIcon.SOURCE}
            className="mx-2 cursor-pointer"
            onClick={() => deleteFaq(pt.id)}
          />
        </div>
      </td>
    </tr>
  ));
};

const FAQTable = () => {
  const { t } = useTranslation();

  const [activeFaq, setActiveFaq] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [answer, setNewAnswer] = useState("");
  const [FAQ, setFaq] = useState([]);
  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    { name: t("label.date") },
    { name: t("label.form.question") },
    { name: t("label.updateAt") },
    { name: t("label.form.action") },
  ];

  useEffect(() => {
    faqService.get("?orderBy=desc&sortBy=id").then((x) => setFaq(x));
  }, [update]);

  function getUpdatedData(pagedata) {
    faqService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setFaq(x));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let form_data = new FormData();

    form_data.append("answer", answer);
    form_data.append("question", newQuestion);

    if (!activeFaq) {
      addFaq(form_data);
    } else {
      updateFaq(activeFaq, form_data);
    }
    setFaq("");
    setActiveFaq(null);
    setNewAnswer("");
    setNewQuestion("");
  };

  const addFaq = (fields) => {
    faqService
      .create(fields)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          alertService.success(
            `${t("label.FAQ")} ${t("message.addedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setNewAnswer("");
    setNewQuestion("");
  };
  const updateFaq = (id, fields) => {
    faqService
      .update(id, fields)
      .then((res) => {
        if (res.status === 200) {
          alertService.success(
            `${t("label.FAQ")} ${t("message.updatedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setActiveFaq("");
    setNewAnswer("");
    setNewQuestion("");
  };

  const deleteFaq = (id) => {
    faqService.delete(id).then(() => {
      alertService.success(t("message.deletedSuccessfuly"), {
        keepAfterRouteChange: true,
        autoClose: true,
      });
      setUpdate(!update);
    });
  };

  const inputHandler = (event, editor) => {
    const data = editor.getData();

    setNewAnswer(data);
  };

  useEffect(() => {
    if (activeFaq) {
      faqService.getById(activeFaq).then((x) => {
        setNewAnswer(x.answer);
        setNewQuestion(x.question);
      });
    }
  }, [activeFaq]);

  useEffect(() => {
    if (searchInput)
      faqService.filter(`?search=${searchInput}`).then((x) => setFaq(x));
  }, [searchInput]);

  return (
    <div className="p-3 mt-5 h-100">
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end mb-3">
        <div className="d-block mb-4 mb-xl-0">
          <h4>{t("label.FAQ")}</h4>
        </div>
      </div>
      <div className="border-bottom pb-3 mb-4">
        <Form className="row" onSubmit={handleSubmit}>
          <Form.Group className="col-lg-3 col-md-6">
            <Form.Label className="me-2">
              {t("label.form.question")} :
            </Form.Label>
            <Form.Control
              className="input-field"
              type="text"
              name="email"
              value={newQuestion}
              required
              onChange={(e) => setNewQuestion(e.target.value)}
            />
          </Form.Group>
          <div className="text-editor h-100 align-self-stretch mt-2">
            <Form.Group className="mb-3">
              <Form.Label className="sub-title">
                {t("label.description")}
              </Form.Label>
              <div className="h-100 ">
                <CKEditor
                  id="inputText"
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={ClassicEditor}
                  onChange={inputHandler}
                  data={answer}
                />
              </div>
            </Form.Group>
          </div>
          <div className="mt-3 text-align-center col-12">
            <button className="btn add-btn add-btn px-3 py-2 w-100">
              {`${activeFaq ? t("action.update") : t("action.add")} ${t(
                "label.FAQ"
              )}`}
            </button>
          </div>
        </Form>
      </div>
      <CustomTable
        columns={columns}
        data={FAQ}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
        setSearchInput={setSearchInput}
      >
        <TableRow
          data={FAQ?.results}
          columns={columns}
          setActiveFaq={setActiveFaq}
          activeFaq={activeFaq}
          deleteFaq={deleteFaq}
          searchTerm={searchInput}
        />
      </CustomTable>
    </div>
  );
};

export default FAQTable;
