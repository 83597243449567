import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { alertService } from "services/alertService";
import { userService } from "services/userService";

import { SITE } from "constants/routes";

const ConfirmVerification = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const { from } = location.state || { from: SITE.Index.path };

  const handleVerification = (e) => {
    e.preventDefault();

    if (token) {
      userService
        .confirmVerification(`?token=${token}`)
        .then((x) => {
          alertService.success(x.detail, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
          history.push(from);
        })
        .catch((err) => {
          alertService.error(err.error, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        });
    }
  };

  return (
    <div>
      <div className="landing-page-wrapper login-page d-flex justify-content-center align-items-center">
        <div className="row landing-page-container login-container h-auto align-items-center justify-content-center text-center">
          <div className="p-md-2 p-lg-5">
            <h3 className="hero-title border-0">
              {t("message.clickVerifyButtonToActivateYourAccoutnt")}
            </h3>
            <div className="d-flex align-items-center justify-content-center w-100">
              <button
                target="_blank"
                className="signup-btn"
                onClick={(e) => handleVerification(e)}
              >
                {t("buttons.Confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmVerification;
