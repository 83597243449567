import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { alertService } from "services/alertService";
import { authService } from "services/authService";

import { PasswordHideIcon, PasswordShowIcon } from "constants/icons";
import { ADMIN } from "constants/routes";

const REQUIRED_FIELDS = ["oldPassword", "newPassword", "confirmPassword"];

const ChangePassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordShow, setOldPasswordShow] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordShow, setNewPasswordShow] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [user, setUser] = useState();
  const [formSubmit, setFormSubmit] = useState(false);
  const [notMatchError, setNoMatchError] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState(null);

  useEffect(() => {
    let userToken = localStorage.getItem("token");

    authService.getUserToken(userToken).then((x) => {
      setUser(x);
    });
  }, []);

  const submitNewPassword = (value) => {
    let valid =
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!#%/.*?&])[A-Za-z\d@$!#/%*.?&]{8,}$/.test(
        value
      );

    if (valid) {
      setNewPasswordError("");
      setNewPassword(value);
    } else {
      setNewPasswordError(t("message.mineightCharacter"));
      setNewPassword(value);
    }
  };

  const setRePassword = (value) => {
    if (value === newPassword) {
      setNoMatchError("");
      setConfirmPassword(value);
    } else {
      setNoMatchError(t("message.paswordMatchFailed"));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    let form_data = new FormData();

    form_data.append("password", newPassword);
    form_data.append("password2", confirmPassword);
    form_data.append("old_password", oldPassword);

    authService
      .changePassword(user?.id, form_data)
      .then((x) => {
        alertService.success(
          `${t("label.form.password")} ${t(
            "message.updatedSuccessfully"
          )}`,
          {
            keepAfterRouteChange: true,
            autoClose: true,
          }
        );
        setUser(x);
        setSubmitting(false);
        history.push(ADMIN.Profile.path);
      })
      .catch((err) => {
        alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    let isSubmitActive = true;
    const passwordDetails = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    REQUIRED_FIELDS.forEach((item) => {
      if (!passwordDetails[item]) {
        isSubmitActive = false;
      }
    });
    setFormSubmit(isSubmitActive);
  }, [oldPassword, newPassword, confirmPassword]);

  return (
    <div className="container my-4 change-password">
      <div className="box-shadow-primary p-5">
        <div className="title-container text-center">
          <h5>{t("label.changePassword")}</h5>
        </div>
        <Form className="change-password-form" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="mt-2">
              {t("label.form.oldPassword")}
            </Form.Label>{" "}
            <InputGroup>
              <Form.Control
                type={oldPasswordShow ? "text" : "password"}
                name="oldpassowrd"
                placeholder="*******"
                className="password-field"
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <InputGroup.Text
                className="icons"
                onClick={() => setOldPasswordShow(!oldPasswordShow)}
              >
                {oldPasswordShow ? (
                  <img src={PasswordHideIcon.SOURCE} alt="hide password" />
                ) : (
                  <img src={PasswordShowIcon.SOURCE} alt="show password" />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label className="mt-2">
              {t("label.form.newPassword")}
            </Form.Label>{" "}
            <InputGroup>
              <Form.Control
                type={newPasswordShow ? "text" : "password"}
                name="newPassword"
                placeholder="*******"
                className="password-field"
                onChange={(e) => submitNewPassword(e.target.value)}
              />
              <InputGroup.Text
                className="icons"
                onClick={() => setNewPasswordShow(!newPasswordShow)}
              >
                {newPasswordShow ? (
                  <img src={PasswordHideIcon.SOURCE} alt="hide password" />
                ) : (
                  <img src={PasswordShowIcon.SOURCE} alt="show password" />
                )}
              </InputGroup.Text>
            </InputGroup>
            {newPasswordError !== "" && (
              <Form.Text className="text-error">{newPasswordError}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label className="mt-2">
              {t("label.form.confirmNewPassword")}
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={confirmPasswordShow ? "text" : "password"}
                placeholder="*******"
                className="password-field"
                onChange={(e) => setRePassword(e.target.value)}
              />
              <InputGroup.Text
                className="icons"
                onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
              >
                {confirmPasswordShow ? (
                  <img src={PasswordHideIcon.SOURCE} alt="hide password" />
                ) : (
                  <img src={PasswordShowIcon.SOURCE} alt="show password" />
                )}
              </InputGroup.Text>
            </InputGroup>
            {notMatchError !== "" && (
              <Form.Text className="text-error">{notMatchError}</Form.Text>
            )}
          </Form.Group>
          <div className="d-flex justify-content-center my-4">
            <button
              className={`btn ${
                !formSubmit || !!newPasswordError || !!notMatchError
                  ? "btn-secondary"
                  : "btn-primary"
              } px-2`}
              type="submit"
              disabled={
                !formSubmit ||
                !!newPasswordError ||
                !!notMatchError ||
                isSubmitting
              }
            >
              {t("label.changePassword")}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
