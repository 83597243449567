import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const ProductCard = ({ image, name, productNum }) => {
  const [isLoading, setIsLoading] = useState(true);
  let loadingTime;

  useEffect(() => {
    loadingTime = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTime);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="product-section h-100 w-100">
          <div className="product-card h-100 w-100">
            <div className="product-image">
              <Skeleton width={"100%"} height={"100%"} />
            </div>
            <div className="product-detail">
              <p className="fs-6 title">
                <Skeleton />
              </p>
              <p>
                <Skeleton />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="product-section h-100 w-100">
          <div className="product-card h-100 w-100">
            <div className="product-image">
              <img src={image} alt={name} />
            </div>
            <div className="product-detail">
              <p className="fs-6 title">{name}</p>
              <p>{productNum}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCard;
