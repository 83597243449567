import React from "react";

function Alertdetail({ msg }) {
  return (
    <>
      <div
        className="alert alert-dismissible fade show alert-detail alert-section"
        role="alert"
      >
        {msg ? msg : "アラーム対応に上流閉塞"}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </>
  );
}

export default Alertdetail;
