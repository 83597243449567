import React, { useContext, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { enrolledModelService } from "services/enrolledModelService";
import { modelService } from "services/modelService";

import NotFound from "components/FilterNotFound";
import CustomPagination from "components/Pagination/pagination";
import LearningProductCard from "components/ProductCard/LearningProductCard";
import ProductCard from "components/ProductCard/Productcard";
import UserLayout from "components/UserLayout/UserLayout";

import { DropDownIcon } from "constants/icons";

import { AuthContext } from "context-api/AuthContext";

import { t } from "i18next";

const LearningList = () => {
  const { currentUser } = useContext(AuthContext);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [learnings, setLearnings] = useState([]);
  const [latestModels, setLatestModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    enrolledModelService
      .get(`?page_size=${20}&orderBy=desc&sortBy=id&userId=${currentUser?.id}`)
      .then((x) => {
        if (x.results) setLearnings(x);
      });
    modelService.get(`?page_size=${3}&orderBy=desc&sortBy=id`).then((x) => {
      if (x.results) setLatestModels(x.results);
    });
  }, []);

  function getUpdatedData(pagedata) {
    enrolledModelService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id&userId=${currentUser?.id}`
      )
      .then((x) => setLearnings(x));
  }

  const [pageCount, setpageCount] = useState(0);
  const currentPage = learnings?.currentPage || 1;

  useEffect(() => {
    const total = learnings?.count;

    const pgCount = Math.ceil(total / itemPerPage);

    setpageCount(pgCount);
  }, [learnings, pageCount]);

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;

    fetchTransactions(currentPage);
  };

  useEffect(() => {
    if (currentPage > 1) {
      handleSearch();
    }
  }, [itemPerPage]);

  const handleSearch = () => {
    const pagedata = {
      page: 1,
      perPage: itemPerPage,
    };

    getUpdatedData(pagedata);
  };

  const fetchTransactions = (currentPage) => {
    const pagedata = {
      page: currentPage,
      perPage: itemPerPage,
    };

    getUpdatedData(pagedata);
  };

  useEffect(() => {
    if (searchInput)
      enrolledModelService
        .filter(`?search=${searchInput}`)
        .then((x) => setLearnings(x));
  }, [searchInput]);

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setSearchInput(searchTerm);
  };

  return (
    <UserLayout>
      <div className="learning-lists container pt-5">
        <div className="row">
          <div className="col-sm-12 col-md-9 mb-3">
            <div className="flex-grow-1">
              <div className="title-search">
                <h6 className="subtitle mb-3">{t("label.learningList")}</h6>
                <div className="d-flex justify-content-between">
                  <div className="searchbar w-50">
                    <Form
                      onSubmit={(e) => handleSubmitSearch(e)}
                      className="d-flex"
                    >
                      <Form.Group className="w-50">
                        <InputGroup>
                          <InputGroup.Text className="bg-white pe-4 border-right-0">
                            <FontAwesomeIcon icon={faSearch} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder={t("label.search")}
                            className="search-input border-left-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <button
                        className="btn btn-secondary ms-lg-3  ms-3  filter-button text-dark"
                        type="submit"
                      >
                        {t("action.filter")}
                      </button>
                    </Form>
                  </div>
                  <div>
                    {learnings?.results?.length ? (
                      <div className="filter d-flex align-items-center">
                        <p className="d-none d-md-block">
                          {t("label.showperPage")}
                        </p>
                        <button
                          className="toggle-dropdown dropdown-toggle show-items d-flex align-items-center px-3 py-2 ms-2"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <p className="mb-0">{itemPerPage}</p>
                          <img src={DropDownIcon.SOURCE} className="ms-2" />
                        </button>
                        <ul className="dropdown-menu box-shadow-secondary page-options mt-2 text-align-left w-auto">
                          <li
                            className="px-3 cursor-pointer"
                            onClick={() => setItemPerPage(20)}
                          >
                            20
                          </li>
                          <li
                            className="px-3 cursor-pointer"
                            onClick={() => setItemPerPage(40)}
                          >
                            40
                          </li>
                          <li
                            className="px-3 cursor-pointer"
                            onClick={() => setItemPerPage(80)}
                          >
                            80
                          </li>
                          <li
                            className="px-3 cursor-pointer"
                            onClick={() => setItemPerPage(100)}
                          >
                            100
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {learnings?.results?.length ? (
                <>
                  <div className="learning-modules row mt-3">
                    {learnings?.results?.map((item, idx) => (
                      <div
                        className="col-lg-3 col-md-4 col-sm-4 col-sm-6 col-xs-12"
                        key={idx}
                      >
                        <LearningProductCard item={item} />
                      </div>
                    ))}
                  </div>
                  <div className="page-wrap mb-3 mt-5">
                    <p className="showing">
                      {t("label.Showing")}
                      &nbsp;{currentPage}
                      &nbsp;{t("label.to")}
                      &nbsp;{pageCount}
                      &nbsp;{t("label.of")}
                      &nbsp;{learnings?.count}
                      &nbsp;{t("label.entries")}.
                    </p>
                    <CustomPagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                      currentPage={currentPage}
                    />
                  </div>
                </>
              ) : (
                <NotFound keyword={searchInput} />
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-3 mb-3">
            <div className="model-details">
              {learnings?.results?.length ? (
                <div className="operation-list d-block  mt-lg-4 pt-lg-5">
                  <p className="subtitle">{t("label.model")}</p>
                  <div className="operations row mt-1">
                    {latestModels.map((item, idx) => (
                      <div className="col-sm-10 mb-3" key={idx}>
                        <Link to={`home/model-detail/${item.id}`}>
                          <ProductCard
                            name={item?.product_name}
                            productNum={item?.model_number}
                            image={item.image}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default LearningList;
