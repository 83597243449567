import { fetchWrapper } from "lib/FetchWrapper";

const API_URL = `${process.env.REACT_APP_BASE_URL}/api`;
const baseUrl = `${API_URL}/terms-of-service`;

export const termsOfService = {
  get,
  getAll,
  getById,
  create,
  update,
  filter,
  delete: _delete,
};

function get(params) {
  return fetchWrapper.get(`${baseUrl}/${params}`);
}

function filter(params) {
  return fetchWrapper.filter(`${baseUrl}/${params}`);
}

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}`, `${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}`, params, `${id}`);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}`, `${id}`);
}
