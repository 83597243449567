import React from "react";

import Footer from "./Footer";
import Header from "./Header";

const UserLayout = ({ children, ...restProps }) => (
  <div {...restProps}>
    <Header />
    <div>{children}</div>
    <Footer />
  </div>
);

export default UserLayout;
