import React, { useEffect, useState } from "react";

import { faqService } from "services/faqService";

import UserLayout from "components/UserLayout/UserLayout";

import { t } from "i18next";

const Faq = () => {
  const [faq, setFaq] = useState();

  useEffect(() => {
    faqService
      .get(`?paginate=false&orderBy=desc&sortBy=id`)
      .then((x) => setFaq(x));
  }, []);

  return (
    <UserLayout className="landing-page-wrapper">
      <div className="inquiry-container h-100">
        <div className="container">
          <div className="inquiry mt-4">
            <p className="title my-4 text-center">{t("label.faq")}</p>
          </div>
          {faq?.map((item, id) => (
            <div className="accordion my-3" id={`drop${id}`}>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#dropdown${id}`}
                    aria-controls={`panelsStayOpen-collapse${id}`}
                  >
                    {item.question}
                  </button>
                </h2>
                <div
                  id={`dropdown${id}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: item?.answer }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </UserLayout>
  );
};

export default Faq;
