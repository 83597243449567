import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Form, InputGroup } from "react-bootstrap";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomPagination from "components/Pagination/pagination";

import { t } from "i18next";

export const CustomTable = ({
  columns,
  data,
  getUpdatedData,
  children,
  setSearchInput,
}) => {
  //for pagination
  const [pageCount, setpageCount] = useState(0);
  const [term, setTerm] = useState("");
  //data listing with pagination
  const [limit, setLimit] = useState("5");

  const currentPage = data?.currentPage || 0;

  useEffect(() => {
    const total = data?.count;

    const pgCount = Math.ceil(total / limit) || 0;

    setpageCount(pgCount);
  }, [data, pageCount]);

  useEffect(() => {
    if (currentPage) {
      handleSearch();
    }
  }, [limit]);

  const fetchTransactions = (currentPage) => {
    const pagedata = {
      page: currentPage,
      perPage: limit,
    };

    getUpdatedData(pagedata);
  };

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;

    fetchTransactions(currentPage);
  };

  const handleSearch = () => {
    const pagedata = {
      page: 1,
      perPage: limit,
    };

    getUpdatedData(pagedata);
  };

  const TableHead = ({ ...props }) => (
    <th className="border-0" {...props}>
      {props.name}
    </th>
  );

  return (
    <div className="custom-table">
      <div className="page-wrap mb-3">
        <div className="searchbar d-block d-md-flex">
          <Form.Group className="w-75">
            <InputGroup>
              <InputGroup.Text className="bg-white pe-4">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder={t("label.search")}
                className="search-input"
                onChange={(e) => setTerm(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <button className="btn btn-primary px-3 py-2 mt-3 mt-md-0 ms-0 ms-md-2" onClick={() =>  setSearchInput(term)}>{t("label.search")}</button>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-4">
          <label className="pe-2" htmlFor="selectPerPage">
            {t("label.resultPerPage")}
          </label>
          <select
            id="selectPerPage"
            value={limit}
            name="perPage"
            onChange={(event) => {
              setLimit(event.target.value);
            }}
            className="rounded-2 select-per-page"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
      <Card border="light" className="shadow-sm mb-3 overflow-hidden">
        <Card.Body className="p-0">
          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="thead-light">
              <tr className="py-2">
                {columns.map((pt, index) => (
                  <TableHead key={index} {...pt} />
                ))}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </Table>
        </Card.Body>
      </Card>
      <div className="page-wrap mb-3">
        <p className="showing">
          {t("label.Showing")}
          &nbsp;{currentPage}
          &nbsp;{t("label.to")}
          &nbsp;{pageCount}
          &nbsp;{t("label.of")}
          &nbsp;{data?.count}
          &nbsp;{t("label.entries")}.
        </p>

        <CustomPagination
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};
