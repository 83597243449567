import React from "react";
import { Table } from "react-bootstrap";

export default function SimpleTable({ columns, children }) {
  return (
    <div className="simple-table box-shadow-primary mt-3">
      <Table
        responsive
        className="table-centered table-nowrap rounded mb-0 w-100"
      >
        <thead className="table-heading">
          <tr>
            {columns.map((pt, index) => (
              <th key={index}>{pt.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </div>
  );
}
