import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import { DropDownIcon, AwardIcon } from "constants/icons";

import { t } from "i18next";

const AchievementCard = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="container">
      <div className="d-flex align-items-center mt-3">
        <span className="learning-title">{t("label.achievement")}</span>
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="dropdown-icon"
        >
          <img src={DropDownIcon.SOURCE} alt="dropdown" />
        </Button>
      </div>
      <Collapse in={open}>
        <div id="example-collapse-text">
          <div className="achievement-wrap">
            <div className="achievement-card">
              <img src={AwardIcon.SOURCE} alt="award icon" />
              <span>{t("label.achievement")}</span>
            </div>
            <div className=" achievement-card">
              <img src={AwardIcon.SOURCE} alt="award icon" />
              <span>{t("label.achievement")}</span>
            </div>
            <div className=" achievement-card">
              <img src={AwardIcon.SOURCE} alt="award icon" />
              <span>{t("label.achievement")}</span>
            </div>
            <div className=" achievement-card">
              <img src={AwardIcon.SOURCE} alt="award icon" />
              <span>{t("label.achievement")}</span>
            </div>
            <div className=" achievement-card">
              <img src={AwardIcon.SOURCE} alt="award icon" />
              <span>{t("label.achievement")}</span>
            </div>
            <div className=" achievement-card">
              <img src={AwardIcon.SOURCE} alt="award icon" />
              <span>{t("label.achievement")}</span>
            </div>
            <div className="achievement-card view-all">
              <span className="view-all-txt">
                {t("label.viewAll")} {t("label.achievement")}
              </span>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default AchievementCard;
