import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NotExistingPage from "pages/Site/404";
import Faq from "pages/Site/FAQ";
import Welcome from "pages/Site/Home/Welcome";
import Inquiry from "pages/Site/Inquiry";
import Login from "pages/Site/Login";
import PrivacyPolicy from "pages/Site/PrivacyPolicy";
import Registration from "pages/Site/Registration";
import SiteRoutes from "pages/Site/SiteRoutes";
import TermsOfService from "pages/Site/TermsofService";

import ConfirmPasswordReset from "components/Auth/ConfirmPasswordReset";
import ConfirmVerification from "components/Auth/ConfirmVerification";
import ForgotPassword from "components/Auth/ForgotPassword";
import RegisterEmailSent from "components/Auth/RegisterEmailSent";
import ResetPasswordLinkSent from "components/Auth/ResetPasswordLinkSent";
import Logout from "components/Logout/Logout";

import { SITE } from "constants/routes";

import HomeContextContainer from "context-api/HomeContextContainer";

import { UserProtectedRoute } from "lib/ProtectedRoute";

const Site = () => (
  <Router>
    <Switch>
      <HomeContextContainer>
        <UserProtectedRoute path={SITE.Home.path}>
          <SiteRoutes />
        </UserProtectedRoute>
        <Route exact path={SITE.Index.path} component={Welcome} />
        <Route exact path={SITE.Login.path} component={Login} />
        <Route exact path={SITE.Logout.path} component={Logout} />
        <Route exact path={SITE.Registration.path} component={Registration} />
        <Route
          exact
          path={SITE.ForgotPassword.path}
          component={ForgotPassword}
        />
        <Route
          exact
          path={SITE.RegisterEmailSent.path}
          component={RegisterEmailSent}
        />
        <Route
          exact
          path={SITE.ResetLinkSent.path}
          component={ResetPasswordLinkSent}
        />
        <Route
          exact
          path={SITE.TermsofService.path}
          component={TermsOfService}
        />
        <Route exact path={SITE.PrivacyPolicy.path} component={PrivacyPolicy} />
        <Route exact path={SITE.Inquiry.path} component={Inquiry} />
        <Route exact path={SITE.FAQ.path} component={Faq} />
        <Route
          exact
          path={SITE.ConfirmUser.path}
          component={ConfirmVerification}
        />
        <Route
          exact
          path={SITE.ConfirmPasswordReset.path}
          component={ConfirmPasswordReset}
        />
        <Route exact path={SITE.NotFound.path} component={NotExistingPage} />
      </HomeContextContainer>
    </Switch>
  </Router>
);

export default Site;
