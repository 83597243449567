import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { alertService } from "services/alertService";
import { privacyPolicyService } from "services/privacyPolicyService";

import { CustomTable } from "./CustomTable";

import { DeleteIcon, EditIcon } from "constants/icons";

import { t } from "i18next";

import { uploadPlugin } from "utils/ckeditorUploader";

const TableRow = ({
  data,
  columns,
  deleteCondition,
  setActivePrivacy,
  activePrivacy,
  searchTerm,
}) => {
  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.created_at}</td>
      <td className="text-wrap">
        <span
          dangerouslySetInnerHTML={{
            __html: pt.text
              ? pt.text.length > 50
                ? `${pt.text.substring(0, 50)}...`
                : pt.text
              : "N/A",
          }}
        />
      </td>
      <td className="text-wrap">{pt.updated_at}</td>
      <td className="text-wrap">
        <div
          className={`action-icons ${pt.id === activePrivacy ? "d-none" : ""}`}
        >
          <img
            src={EditIcon.SOURCE}
            className="ms-2 cursor-pointer"
            onClick={() => setActivePrivacy(pt.id)}
          />
          <img
            src={DeleteIcon.SOURCE}
            className="mx-2 cursor-pointer"
            onClick={() => deleteCondition(pt.id)}
          />
        </div>
      </td>
    </tr>
  ));
};

const PrivacyPolictTable = () => {
  const { t } = useTranslation();

  const [activePrivacy, setActivePrivacy] = useState("");
  const [newPrivacy, setNewPrivacy] = useState("");
  const [privacy, setPrivacy] = useState([]);
  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    { name: t("label.date") },
    { name: t("label.form.name") },
    { name: t("label.updateAt") },
    { name: t("label.form.action") },
  ];

  useEffect(() => {
    privacyPolicyService
      .get("?orderBy=desc&sortBy=id")
      .then((x) => setPrivacy(x));
  }, [update]);

  function getUpdatedData(pagedata) {
    privacyPolicyService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setPrivacy(x));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let form_data = new FormData();

    form_data.append("text", newPrivacy);

    if (!activePrivacy) {
      addPrivacy(form_data);
    } else {
      updatePrivacy(activePrivacy, form_data);
    }
    setActivePrivacy(null);
    setNewPrivacy("");
  };

  const addPrivacy = (fields) => {
    privacyPolicyService
      .create(fields)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          alertService.success(
            `${t("label.privacypolicy")} ${t("message.addedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setNewPrivacy("");
  };
  const updatePrivacy = (id, fields) => {
    privacyPolicyService
      .update(id, fields)
      .then((res) => {
        if (res.status === 200) {
          alertService.success(
            `${t("label.privacyPolicy")} ${t("message.updatedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setActivePrivacy("");
    setNewPrivacy("");
  };

  const deleteCondition = (id) => {
    privacyPolicyService.delete(id).then(() => {
      alertService.success(t("message.deletedSuccessfuly"), {
        keepAfterRouteChange: true,
        autoClose: true,
      });
      setUpdate(!update);
    });
  };

  const inputHandler = (event, editor) => {
    const data = editor.getData();

    setNewPrivacy(data);
  };

  useEffect(() => {
    if (activePrivacy) {
      privacyPolicyService
        .getById(activePrivacy)
        .then((x) => setNewPrivacy(x.name));
    }
  }, [activePrivacy]);

  useEffect(() => {
    if (searchInput)
      privacyPolicyService
        .filter(`?search=${searchInput}`)
        .then((x) => setPrivacy(x));
  }, [searchInput]);

  return (
    <div className="p-3 mt-5 h-100">
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end mb-3">
        <div className="d-block mb-4 mb-xl-0">
          <h4>{t("label.privacyPolicy")}</h4>
        </div>
      </div>
      <div className="border-bottom pb-3 mb-4">
        <Form className="row" onSubmit={handleSubmit}>
          <div className="text-editor h-100 align-self-stretch">
            <Form.Group className="mb-3">
              <Form.Label className="sub-title">
                {t("label.description")}
              </Form.Label>
              <div className="h-100 ">
                <CKEditor
                  id="inputText"
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={ClassicEditor}
                  onChange={inputHandler}
                  data={newPrivacy}
                />
              </div>
            </Form.Group>
          </div>
          <div className="mt-3 text-align-center col-12">
            <button className="btn add-btn px-3 py-2 w-100">
              {`${activePrivacy ? t("action.update") : t("action.add")} ${t(
                "label.privacyPolicy"
              )}`}
            </button>
          </div>
        </Form>
      </div>
      <CustomTable
        columns={columns}
        data={privacy}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
        setSearchInput={setSearchInput}
      >
        <TableRow
          data={privacy?.results}
          columns={columns}
          setActivePrivacy={setActivePrivacy}
          activePrivacy={activePrivacy}
          deleteCondition={deleteCondition}
          searchTerm={searchInput}
        />
      </CustomTable>
    </div>
  );
};

export default PrivacyPolictTable;
