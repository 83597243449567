import React, { useEffect, useState } from "react";

import { modelService } from "services/modelService";
import { operationService } from "services/operationService";

import EditModelDetails from "components/Modal/EditModelDetails";
import OperationModel from "components/Modal/OperationModel";
import WarningModel from "components/Modal/WarningModel";
import ProductCard from "components/ProductCard/Productcard";

import { BackIcon, DoctorImage, EditIcon } from "constants/icons";

import { t } from "i18next";

const ModalDetails = ({ match }) => {
  let modelId = "";

  if (match !== undefined) {
    modelId = match.params.id;
  } else {
    modelId = null;
  }

  const [editModal, setEditModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [operationId, setOperationId] = useState();
  const [operationModal, setOperationModal] = useState(false);
  const [operationList, setOperationList] = useState([]);
  const [update, setUpdate] = useState(false);
  const [modelDetails, setModelDetails] = useState({
    maker_name: "",
    genre: "",
    product_name: "",
    model_number: "",
    service_duration: "",
    instruction_mannual: "",
    instruction: "",
    package_insert: "",
    sales_condition: "",
    product_inquiry: "",
    image: "",
  });

  useEffect(() => {
    if (modelId) {
      modelService.getById(modelId).then((x) => {
        setModelDetails({
          image: x.image,
          maker_name: x.maker,
          genre: x.genre,
          product_name: x.product_name,
          model_number: x.model_number,
          service_duration: x.service_duration,
          instruction_mannual: x.instruction_mannual,
          instruction: x.instruction,
          sales_condition: x.sales_condition,
          package_insert: x.package_insert,
          product_inquiry: x.product_inquiry,
        });
      });
    }
  }, [update]);

  useEffect(() => {
    operationService
      .get(`?paginate=false&orderBy=desc&sortBy=id&model_id=${modelId}`)
      .then((x) => {
        setOperationList(x);
      });
  }, [update]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    setWarning(true);
  };

  const handleBack = () => {
    if (operationList.length) {
      history.back();
    } else {
      setWarning(true);
    }
  };

  const handleModelcancel = () => {
    modelService.delete(modelId).then(() => history.back());
  };

  return (
    <>
      <div className="model-details box-shadow-primary p-lg-5 p-3 mt-5">
        <div className="d-flex mb-xl-0 align-items-center">
          <img
            className="align-self-center mb-2 cursor-pointer"
            src={BackIcon.SOURCE}
            onClick={() => handleBack()}
          />
          <h4 className="ms-3 user-name">{t("label.modelDetail")}</h4>
        </div>
        <div className="my-4 d-lg-flex align-items-center">
          <img
            src={
              `${process.env.REACT_APP_BASE_URL}${modelDetails?.image}` ||
              DoctorImage.SOURCE
            }
            className="title-image"
          />
          <div className="model-detail-section flex-grow-1 ms-lg-4 position-relative">
            <div
              className="py-1 pb-2 px-2 position-absolute edit-section cursor-pointer"
              onClick={() => setEditModal(true)}
            >
              <img src={EditIcon.SOURCE} alt="edit modal" />
            </div>
            <div className="d-flex align-items-center p-4 flex-wrap">
              <div className="model-attribute  m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.searchForm.makerName")}:</p>
                <p className="value ms-2">{modelDetails.maker_name.name}</p>
              </div>
              <div className="model-attribute  m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.searchForm.genre")}:</p>
                <p className="value ms-2">{modelDetails.genre.name}</p>
              </div>
              <div className="model-attribute m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.searchForm.productName")}:</p>
                <p className="value ms-2">{modelDetails.product_name}</p>
              </div>
              <div className="model-attribute m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.searchForm.modelNumber")} :</p>
                <p className="value ms-2">{modelDetails.model_number}</p>
              </div>
              <div className="model-attribute m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.serviceDuration")}:</p>
                <p className="value ms-2">
                  {modelDetails.service_duration} {t("label.years")}
                </p>
              </div>
              <div className="model-attribute m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.instructionMannual")}:</p>
                <p className="value ms-2 link">
                  {modelDetails.instruction_mannual}
                </p>
              </div>
              <div className="model-attribute m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.packageInsert")}:</p>
                <p className="value ms-2 link">{modelDetails.package_insert}</p>
              </div>
              <div className="model-attribute m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.salesCondition")}:</p>
                <p className="value ms-2">
                  {modelDetails.sales_condition.name}
                </p>
              </div>
              <div className="model-attribute m-lg-4 mb-3 d-flex flex-wrap">
                <p className="title">{t("label.productInquiry")}:</p>
                <p className="value ms-2 link">
                  {modelDetails.product_inquiry}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="operation-list">
          <div className="title">
            <p>
              {t("label.operation")} {t("action.list")}
            </p>
          </div>
          <div className="operations row mt-3">
            <div className="col-sm-12 col-md-4 col-lg-3 col-xl-2 mb-3">
              <div
                className="operation add-operation cursor-pointer d-flex justify-content-center align-items-center h-100 w-100"
                onClick={() => setOperationModal(true)}
              >
                <h4 className="p-4 m-2">+</h4>
              </div>
            </div>
            {operationList?.map((item, idx) => (
              <div
                className="col-sm-12 col-md-4 col-lg-3 col-xl-2 mb-3"
                key={idx}
              >
                <div
                  className="cursor-pointer h-100 w-100"
                  onClick={() => {
                    setOperationId(item.id);
                    setOperationModal(true);
                  }}
                >
                  <ProductCard image={item.thumbnail} name={item.title} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <EditModelDetails
        isVisible={editModal}
        setIsVisible={setEditModal}
        update={update}
        setUpdate={setUpdate}
        modelId={modelId}
      />
      <OperationModel
        isVisible={operationModal}
        setIsVisible={setOperationModal}
        modelDetails={modelDetails}
        setModelDetails={setModelDetails}
        modelId={modelId}
        operationId={operationId}
        update={update}
        setUpdate={setUpdate}
      />
      <WarningModel
        isVisible={warning}
        setIsVisible={setWarning}
        onConfirm={() => handleModelcancel()}
        warningText={t("message.warningonModalQuit")}
        confirmText={t("action.update")}
      />
    </>
  );
};

export default ModalDetails;
