import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { alertService } from "services/alertService";
import { makerService } from "services/makerService";

import { CustomTable } from "./CustomTable";

import { DeleteIcon, EditIcon } from "constants/icons";

import { t } from "i18next";

const TableRow = ({
  data,
  columns,
  deleteMaker,
  setActiveMaker,
  activeMaker,
  searchTerm,
}) => {
  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.created_at}</td>
      <td className="text-wrap">{pt.name}</td>
      <td className="text-wrap">{pt.image}</td>
      <td className="text-wrap">{pt.updated_at}</td>
      <td className="text-wrap">
        <div
          className={`action-icons ${pt.id === activeMaker ? "d-none" : ""}`}
        >
          <img
            src={EditIcon.SOURCE}
            className="ms-1 cursor-pointer"
            onClick={() => setActiveMaker(pt.id)}
          />
          <img
            src={DeleteIcon.SOURCE}
            className="mx-1 cursor-pointer"
            onClick={() => deleteMaker(pt.id)}
          />
        </div>
      </td>
    </tr>
  ));
};

const MakerTable = () => {
  const { t } = useTranslation();

  const [activeMaker, setActiveMaker] = useState("");
  const [newMaker, setNewMaker] = useState("");
  const [makerImage, setMakerImage] = useState("");
  const [makers, setMakers] = useState([]);
  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    { name: t("label.date") },
    { name: t("label.form.name") },
    { name: "Image" },
    { name: t("label.updateAt") },
    { name: t("label.form.action") },
  ];

  useEffect(() => {
    makerService.get("?orderBy=desc&sortBy=id").then((x) => setMakers(x));
  }, [update]);

  function getUpdatedData(pagedata) {
    makerService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setMakers(x));
  }

  function handleSubmit(e) {
    e.preventDefault();
    let form_data = new FormData();

    if (makerImage) form_data.append("image", makerImage, makerImage.name);
    form_data.append("name", newMaker);

    if (!activeMaker) {
      addMakers(form_data);
    } else {
      updateMakers(activeMaker, form_data);
    }
  }

  const addMakers = (fields) => {
    makerService
      .create(fields)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          alertService.success(
            `${t("label.searchForm.maker")} ${t("message.addedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setNewMaker("");
  };

  const updateMakers = (id, fields) => {
    makerService
      .update(id, fields)
      .then((res) => {
        if (res.status === 200) {
          alertService.success(
            `${t("label.searchForm.maker")} ${t(
              "message.updatedSuccessfully"
            )}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setActiveMaker("");
    setNewMaker("");
    const imageValue = document.getElementById("maker-image");

    imageValue.value = "";
    setMakerImage("");
  };

  const deleteMaker = (id) => {
    makerService.delete(id).then(() => {
      alertService.success(t("message.deletedSuccessfuly"), {
        keepAfterRouteChange: true,
        autoClose: true,
      });
      setUpdate(!update);
    });
  };

  useEffect(() => {
    if (activeMaker) {
      makerService.getById(activeMaker).then((x) => {
        setNewMaker(x.name);
      });
    }
  }, [activeMaker]);

  const imageUpload = (id) => {
    const fileInput = document.getElementById(id);

    fileInput.click();
  };

  useEffect(() => {
    if (searchInput)
      makerService.filter(`?search=${searchInput}`).then((x) => setMakers(x));
  }, [searchInput]);

  return (
    <div className="p-3 mt-5 h-100">
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end mb-3">
        <div className="d-block mb-4 mb-xl-0">
          <h4>Makers</h4>
        </div>
      </div>
      <div className="border-bottom pb-3 mb-4">
        <Form className="row" onSubmit={handleSubmit}>
          <Form.Group className="col-lg-3 col-md-6">
            <Form.Label className="me-2">{t("label.form.name")} :</Form.Label>
            <Form.Control
              className="input-field"
              type="text"
              name="email"
              value={newMaker}
              required
              onChange={(e) => setNewMaker(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-lg-3 col-md-6">
            <Form.Label className="me-2">Image:</Form.Label>
            <InputGroup>
              <Form.Control
                name="image"
                placeholder="add"
                type="file"
                id="maker-image"
                onChange={(e) => setMakerImage(e.target.files[0])}
              />
              <InputGroup.Text
                className="btn"
                onClick={() => imageUpload("maker-image")}
              >
                {t("label.browse")}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div className="mt-4 pt-1 col-lg-2 col-md-12">
            <button className="btn add-btn px-3 py-2 w-100" type="submit">
              {activeMaker ? t("action.update") : t("action.add")}{" "}
              {t("label.makers")}
            </button>
          </div>
        </Form>
      </div>

      <CustomTable
        columns={columns}
        data={makers}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
        setSearchInput={setSearchInput}
      >
        <TableRow
          data={makers?.results}
          columns={columns}
          setActiveMaker={setActiveMaker}
          activeMaker={activeMaker}
          deleteMaker={deleteMaker}
          searchTerm={searchInput}
        />
      </CustomTable>
    </div>
  );
};

export default MakerTable;
