import React from "react";
import { Route, Switch } from "react-router-dom";

import ChangePassword from "./ChangePassword";
import Home from "./Home";
import LearningList from "./LearningList";
import ModelDetail from "./Model/ModelDetail";
import ModelList from "./ModelList";
import Profile from "./Profile/Index";
import SearchBox from "./Search/SearchBox";
import SearchList from "./Search/SearchList";

import { SITE } from "constants/routes";

const SiteRoutes = () => (
  <Switch>
    <Route exact path={SITE.Home.path} component={Home} />
    <Route exact path={SITE.ModelList.path} component={ModelList} />
    <Route exact path={SITE.ModelDetail.path} component={ModelDetail} />
    <Route exact path={SITE.LearningList.path} component={LearningList} />
    <Route exact path={SITE.Profile.path} component={Profile} />
    <Route exact path={SITE.ChangePassword.path} component={ChangePassword} />
    <Route exact path={SITE.SearchBox.path} component={SearchBox} />
    <Route exact path={SITE.SearchList.path} component={SearchList} />
  </Switch>
);

export default SiteRoutes;
