import React, { useEffect, useState } from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";

import { faEye, faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { modelService } from "services/modelService";

import { CustomTable } from "components/Table/CustomTable";

import { ADMIN } from "constants/routes";

import { t } from "i18next";

const TableRow = ({ data, columns, searchTerm }) => {
  const { path } = useRouteMatch();

  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.id}</td>
      <td className="text-wrap">{pt.genre?.name}</td>
      <td className="text-wrap">{pt.maker?.name}</td>
      <td className="text-wrap">{pt.product_name}</td>
      <td className="text-wrap">{pt.model_number}</td>
      <td className="text-wrap">
        <Link
          as={Button}
          to={`${path}/view/${pt.id}`}
          data-toggle="tooltip"
          data-placement="top"
          title={t("label.viewDetails")}
        >
          <FontAwesomeIcon icon={faEye} className="me-2 text-primary" />
        </Link>
      </td>
    </tr>
  ));
};

const ModalList = () => {
  const [modal, setmodal] = useState([]);
  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    { name: t("label.id") },
    { name: t("label.searchForm.genre") },
    { name: t("label.searchForm.makerName") },
    { name: t("label.searchForm.productName") },
    { name: t("label.searchForm.modelNumber") },
    { name: t("label.form.action") },
  ];

  useEffect(() => {
    modelService.get("?orderBy=desc&sortBy=id").then((x) => setmodal(x));
  }, [update]);

  function getUpdatedData(pagedata) {
    modelService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setmodal(x));
  }

  useEffect(() => {
    if (searchInput)
      modelService.filter(`?search=${searchInput}`).then((x) => setmodal(x));
  }, [searchInput]);

  return (
    <div className="box-shadow-primary p-lg-5 p-3 mt-5">
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("label.model")}</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {t("label.model")} {t("action.list")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("label.model")}</h4>
        </div>
        <div className="addBtn-wrapper mb-4 mb-xl-0">
          <Link
            className="px-3 py-2 btn-primary rounded-1"
            to={`${ADMIN.AddModal.path}`}
          >
            <FontAwesomeIcon icon={faPlus} /> {t("action.add")}&nbsp;
            {t("label.model")}
          </Link>
        </div>
      </div>

      <CustomTable
        columns={columns}
        data={modal}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
        setSearchInput={setSearchInput}
      >
        <TableRow
          data={modal?.results}
          columns={columns}
          setUpdate={setUpdate}
          update={update}
          searchTerm={searchInput}
        />
      </CustomTable>
    </div>
  );
};

export default ModalList;
