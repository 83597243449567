import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const LanguageContext = React.createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const currentLanguage = localStorage.getItem("current_lang");
  const [language, setLanguage] = useState(currentLanguage);
  const [eng, setEng] = useState(false);

  if (!currentLanguage) {
    localStorage.setItem("current_lang", i18n.language);
    setLanguage(i18n.language);
  }

  const handleLangChange = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    localStorage.setItem("current_lang", lang);
  };

  useEffect(() => {
    if (localStorage.getItem("current_lang")) {
      i18n.changeLanguage(localStorage.getItem("current_lang"));
    }
  }, []);

  useEffect(() => {
    if (language == "en") {
      setEng(true);
    } else {
      setEng(false);
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={{ handleLangChange, language, eng }}>
      {children}
    </LanguageContext.Provider>
  );
};
