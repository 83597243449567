import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { genreService } from "services/genreService";
import { makerService } from "services/makerService";

import { FilterIcon, SearchIcon } from "constants/icons";
import { SITE } from "constants/routes";

const Search = ({ setSearchTerm }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isOpened, setIsOpened] = useState(false);
  const [makers, setMakers] = useState([]);
  const [genre, setGenres] = useState([]);

  function toggle(e) {
    e.preventDefault();
    setIsOpened((wasOpened) => !wasOpened);
  }

  //for filter
  const [searchData, setSearchdata] = useState({
    search: "",
    genre: "",
    maker: "",
    productname: "",
  });

  const changeField = (name, value) => {
    const auxData = { ...searchData };

    auxData[name] = value;
    setSearchdata(auxData);
  };

  useEffect(() => {
    makerService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setMakers(x));
    genreService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setGenres(x));
  }, []);

  const makerOptions = makers?.map((x, index) => (
    <option value={x.name} key={index}>
      {x.name}
    </option>
  ));

  const genreOptions = genre?.map((x, index) => (
    <option value={x.name} key={index}>
      {x.name}
    </option>
  ));

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(searchData.search);
    history.push({
      pathname: `${SITE.SearchList.path}`,
      search: `?search=${searchData.search}&genre=${searchData.genre}&maker=${searchData.maker},&pro_mod=${searchData.productname}`, // query string
      state: {
        search: `${searchData.search}`,
        genre: `${searchData.genre}`,
        maker: `${searchData.maker}`,
        pro_mod: `${searchData.productname}`,
      },
    });
  };

  return (
    <div>
      {" "}
      <form onSubmit={(e) => handleSearch(e)}>
        <div className="input-wrap">
          <div className="d-flex justify-content-end">
            <input
              type="text"
              id="search"
              name="search"
              className="search-input ps-4"
              placeholder={t("label.search")}
              onChange={(event) =>
                changeField(event.target.name, event.target.value)
              }
            />
            {!isOpened && (
              <button className="search-btn" type="submit">
                {t("label.search")}
              </button>
            )}
            <div className="d-flex align-items-center search-icon-wrap justify-content-end">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {t("label.advance")} {t("label.search")}
                  </Tooltip>
                }
              >
                <div className="filterIcon" onClick={toggle}>
                  <span>
                    <button className="filter-btn">
                      <img src={FilterIcon.SOURCE} />
                    </button>
                  </span>
                </div>
              </OverlayTrigger>
            </div>
          </div>
          <div className="mobile-search d-md-none">
            <Link
              to={SITE.SearchBox.path}
              className="d-flex text-dark align-items-center justify-content-between"
            >
              <div className="input-area">{t("label.search")}</div>
              <button className="search-icon">
                <img src={SearchIcon.SOURCE} />
              </button>
            </Link>
          </div>

          {/* table filter */}
          {isOpened && (
            <div className="col-12">
              <div className="mb-3">
                <h6 className="mt-4">
                  {t("label.advance")} {t("label.search")}
                </h6>
                <div className="form-group row">
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label>{t("label.searchForm.genre")}</label>
                    <br />
                    <select
                      className="univ-input"
                      placeholder="Enter Genre"
                      name="genre"
                      value={searchData.genre}
                      onChange={(event) =>
                        changeField(event.target.name, event.target.value)
                      }
                    >
                      <option value="">{t("label.searchForm.genre")} </option>
                      {genreOptions}
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label>{t("label.searchForm.maker")}</label>
                    <br />
                    <select
                      className="univ-input"
                      name="maker"
                      onChange={(event) =>
                        changeField(event.target.name, event.target.value)
                      }
                    >
                      <option value="">
                        {t("action.select")} {t("label.searchForm.maker")}
                      </option>
                      {makerOptions}
                    </select>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-3">
                    <label>{t("label.searchForm.productName")}</label>
                    <Form.Control
                      className="univ-input"
                      name="productname"
                      placeholder={`${t("action.enter")} ${t(
                        "label.searchForm.productName"
                      )} / ${t("label.searchForm.modelNumber")}`}
                      onChange={(event) =>
                        changeField(event.target.name, event.target.value)
                      }
                    ></Form.Control>
                  </div>

                  <div className="col-lg-3 col-md-6 ">
                    <button
                      className="advance-search-btn  w-100 ms-0"
                      type="submit"
                    >
                      {t("label.search")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Search;
