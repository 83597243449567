import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";

import { modelService } from "services/modelService";
import { userService } from "services/userService";

import SimpleTable from "components/Table/SimpleTable";

import {
  TotalMedicalIcon,
  TotalUserIcon,
  ModalIcon,
  NewUserIcon,
} from "constants/icons";
import { ADMIN } from "constants/routes";

import { t } from "i18next";

const TableRow = ({ data, columns }) => {
  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{t("message.noResultsFound")}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.medical_license_number}</td>
      <td className="text-wrap">{pt.name}</td>
      <td className="text-wrap">{pt.work_place}</td>
      <td className="text-wrap">{pt.address}</td>
    </tr>
  ));
};

const Dashboard = () => {
  const [user, setuser] = useState([]);
  const [models, setmodels] = useState([]);
  const [totaluser, settotaluser] = useState(0);
  const [totalActiveuser, settotalActiveuser] = useState(0);
  const [totalmodels, settotalmodels] = useState(0);

  const user_table_columns = [
    { name: t("label.form.med_lic_no") },
    { name: t("label.form.name") },
    { name: t("label.affilatedMedicalInstitute") },
    // { name: "Completion year" },
    { name: t("label.form.address") },
  ];

  useEffect(() => {
    userService.get("?orderBy=desc&sortBy=id").then((x) => {
      if (x.results) setuser(x);
    });
    userService.get("?paginate=false&orderBy=desc&sortBy=id").then((x) => {
      settotaluser(x.length);
    });
    userService
      .get("?paginate=false&active=true&orderBy=desc&sortBy=id")
      .then((x) => {
        settotalActiveuser(x.length);
      });
    modelService.get("?orderBy=desc&sortBy=id").then((x) => {
      if (x.results) setmodels(x.results);
    });
    modelService.get("?paginate=false&orderBy=desc&sortBy=id").then((x) => {
      settotalmodels(x.length);
    });
  }, []);

  const OVERVIEW_CARDS = [
    {
      title: t("label.totalUsers"),
      total: totaluser,
      icon: TotalUserIcon.SOURCE,
    },
    {
      title: t("label.totalNewUsers"),
      total: totalActiveuser,
      icon: NewUserIcon.SOURCE,
    },
    {
      title: t("label.totalModels"),
      total: totalmodels,
      icon: ModalIcon.SOURCE,
    },
    {
      title: t("label.totalMedicalInstitutions"),
      total: "XXX",
      icon: TotalMedicalIcon.SOURCE,
    },
  ];

  const linegraphProps = {
    options: {
      chart: {
        id: "basic-bar",
      },
      bar: {
        horizontal: false,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            legend: {
              position: "bottom",
            },

            series: [
              {
                name: "Users",
                data: [30, 60, 48, 90],
              },
              {
                name: "Modals",
                data: [35, 42, 45, 30],
              },
              {
                name: "Medical Institutions",
                data: [32, 45, 49, 70],
              },
            ],
            xaxis: {
              categories: ["Jan", "Apr", "July", "Oct"],
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Users",
        data: [30, 60, 48, 90, 67, 10, 50, 41, 12, 54, 65, 34],
      },
      {
        name: "Modals",
        data: [35, 42, 45, 30, 29, 50, 10, 91, 23, 87, 32, 89],
      },
      {
        name: "Medical Institutions",
        data: [32, 45, 49, 70, 99, 100, 100, 21, 21, 54, 98, 67],
      },
    ],
  };

  return (
    <div className="dashboard-page">
      <div className="dashboard-title d-lg-flex justify-content-between align-items-center">
        <div className="title">
          <p className="page-title">{t("label.dashboard")}</p>
          <h2 className=" ">{t("label.overview")}</h2>
        </div>
        <div className="search-section me-2">
          <input className="ps-3 py-2 search-input" placeholder="Search" />
        </div>
      </div>
      <div className="overview-cards d-flex flex-wrap justify-content-between">
        {OVERVIEW_CARDS.map((item, index) => (
          <div
            className="overview-card box-shadow-primary px-4 mt-3 mx-2 py-3 flex-fill d-flex justify-content-between align-items-stretch cursor-pointer"
            key={index}
          >
            <div className="card-title">
              <p className="title">{item.title}</p>
              <p className="numbers">{item.total}</p>
            </div>

            <img src={item.icon} alt="card icon" />
          </div>
        ))}
      </div>
      <div className="overview-linegraph mt-4">
        <h3 className="page-heading">{t("label.overallInformation")}</h3>
        <div className="chart-container py-3">
          <Chart
            options={linegraphProps.options}
            series={linegraphProps.series}
            type="bar"
            width="100%"
            height="300"
          />
        </div>
      </div>
      <div className="user-section mt-5">
        <div className="title d-flex justify-content-between">
          <h3 className=" ">{t("label.totalUsers")}</h3>
          <Link to={ADMIN.Users.path}>
            <p className="link">{t("label.viewAll")}</p>
          </Link>
        </div>
        <div className="user-table">
          <SimpleTable columns={user_table_columns}>
            <TableRow data={user?.results} columns={user_table_columns} />
          </SimpleTable>
        </div>
      </div>
      <div className="row my-3">
        <div className="modal-title col-md-12 col-lg-6 mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="page-heading">{t("label.totalModels")}</h3>
            <Link to={ADMIN.Modals.path}>
              <p className="link">{t("label.viewAll")}</p>
            </Link>
          </div>
          <div className="detail-card box-shadow-primary px-5 mt-2">
            {models?.map((item, index) => (
              <div className="detail-section py-4" key={index}>
                <p>
                  {t("label.makerName")}
                  <span className="field-value ms-2">{item?.maker?.name}</span>
                </p>
                <p className="mt-2">
                  {t("label.modelName")}
                  <span className="field-value ms-2">
                    : {item.product_name}
                  </span>
                </p>
                <p className="mt-2">
                  {t("label.searchForm.modelNumber")}
                  <span className="field-value ms-2">
                    : {item.model_number}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="medical-title col-md-12 col-lg-6 mt-4">
          <div className="medical-section d-flex justify-content-between align-items-center">
            <h3 className="page-heading">
              {t("label.totalMedicalInstitutions")}
            </h3>
            <Link to={ADMIN.MedicalInstitute.path}>
              <p className="link">{t("label.viewAll")}</p>
            </Link>
          </div>
          <div className="detail-card box-shadow-primary px-5 mt-2">
            {[...Array(3)].map((item, index) => (
              <div className="detail-section py-3" key={index}>
                <p>
                  {t("label.personIncharge")}
                  <span className="field-value ms-2">: Shankhar</span>
                </p>
                <p className="mt-2">
                  {t("label.nameofMedicalInstitution")}
                  <span className="field-value ms-2">
                    : Kathmandu Medical College
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
