import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import FAQTable from "components/Table/FAQ";
import GenreTable from "components/Table/Genre";
import MakerTable from "components/Table/Maker";
import OccupationTable from "components/Table/Occupation";
import PrivacyPolictTable from "components/Table/PrivacyPolicy";
import SalesConditionTable from "components/Table/SalesCondition";
import TermsOfServiceTable from "components/Table/TermsofService";

import { t } from "i18next";

function Settings() {
  return (
    <div className="box-shadow-primary settings p-lg-5 p-2">
      <Tabs defaultActiveKey="Occupation" id="settings" className="mb-3 tabs">
        <Tab eventKey="Occupation" title={t("label.form.occupation")}>
          <OccupationTable />
        </Tab>
        <Tab eventKey="Makers" title={t("label.makers")}>
          <MakerTable />
        </Tab>
        <Tab eventKey="Genres" title={t("label.searchForm.genre")}>
          <GenreTable />
        </Tab>
        <Tab eventKey="Sales Condition" title={t("label.salesCondition")}>
          <SalesConditionTable />
        </Tab>
        <Tab eventKey="Terms of Service" title={t("label.termsofservice")}>
          <TermsOfServiceTable />
        </Tab>
        <Tab eventKey="Privacy Policy" title={t("label.privacyPolicy")}>
          <PrivacyPolictTable />
        </Tab>
        <Tab eventKey="FAQ" title={t("label.FAQ")}>
          <FAQTable />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Settings;
