import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { modelService } from "services/modelService";

import NotFound from "components/FilterNotFound";
import CustomPagination from "components/Pagination/pagination";
import ProductCard from "components/ProductCard/Productcard";
import UserLayout from "components/UserLayout/UserLayout";

import { DropDownIcon } from "constants/icons";

const ModelList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [itemPerPage, setItemPerPage] = useState(20);
  const [models, setModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    modelService.get(`?page_size=${20}&orderBy=desc&sortBy=id`).then((x) => {
      if (x.results) setModels(x);
    });
  }, []);

  function getUpdatedData(pagedata) {
    modelService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setModels(x));
  }

  const [pageCount, setpageCount] = useState(0);
  const currentPage = models?.currentPage || 1;

  useEffect(() => {
    const total = models?.count;

    const pgCount = Math.ceil(total / itemPerPage);

    setpageCount(pgCount);
  }, [models, pageCount]);

  useEffect(() => {
    if (currentPage > 1) {
      handleSearch();
    }
  }, [itemPerPage]);

  const handleSearch = () => {
    const pagedata = {
      page: 1,
      perPage: itemPerPage,
    };

    getUpdatedData(pagedata);
  };

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;

    fetchTransactions(currentPage);
  };

  const fetchTransactions = (currentPage) => {
    const pagedata = {
      page: currentPage,
      perPage: itemPerPage,
    };

    getUpdatedData(pagedata);
  };

  useEffect(() => {
    if (searchInput)
      modelService.filter(`?search=${searchInput}`).then((x) => setModels(x));
  }, [searchInput]);

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setSearchInput(searchTerm);
  };

  return (
    <UserLayout>
      <div className="learning-lists container d-block d-lg-flex pt-5">
        <div className="flex-grow-1">
          <div className="title-search">
            <h6 className="subtitle">
              {t("label.model")} {t("action.list")}
            </h6>
            <div className="d-flex justify-content-between align-items-center">
              <div className="searchbar w-50">
                <Form
                  onSubmit={(e) => handleSubmitSearch(e)}
                  className="d-flex"
                >
                  <Form.Group className="w-50">
                    <InputGroup>
                      <InputGroup.Text className="bg-white pe-4 border-right-0">
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder={t("label.search")}
                        className="search-input border-left-0"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <button
                    className="btn btn-secondary ms-lg-3 ms-3 filter-button text-dark"
                    type="submit"
                  >
                    {t("action.filter")}
                  </button>
                </Form>
              </div>
              {models?.results?.length ? (
                <div className="filter d-flex align-items-center">
                  <p>{t("label.showperPage")}</p>
                  <button
                    className="toggle-dropdown dropdown-toggle show-items d-flex align-items-center px-3 py-2 ms-2"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p className="mb-0">{itemPerPage}</p>
                    <img src={DropDownIcon.SOURCE} className="ms-2" />
                  </button>
                  <ul className="dropdown-menu box-shadow-secondary page-options mt-2 text-align-left w-auto">
                    <li
                      className="px-3 cursor-pointer"
                      onClick={() => setItemPerPage(20)}
                    >
                      20
                    </li>
                    <li
                      className="px-3 cursor-pointer"
                      onClick={() => setItemPerPage(40)}
                    >
                      40
                    </li>
                    <li
                      className="px-3 cursor-pointer"
                      onClick={() => setItemPerPage(80)}
                    >
                      80
                    </li>
                    <li
                      className="px-3 cursor-pointer"
                      onClick={() => setItemPerPage(100)}
                    >
                      100
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          {models?.results?.length ? (
            <>
              <div className="learning-modules row mt-3">
                {models?.results?.map((item, idx) => (
                  <div
                    className="col-lg-3 col-sm-4 col-sm-6 col-xs-12"
                    key={idx}
                  >
                    <div
                      onClick={() =>
                        history.push(`home/model-detail/${item.id}`)
                      }
                      className="text-dark cursor-pointer"
                    >
                      <ProductCard
                        image={item.image}
                        name={item.product_name}
                        productNum={item.model_number}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="page-wrap ">
                <p className="showing">
                  {t("label.Showing")}
                  &nbsp;{currentPage}
                  &nbsp;{t("label.to")}
                  &nbsp;{pageCount}
                  &nbsp;{t("label.of")}
                  &nbsp;{models?.count}
                  &nbsp;{t("label.entries")}.
                </p>
                <CustomPagination
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                />
              </div>
            </>
          ) : (
            <NotFound keyword={searchInput} />
          )}
        </div>
      </div>
    </UserLayout>
  );
};

export default ModelList;
