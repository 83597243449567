import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { alertService } from "services/alertService";
import { authService } from "services/authService";

import { ProfileIcon } from "constants/icons";

import { AuthContext } from "context-api/AuthContext";

import { t } from "i18next";

const Profile = () => {
  const { setuserUpdated, userUpdated } = useContext(AuthContext);
  const [isEditable, setIsEditable] = useState(true);
  const [userDetails, setUserDetails] = useState({
    image: "",
    name: "",
    furigana: "",
    email: "",
  });
  const [prevImage, setPrevImage] = useState();

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handlePictureChange = (e) => {
    if (e.target.name === "image") {
      setUserDetails({
        ...userDetails,
        [e.target.name]: e.target.files[0],
      });
      setPrevImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    let userToken = localStorage.getItem("token");

    authService.getUserToken(userToken).then((x) => {
      setUserDetails(x);
      setPrevImage(x.image);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();

    if (userDetails.image)
      form_data.append("image", userDetails.image, userDetails.image.name);
    form_data.append("email", userDetails.email);
    form_data.append("name", userDetails.name);
    form_data.append("furigana", userDetails.furigana);

    authService
      .updateUserProfile(userDetails?.id, form_data)
      .then((x) => {
        alertService.success(
          `${t("label.user")} ${t("message.updatedSuccessfully")}`,
          {
            keepAfterRouteChange: true,
            autoClose: true,
          }
        );
        setUserDetails(x);
        setuserUpdated(!userUpdated);
      })
      .catch((err) => {
        alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
      });
  };

  const uploadImage = () => {
    const input = document.getElementById("update-profile");

    input.click();
  }
  
  return (
    <div className="profile container my-5">
      <div className="profile-section box-shadow-primary">
        <div className="alert-section rounded-top"></div>
        <div className="profile-details pb-5 px-5 position-relative">
          <div className="profile-avatar position-relative d-block align-items-center d-lg-flex">
            <div className="image-section rounded-circle position-relative overflow-hidden">
              <img
                src={prevImage ? prevImage : ProfileIcon.SOURCE}
                alt="profile image"
                className="profile-picture"
              />

              {!isEditable && (
                <div onClick = {() => uploadImage()} className="change-profile-image text-white pt-1 pb-4 px-3 text-center position-absolute cursor-pointer">
                  <input
                    type="file"
                    name="image"
                    id="update-profile"
                    className="d-none"
                    onChange={(e) => handlePictureChange(e)}
                  />
                  <label>
                    <p className="cursor-pointer">{t("label.changeImage")}</p>
                  </label>
                </div>
              )}
            </div>
            <div className="user-details pt-3 pb-2 ms-2">
              <p className="name p-0">{userDetails?.name}</p>
              <p className="username p-0">{userDetails?.furigana}</p>
              <p className="email p-0">{userDetails?.email}</p>
            </div>
          </div>
          <div>
            <div className="d-block edit-title d-flex align-items-center justify-content-between py-2">
              <p>{t("message.editYourInformation")}</p>
              {isEditable && (
                <button
                  className="btn edit-button px-4"
                  onClick={() => setIsEditable(false)}
                >
                  {t("buttons.edit")}
                </button>
              )}
            </div>
            <Form className="row mt-4" onSubmit={handleSubmit}>
              <Form.Group
                className={`col-lg-4 col-md-6 col-sm-12 ${
                  isEditable && "editing"
                }`}
              >
                <Form.Label>{t("label.form.name")}</Form.Label>
                <Form.Control
                  className="input-field"
                  disabled={isEditable}
                  type="text"
                  name="name"
                  required
                  onChange={(e) => handleChange(e)}
                  value={userDetails?.name}
                />
              </Form.Group>
              <Form.Group
                className={`col-lg-4 col-md-6 col-sm-12 ${
                  isEditable && "editing"
                }`}
              >
                <Form.Label>{t("label.form.furigana")}</Form.Label>
                <Form.Control
                  className="input-field"
                  disabled={isEditable}
                  type="text"
                  name="furigana"
                  onChange={(e) => handleChange(e)}
                  value={userDetails?.furigana}
                />
              </Form.Group>
              <Form.Group
                className={`col-lg-4 col-md-6 col-sm-12 ${
                  isEditable && "editing"
                }`}
              >
                <Form.Label>{t("label.form.email")}</Form.Label>
                <Form.Control
                  className="input-field"
                  disabled={isEditable}
                  type="text"
                  name="email"
                  readOnly
                  onChange={(e) => handleChange(e)}
                  value={userDetails?.email}
                />
              </Form.Group>
              {!isEditable && (
                <div className="update-section">
                  <button className="btn btn-primary px-5 mt-4" type="submit">
                    {t("action.update")}
                  </button>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
