import awardIcon from "assets/images/award.svg";
import backIcon from "assets/images/BackIcon.svg";
import badgeIcon from "assets/images/Badge.svg";
import binIcon from "assets/images/Bin.svg";
import brandLogo from "assets/images/BrandLogo.svg";
import closeIcon from "assets/images/close.svg";
import dashboardLogo from "assets/images/DashboardLogo.svg";
import dashboardLogoActive from "assets/images/DashboardLogoActive.svg";
import deleteWhiteIcon from "assets/images/Delete-White.svg";
import deleteIcon from "assets/images/Delete.svg";
import doctorIcon from "assets/images/Doctor.png";
import dropdownIcon from "assets/images/Dropdown.svg";
import editIcon from "assets/images/edit.svg";
import editWhiteIcon from "assets/images/EditWhite.svg";
import failIcon from "assets/images/FailIcon.svg";
import filterIcon from "assets/images/filter-icon.svg";
import flag_of_Japan from "assets/images/Flag_of_Japan.svg";
import hamburgerIcon from "assets/images/Hamburger.svg";
import labIcon from "assets/images/Lab.svg";
import lockIcon from "assets/images/LockedIcon.svg";
import logoutIcon from "assets/images/logout.svg";
import medicalInstituteIcon from "assets/images/MedicalIcon.svg";
import medicalInstituteIconActive from "assets/images/MedicalIconActive.svg";
import modalIcon from "assets/images/ModalList.svg";
import noPageIcon from "assets/images/noPage.svg";
import notFoundIcon from "assets/images/NotFoundSearch.svg";
import passwordIcon from "assets/images/password.svg";
import passwordHideIcon from "assets/images/PasswordHide.svg";
import passwordShowIcon from "assets/images/PasswordShow.svg";
import patientIcon from "assets/images/Patient.svg";
import pdfIcon from "assets/images/pdf.svg";
import productsIcon from "assets/images/ProductIcon.svg";
import productsIconActive from "assets/images/ProductIconActive.svg";
import profile from "assets/images/ProfileIcon.png";
import profileIcon from "assets/images/ProfileImage.svg";
import rightIcon from "assets/images/RightArrow.svg";
import searchIcon from "assets/images/search-icon.svg";
import seminarIcon from "assets/images/Seminar.svg";
import settingIcon from "assets/images/Setting.svg";
import studyIcon from "assets/images/Study.svg";
import medicalTotalIcon from "assets/images/TotalMedical.svg";
import totalNewUserIcon from "assets/images/TotalNewUser.svg";
import totalUsers from "assets/images/TotalUsers.svg";
import userListIcon from "assets/images/UserlistLogo.svg";
import userListIconActive from "assets/images/UserlistLogoActive.svg";
import videoIcon from "assets/images/VideoIcon.svg";

export const FailIcon = {
  SOURCE: failIcon,
};
export const PDFIcon = {
  SOURCE: pdfIcon,
};
export const LockIcon = {
  SOURCE: lockIcon,
};
export const BinIcon = {
  SOURCE: binIcon,
};
export const LabIcon = {
  SOURCE: labIcon,
};
export const SeminarIcon = {
  SOURCE: seminarIcon,
};
export const StudyIcon = {
  SOURCE: studyIcon,
};
export const PasswordIcon = {
  SOURCE: passwordIcon,
};
export const NotFoundPage = {
  SOURCE: noPageIcon,
};
export const NotfoundIcon = {
  SOURCE: notFoundIcon,
};
export const BadgeIcon = {
  SOURCE: badgeIcon,
};

export const Flag_of_Japan = {
  SOURCE: flag_of_Japan,
};

export const VideoIcon = {
  SOURCE: videoIcon,
};

export const Profile = {
  SOURCE: profile,
};
export const EditWhiteIcon = {
  SOURCE: editWhiteIcon,
};
export const DeleteIcon = {
  SOURCE: deleteIcon,
  WHITE: deleteWhiteIcon,
};
export const CloseIcon = {
  SOURCE: closeIcon,
};
export const EditIcon = {
  SOURCE: editIcon,
};
export const DoctorImage = {
  SOURCE: doctorIcon,
};
export const PatientImage = {
  SOURCE: patientIcon,
};
export const BackIcon = {
  SOURCE: backIcon,
};
export const PasswordShowIcon = {
  SOURCE: passwordShowIcon,
};

export const PasswordHideIcon = {
  SOURCE: passwordHideIcon,
};

export const RightArrow = {
  SOURCE: rightIcon,
};

export const SettingIcon = {
  SOURCE: settingIcon,
};
export const SearchIcon = {
  SOURCE: searchIcon,
};

export const LogoutIcon = {
  SOURCE: logoutIcon,
};

export const BrandLogo = {
  SOURCE: brandLogo,
};

export const DashboardLogo = {
  SOURCE: dashboardLogo,
  ACTIVE_SOURCE: dashboardLogoActive,
};

export const DropDownIcon = {
  SOURCE: dropdownIcon,
};

export const AwardIcon = {
  SOURCE: awardIcon,
};

export const FilterIcon = {
  SOURCE: filterIcon,
};

export const HamburgerIcon = {
  SOURCE: hamburgerIcon,
};

export const MedicalIcon = {
  SOURCE: medicalInstituteIcon,
  ACTIVE_SOURCE: medicalInstituteIconActive,
};

export const TotalMedicalIcon = {
  SOURCE: medicalTotalIcon,
};

export const ModalIcon = {
  SOURCE: modalIcon,
};

export const ProfileIcon = {
  SOURCE: profileIcon,
};

export const ProductsIcon = {
  SOURCE: productsIcon,
  ACTIVE_SOURCE: productsIconActive,
};

export const NewUserIcon = {
  SOURCE: totalNewUserIcon,
};

export const UserListIcon = {
  SOURCE: userListIcon,
  ACTIVE_SOURCE: userListIconActive,
};

export const TotalUserIcon = {
  SOURCE: totalUsers,
};
