import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import {
  DashboardLogo,
  UserListIcon,
  MedicalIcon,
  ProductsIcon,
} from "constants/icons";

import { t } from "i18next";

import { useWindowDimensions } from "utils/getDimensions";

const navItems = [
  {
    key: "Dashboard",
    icon: DashboardLogo.SOURCE,
    activeicon: DashboardLogo.ACTIVE_SOURCE,
    label: t("label.dashboard"),
    link: "/admin/dashboard",
  },
  {
    key: "users",
    icon: UserListIcon.SOURCE,
    activeicon: UserListIcon.ACTIVE_SOURCE,
    label: `${t("label.user")} ${t("action.list")}`,
    link: "/admin/users",
  },
  {
    key: "modal list",
    icon: ProductsIcon.SOURCE,
    activeicon: ProductsIcon.ACTIVE_SOURCE,
    label: `${t("label.model")} ${t("action.list")}`,
    link: "/admin/models",
  },
  {
    key: "medical institute",
    icon: MedicalIcon.SOURCE,
    activeicon: MedicalIcon.ACTIVE_SOURCE,
    label: t("label.medicalInstitution"),
    link: "/admin/medical-institute",
  },
];

const Sidebar = ({ collapsed, setCollapsed }) => {
  const [activeNav, setActiveNav] = useState("/dashboard");

  const { width } = useWindowDimensions();
  const history = useHistory();

  const onNavClick = (item) => {
    history.push(item.link);
    setActiveNav(item.label);
    if (width < 768) {
      setCollapsed(true);
    }
  };

  return (
    <div className={`sidebar flex-shrink-1 ${collapsed ? "hide" : "show"}`}>
      <Nav>
        <ul className="ps-3 pe-1 mt-4">
          {navItems.map((item) => (
            <li
              className={`list-group-item ${
                collapsed ? "mobile-nav" : ""
              } px-3 py-2 my-1 align-items center ${
                activeNav === item.label
                  ? "active-navigation"
                  : "inactive-navigation"
              }`}
              role="menuitem"
              onClick={() => onNavClick(item)}
              key={item.key}
            >
              <img
                src={activeNav === item.label ? item.activeicon : item.icon}
                alt={item.key}
                className="sidebar-icon"
              />
              <span className="ms-3">{item.label}</span>
            </li>
          ))}
        </ul>
      </Nav>
    </div>
  );
};

export default Sidebar;
