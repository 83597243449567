import React from "react";
import { useTranslation } from "react-i18next";


export default function ResetPasswordLinkSent() {
  const { t } = useTranslation();

  return (
    <div className="landing-page-wrapper login-page d-flex justify-content-center align-items-center">
      <div className="row landing-page-container login-container h-auto align-items-center justify-content-center text-center">
        <div className="p-md-2 p-lg-5">
          <h3 className="hero-title border-0">
            {t("message.passwordResetLinkHasBeenSend")}
          </h3>
          <div className="d-flex align-items-center justify-content-center w-100">
            <a
              target="_blank"
              className="signup-btn"
              href={"https://gmail.com/"}
            >
              {t("message.Checkyourmail")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
