import Dashboard from "pages/Admin/Dashboard/Dashboard";
import Login from "pages/Admin/Login/Login";
import MedicalInstitute from "pages/Admin/MedicalInstitute/MedicalInstitute";
import AddModal from "pages/Admin/Modal/AddModal";
import ModalDetails from "pages/Admin/Modal/ModalDetail";
import ModalList from "pages/Admin/Modal/ModalList";
import Profile from "pages/Admin/Profile";
import Registration from "pages/Admin/Registration/Registration";
import Settings from "pages/Admin/Settings";
import UserDetail from "pages/Admin/Users/UserDetail";
import UserList from "pages/Admin/Users/UserList";
import NotExistingPage from "pages/Site/404";

import ChangePassword from "components/Auth/ChangePassword";
import ForgotPassword from "components/Auth/ForgotPassword";
import VerificationSent from "components/Auth/RegisterEmailSent";
import Logout from "components/Logout/Logout";

const SIGN_IN = { path: "/signin" };
const SIGN_UP = { path: "/signup" };

export { SIGN_IN, SIGN_UP };

export const SITE = {
  Index: { path: "/" },
  Home: { path: "/home" },
  Profile: { path: "/home/profile" },
  SearchList: { path: "/home/search" },
  SearchBox: { path: "/home/search-box" },
  ModelList: { path: "/home/model-list" },
  ModelDetail: { path: "/home/model-detail/:id" },
  LearningList: { path: "/home/learning-list" },
  ChangePassword: { path: "/home/change-password" },
  FAQ: { path: "/faq" },
  Login: { path: "/login" },
  NotFound: { path: "/404" },
  Logout: { path: "/logout" },
  Inquiry: { path: "/inquiry" },
  Registration: { path: "/register" },
  ForgotPassword: { path: "/forgot-password" },
  TermsofService: { path: "/terms-of-service" },
  PrivacyPolicy: { path: "/privacy-policy" },
  RegisterEmailSent: { path: "/verification-email-sent" },
  ConfirmUser: { path: "/accounts/email-verify" },
  ResetLinkSent: { path: "/accounts/reset-email-sent" },
  ConfirmPasswordReset: { path: "/accounts/password-reset/:id/:token" },
};

export const MEDIN = {
  Index: { path: "/medical-institution" },
};

export const ADMIN = {
  // pages
  Index: { path: "/admin", component: Dashboard },
  Register: { path: "/admin/register", component: Registration },
  Auth: { path: "/admin/authenticate", component: Login },
  VerificationSent: {
    path: "/admin/verification-email-sent",
    component: VerificationSent,
  },
  Dashboard: { path: "/admin/dashboard", component: Dashboard },
  Logout: { path: "/admin/logout", component: Logout },
  Users: { path: "/admin/users", component: UserList },
  Profile: { path: "/admin/profile", component: Profile },
  UserDetail: { path: "/admin/users/view/:id", component: UserDetail },
  Modals: { path: "/admin/models", component: ModalList },
  ModalDetails: { path: "/admin/models/view/:id", component: ModalDetails },
  AddModal: { path: "/admin/models/add", component: AddModal },
  MedicalInstitute: {
    path: "/admin/medical-institute",
    component: MedicalInstitute,
  },
  Settings: { path: "/admin/settings", component: Settings },
  ChangePassword: { path: "/admin/change-password", component: ChangePassword },
  ForgotPassword: { path: "/admin/forgot-password", component: ForgotPassword },
  NotFound: { path: "/admin/notfound", component: NotExistingPage },
};
