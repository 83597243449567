import React, { useEffect, useState } from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";

import { faEye, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userService } from "services/userService";

import Preloader from "components/Preloader/Preloader";
import { CustomTable } from "components/Table/CustomTable";

import { t } from "i18next";

// import { ADMIN } from "constants/routes";

const TableRow = ({ data, columns, searchTerm }) => {
  const { path } = useRouteMatch();

  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.medical_license_number}</td>
      <td className="text-wrap">{pt.name}</td>
      <td className="text-wrap">{pt.work_place}</td>
      <td className="text-wrap">{pt.address}</td>
      <td className="text-wrap">
        <Link
          as={Button}
          to={`${path}/view/${pt.id}`}
          data-toggle="tooltip"
          data-placement="top"
          title={t("label.viewDetails")}
        >
          <FontAwesomeIcon icon={faEye} className="me-2" />
        </Link>
      </td>
    </tr>
  ));
};

const UserList = () => {
  const [loaded, setLoaded] = useState(false);
  const [user, setuser] = useState();
  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    { name: t("label.form.med_lic_no") },
    { name: t("label.form.name") },
    { name: t("label.affilatedMedicalInstitute") },
    { name: t("label.form.address") },
    { name: t("label.form.action") },
  ];

  useEffect(() => {
    setLoaded(true);
    userService.get("?orderBy=desc&sortBy=id").then((x) => {
      if (x.results) setuser(x);
    });
    setLoaded(false);
  }, [update]);

  function getUpdatedData(pagedata) {
    userService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setuser(x));
  }

  useEffect(() => {
    if (searchInput)
      userService.filter(`?search=${searchInput}`).then((x) => setuser(x));
  }, [searchInput]);

  return (
    <Preloader show={loaded} className="my-5 py-5">
      <div className="box-shadow-primary p-lg-5 p-3 mt-5">
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t("label.user")}</Breadcrumb.Item>
              <Breadcrumb.Item active>
                {t("action.list")} {t("label.user")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <h4>{t("label.user")}</h4>
          </div>
          <div className="addBtn-wrapper mb-4 mb-xl-0">
            {/* <Link
              className="px-3 py-2 btn-primary rounded-1"
              to={`${ADMIN.Users.path}/add/`}
            >
              <FontAwesomeIcon icon={faPlus} /> {t('action.add')} {t('label.user')}
            </Link> */}
          </div>
        </div>

        <CustomTable
          columns={columns}
          data={user}
          setUpdate={setUpdate}
          update={update}
          getUpdatedData={getUpdatedData}
          setSearchInput={setSearchInput}
        >
          <TableRow
            data={user?.results}
            columns={columns}
            searchTerm={searchInput}
          />
        </CustomTable>
      </div>
    </Preloader>
  );
};

export default UserList;
