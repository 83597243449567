import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { modelService } from "services/modelService";

import ProductCard from "components/ProductCard/Productcard";

const Maker = ({ genre }) => {
  const [models, setModels] = useState([]);

  useEffect(() => {
    modelService
      .get(`?page_size=${4}&orderBy=desc&sortBy=id&genreId=${genre?.id}`)
      .then((x) => {
        setModels(x.results);
      });
  }, []);

  if (!models.length) return null;

  return (
    <div className="container mt-4">
      <div className="product-header content-container">
        <h5>{genre?.name}</h5>
      </div>
      <div className="row content-container">
        {models?.map((product, index) => (
          <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3" key={index}>
            <Link to={`home/model-detail/${product.id}`} className="text-black">
              <ProductCard
                image={product?.image}
                name={product?.product_name}
                productNum={product?.model_number}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Maker;
