import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { alertService } from "services/alertService";
import { salesConditionService } from "services/salesConditionService";

import { CustomTable } from "./CustomTable";

import { DeleteIcon, EditIcon } from "constants/icons";

import { t } from "i18next";

const TableRow = ({
  data,
  columns,
  deleteCondition,
  setActiveCondition,
  activeCondition,
  searchTerm,
}) => {
  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.created_at}</td>
      <td className="text-wrap">{pt.name}</td>
      <td className="text-wrap">{pt.updated_at}</td>
      <td className="text-wrap">
        <div
          className={`action-icons ${
            pt.id === activeCondition ? "d-none" : ""
          }`}
        >
          <img
            src={EditIcon.SOURCE}
            className="ms-2 cursor-pointer"
            onClick={() => setActiveCondition(pt.id)}
          />
          <img
            src={DeleteIcon.SOURCE}
            className="mx-2 cursor-pointer"
            onClick={() => deleteCondition(pt.id)}
          />
        </div>
      </td>
    </tr>
  ));
};

const SalesConditionTable = () => {
  const { t } = useTranslation();

  const [activeCondition, setActiveCondition] = useState("");
  const [newCondition, setNewCondition] = useState("");
  const [salesCondition, setSalesCondition] = useState([]);
  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    { name: t("label.date") },
    { name: t("label.form.name") },
    { name: t("label.updateAt") },
    { name: t("label.form.action") },
  ];

  useEffect(() => {
    salesConditionService
      .get("?orderBy=desc&sortBy=id")
      .then((x) => setSalesCondition(x));
  }, [update]);

  function getUpdatedData(pagedata) {
    salesConditionService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setSalesCondition(x));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let form_data = new FormData();

    form_data.append("name", newCondition);

    if (!activeCondition) {
      addSalesCondition(form_data);
    } else {
      updateSalesCondition(activeCondition, form_data);
    }
    setSalesCondition("");
    setActiveCondition(null);
    setNewCondition("");
  };

  const addSalesCondition = (fields) => {
    salesConditionService
      .create(fields)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          alertService.success(
            `${t("label.salesCondition")} ${t("message.addedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setNewCondition("");
  };
  const updateSalesCondition = (id, fields) => {
    salesConditionService
      .update(id, fields)
      .then((res) => {
        if (res.status === 200) {
          alertService.success(
            `${t("label.salesCondition")} ${t("message.updatedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setActiveCondition("");
    setNewCondition("");
  };

  const deleteCondition = (id) => {
    salesConditionService.delete(id).then(() => {
      alertService.success(t("message.deletedSuccessfuly"), {
        keepAfterRouteChange: true,
        autoClose: true,
      });
      setUpdate(!update);
    });
  };

  useEffect(() => {
    if (activeCondition) {
      salesConditionService
        .getById(activeCondition)
        .then((x) => setNewCondition(x.name));
    }
  }, [activeCondition]);

  useEffect(() => {
    if (searchInput)
      salesConditionService
        .filter(`?search=${searchInput}`)
        .then((x) => setSalesCondition(x));
  }, [searchInput]);

  return (
    <div className="p-3 mt-5 h-100">
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end mb-3">
        <div className="d-block mb-4 mb-xl-0">
          <h4>{t("label.salesCondition")}</h4>
        </div>
      </div>
      <div className="border-bottom pb-3 mb-4">
        <Form className="row" onSubmit={handleSubmit}>
          <Form.Group className="row">
            <Form.Label className="me-2">{t("label.form.name")}:</Form.Label>
            <div className="d-flex col-lg-4 col-md-12">
              <Form.Control
                className="input-field w-100 col-lg-6"
                type="text"
                name="name"
                value={newCondition}
                required
                onChange={(e) => setNewCondition(e.target.value)}
              />

              <div className="text-align-center ms-3 col-lg-6">
                <button className="btn add-btn px-3 py-2 w-100" type="submit">
                  {`${
                    activeCondition ? t("action.update") : t("action.add")
                  } ${t("label.salesCondition")}`}
                </button>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
      <CustomTable
        columns={columns}
        data={salesCondition}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
        setSearchInput={setSearchInput}
      >
        <TableRow
          data={salesCondition?.results}
          columns={columns}
          setActiveCondition={setActiveCondition}
          activeCondition={activeCondition}
          deleteCondition={deleteCondition}
          searchTerm={searchInput}
        />
      </CustomTable>
    </div>
  );
};

export default SalesConditionTable;
