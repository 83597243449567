import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { alertService } from "services/alertService";
import { genreService } from "services/genreService";
import { makerService } from "services/makerService";
import { modelService } from "services/modelService";
import { salesConditionService } from "services/salesConditionService";

import ModalLayout from "./ModalLayout";

import { t } from "i18next";

import { uploadPlugin } from "utils/ckeditorUploader";

const EditModelDetails = ({
  isVisible,
  setIsVisible,
  update,
  setUpdate,
  modelId,
}) => {
  const [makers, setMakers] = useState([]);
  const [genres, setGenres] = useState([]);
  const [salesConditions, setSalesConditions] = useState([]);
  const [instruction, setInstruction] = useState();
  const [editedModel, setEditedModel] = useState({
    maker: "",
    genre: "",
    product_name: "",
    model_number: "",
    service_duration: "",
    instruction: "",
    instruction_mannual: "",
    package_insert: "",
    sales_condition: "",
    product_inquiry: "",
    image: "",
  });
  const [prevImage, setprevImage] = useState("");
  const [prevInstruction, setprevInstruction] = useState("");
  const [prevPackageInsert, setprevPackageInsert] = useState("");

  const handleChange = (e) => {
    setEditedModel({
      ...editedModel,
      [e.target.name]: e.target.value,
    });
  };

  const inputHandler = (event, editor) => {
    const data = editor.getData();

    setInstruction(data);
  };

  useEffect(() => {
    makerService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setMakers(x));
    genreService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setGenres(x));
    salesConditionService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setSalesConditions(x));
  }, []);

  useEffect(() => {
    if (modelId) {
      modelService.getById(modelId).then((x) => {
        setEditedModel({
          maker: x.maker.id,
          genre: x.genre.id,
          product_name: x.product_name,
          model_number: x.model_number,
          service_duration: x.service_duration,
          sales_condition: x.sales_condition.id,
          product_inquiry: x.product_inquiry,
          instruction: x.instruction,
        });
        setprevImage(x.image);
        setprevInstruction(x.instruction_mannual);
        setprevPackageInsert(x.package_insert);
      });
    }
  }, [modelId]);

  const handleFileUpload = (e) => {
    setEditedModel({
      ...editedModel,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();

    if (editedModel.image)
      form_data.append("image", editedModel.image, editedModel.image.name);

    if (editedModel.instruction_mannual)
      form_data.append(
        "instruction_mannual",
        editedModel.instruction_mannual,
        editedModel.instruction_mannual.name
      );

    if (editedModel.package_insert)
      form_data.append(
        "package_insert",
        editedModel.package_insert,
        editedModel.package_insert.name
      );
    form_data.append("product_name", editedModel.product_name);
    form_data.append("model_number", editedModel.model_number);
    form_data.append("service_duration", editedModel.service_duration);
    form_data.append("product_inquiry", editedModel.product_inquiry);
    form_data.append("instruction", instruction);
    form_data.append("maker", editedModel.maker);
    form_data.append("genre", editedModel.genre);
    form_data.append("sales_condition", editedModel.sales_condition);

    modelService
      .update(modelId, form_data)
      .then((res) => {
        if (res.status === 200) {
          alertService.success(
            `${t("label.model")} ${t("message.updatedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
          setIsVisible(false);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
  };

  const makerOptions = makers?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  const genreOptions = genres?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  const salesConditionOptions = salesConditions?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  const pickFile = (id) => {
    const inputField = document.getElementById(id);

    inputField.click();
  };

  function deleteOperation(id) {
    modelService.delete(id).then(() => {
      alertService.success("Deleted Successfully", {
        keepAfterRouteChange: true,
        autoClose: true,
      });
      setUpdate(!update);
    });
  }

  return (
    <ModalLayout
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      className="custom-modal box-shadow-primary"
    >
      <div className="modal-header py-3 px-5">
        {t("buttons.edit")} {t("label.model")}
      </div>
      <div className="flex-grow-1 h-100 content d-flex flex-column justify-content-end">
        <div className="edit-form">
          <Form onSubmit={handleSubmit}>
            <div className="py-5 px-0 px-md-3 px-lg-5 row align-items-end">
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.searchForm.makerName")}</Form.Label>

                <Form.Select
                  name="maker"
                  value={editedModel?.maker}
                  onChange={(e) => handleChange(e)}
                >
                  {makerOptions}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.searchForm.genre")}</Form.Label>
                <Form.Select
                  name="genre"
                  value={editedModel?.genre}
                  onChange={(e) => handleChange(e)}
                >
                  {genreOptions}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.searchForm.productName")}</Form.Label>
                <Form.Control
                  type="text"
                  name="product_name"
                  value={editedModel?.product_name}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.searchForm.modelNumber")} </Form.Label>
                <Form.Control
                  type="text"
                  name="model_number"
                  value={editedModel?.model_number}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.serviceDuration")} </Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  name="service_duration"
                  value={editedModel?.service_duration}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.image")} </Form.Label>
                <a href={`${process.env.REACT_APP_BASE_URL}${prevImage}`}>
                  {prevImage}
                </a>
                <InputGroup>
                  <Form.Control
                    name="image"
                    type="file"
                    id="modal-image"
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <InputGroup.Text
                    className="btn"
                    onClick={() => pickFile("modal-image")}
                  >
                    {t("label.browse")}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.instructionMannual")}</Form.Label>
                <a href={`${process.env.REACT_APP_BASE_URL}${prevInstruction}`}>
                  {prevInstruction}
                </a>
                <InputGroup>
                  <Form.Control
                    type="file"
                    name="instruction"
                    id="instruction-mannual"
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <InputGroup.Text
                    className="btn"
                    onClick={() => pickFile("instruction-mannual")}
                  >
                    {t("label.browse")}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.packageInsert")}</Form.Label>
                <a
                  href={`${process.env.REACT_APP_BASE_URL}${prevPackageInsert}`}
                >
                  {prevPackageInsert}
                </a>
                <InputGroup>
                  <Form.Control
                    type="file"
                    name="package_insert"
                    id="package-insert"
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <InputGroup.Text
                    className="btn"
                    onClick={() => pickFile("package-insert")}
                  >
                    {t("label.browse")}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.salesCondition")}</Form.Label>
                <Form.Select
                  name="sales_condition"
                  value={editedModel?.sales_condition}
                  onChange={(e) => handleChange(e)}
                >
                  {salesConditionOptions}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>{t("label.productInquiry")}</Form.Label>
                <Form.Control
                  type="text"
                  name="product_inquiry"
                  value={editedModel?.product_inquiry}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>

              <div className="text-editor col-lg-12 col-md-12 mb-3">
                <Form.Group className="mb-3">
                  <Form.Label className="sub-title">
                    {t("label.instruction")}
                  </Form.Label>
                  <CKEditor
                    id="inputText"
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                    editor={ClassicEditor}
                    onChange={inputHandler}
                    data={editedModel?.instruction}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="modal-footer px-5 py-3 mt-auto d-flex jusitfy-content-end">
              {modelId && (
                <button
                  className="btn btn-danger px-3 py-2 mx-2"
                  onClick={() => {
                    if (window.confirm("Are you sure you want delete?")) {
                      deleteOperation(modelId);
                    }
                  }}
                >
                  {t("action.delete")}
                </button>
              )}
              <button
                className="btn btn-secondary px-3 py-2 mx-2"
                type="reset"
                onClick={() => setIsVisible(false)}
              >
                {t("action.cancel")}
              </button>
              <button className="btn btn-primary px-3 py-2 ms-2" type="submit">
                {t("action.update")}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </ModalLayout>
  );
};

export default EditModelDetails;
