import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import Captcha from "demos-react-captcha";
import { alertService } from "services/alertService";
import { contactService } from "services/contactService";

import UserLayout from "components/UserLayout/UserLayout";

import Emailicon from "assets/images/email-icon.svg";
import Featureimg from "assets/images/feature-img.svg";
import Hero1 from "assets/images/hero1.svg";
import Hero2 from "assets/images/hero2.svg";
import Phoneicon from "assets/images/phone-icon.svg";

import { SITE } from "constants/routes";

import { AuthContext } from "context-api/AuthContext";

const Welcome = () => {
  const { isTokenValidated } = useContext(AuthContext);

  if (isTokenValidated) {
    return <Redirect to={{ pathname: SITE.Home.path }} />;
  }
  const { t } = useTranslation();

  return (
    <>
      <UserLayout className="landing-page-wrapper">
        <div className="landing-page-container ">
          <div className="container">
            <section className="hero-section pt-4">
              <div className="row align-items-center">
                <div className="col-md-6 col-12">
                  <span className="hero-text">{t("label.kiwi")}</span>
                  <h1 className="hero-title">{t("message.heroTitle")}</h1>
                  <h1 className="hero-desc">{t("message.heroContent")}</h1>
                </div>
                <div className="col-md-6 col-12">
                  <img src={Hero1} className="hero-img1" />
                </div>
              </div>
            </section>
            {/* about section */}
            <section className="about-section section">
              <div className="row align-items-center">
                <div className="col-md-6 col-12">
                  <img src={Hero2} className="hero-img2" />
                </div>
                <div className="col-md-6">
                  <h1 className="hero-desc">{t("label.aboutKikisapo")}</h1>
                  <div className="kikisapo-desc">
                    {t("message.aboutContent")}
                  </div>
                </div>
              </div>
            </section>
            {/* feature section */}
            <section className="features-section section">
              <h1 className="hero-desc feature-title">
                {t("label.ourFeatures")}
              </h1>
              <div className="row justify-content-center">
                <div className="feature-desc col-md-8 mt-4">
                  {t("message.featureContent")}
                </div>
              </div>
              <div className="feature-image-wrapper d-flex justify-content-center">
                <img src={Featureimg} className="feature-img" />
              </div>
            </section>
            {/* query section */}
            <section className="query-section section">
              <h1 className="hero-title mt-5 mb-5">{t("label.haveaQuery")}</h1>

              <div className="row  mt-5">
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <div className="contact-wrap h-100">
                    <h4>{t("label.quickContact")}</h4>
                    <div className="contact-details">
                      <img src={Phoneicon} />
                      <span>+81-78-381-7881</span>
                    </div>
                    <div className="contact-details">
                      <img src={Emailicon} />
                      <span>aws_admin@portback.com</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12  col-sm-12 mb-3">
                  <QueryForm />
                </div>
              </div>
            </section>
          </div>
        </div>
      </UserLayout>
    </>
  );
};

const QueryForm = () => {
  const { t } = useTranslation();
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [captchaVisible, setCaptchaVisible] = useState(false);

  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    let newData = { ...data };

    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const handleCaptchaChange = () => {
    setCaptchaVisible(!captchaVisible);
  };

  const handleCaptha = (value) => {
    if (value == true) {
      setDisabledStatus(false);
    } else {
      setDisabledStatus(true);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    let form_data = new FormData();

    form_data.append("name", data.name);
    form_data.append("email", data.email);
    form_data.append("message", data.message);

    contactService
      .create(form_data)
      .then(() => {
        alertService.success(t("message.contactFormSubmitted"), {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setData({ name: "", email: "", message: "" });
        setDisabledStatus(true);
        setCaptchaVisible(false);
        e.target.reset();
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
  }

  return (
    <div className="query-form h-100">
      <h3>{t("label.fillYourForm")}</h3>
      <Form onSubmit={handleSubmit} className="w-100 mt-3">
        <Form.Group className="mb-3">
          <Form.Control
            placeholder={t("label.form.name")}
            type="text"
            name="name"
            className="form-input"
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder={t("label.form.email")}
            type="text"
            name="email"
            className="form-input"
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            placeholder={t("label.form.message")}
            name="message"
            className="form-input detail-input"
            rows={4}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <input
            type="checkbox"
            id="captcha"
            name="captcha"
            className="p-0 m-0 d-inline w-auto me-2"
            required
            onChange={handleCaptchaChange}
          />
          <label htmlFor="captcha">{t("label.proceedWithCaptcha")}</label>
        </Form.Group>
        <Form.Group
          className={`mb-3 text-black ${captchaVisible ? "d-block" : "d-none"}`}
        >
          <label htmlFor="message5">
            {t("label.captcha")} <sup className="text-danger">*</sup>
          </label>
          <Captcha
            onChange={handleCaptha}
            placeholder={`${t("action.enter")} ${t("label.captcha")}`}
            value="jfdsk"
            onRefresh={() => setDisabledStatus(true)}
          />
        </Form.Group>
        <button className="submit-btn" type="submit" disabled={disabledStatus}>
          {t("buttons.Submit")}
        </button>
      </Form>
    </div>
  );
};

export default Welcome;
