import React from "react";

import { NotFoundPage } from "constants/icons";

const NotExistingPage = () => (
  <div className="not-found-page d-flex justify-content-center align-items-center my-5 py-5 h-100 container">
    <div className="not-found-info text-center my-5">
      <img src={NotFoundPage.SOURCE} />
      <p className="my-2 title">Page Not Found</p>
      <p className="info">
        The page you are looking for doesn’t exit or other error occured .
      </p>
      <p className="info">
        Go Back,or head over to{" "}
        <span>
          <a>kikisapo.com</a>
        </span>{" "}
        to choose a new direction{" "}
      </p>
    </div>
  </div>
);

export default NotExistingPage;
