import React from "react";

import ModalLayout from "./ModalLayout";

import { CloseIcon, FailIcon } from "constants/icons";

import { t } from "i18next";

export default function FailedModel({ isVisible, setIsVisible }) {
  return (
    <ModalLayout
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      className="custom-modal box-shadow-primary"
    >
      <div className="failed-modal">
        <div className="text-center">
          <div className="d-flex flex-row-reverse m-4">
            <img
              src={CloseIcon.SOURCE}
              alt="close"
              className="cursor-pointer"
              onClick={() => setIsVisible(false)}
            />
          </div>
          <div className="my-5">
            <p className="title pt-3 text-danger">{t("message.failed")}</p>
            <div className="text-center badge-container my-4">
              <img src={FailIcon.SOURCE} alt="badge" />
            </div>
            <p className="info text-center mx-5 px-5">
              {t("message.sorryYouFailedQuiz")}
            </p>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
}
