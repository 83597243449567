import React from "react";
import ReactPaginate from "react-paginate";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomPagination = ({ pageCount, handlePageClick, currentPage }) => (
  <div className="transaction-pagination">
    <ReactPaginate
      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      subContainerClassName={"pages pagination"}
      activeClassName={"active"}
      forcePage={currentPage - 1}
    />
  </div>
);

export default CustomPagination;
