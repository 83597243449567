import React from "react";

import { NotfoundIcon } from "constants/icons";

export default function NotFound({ keyword }) {
  if (!keyword) return null;

  return (
    <div className="not-found d-flex justify-content-center align-items-center mt-4 py-5">
      <div className="not-found-info text-center my-5">
        <img src={NotfoundIcon.SOURCE} />
        <p className="my-2 title">{`No Search Found "${keyword}"`}</p>
        <p className="info">We couldnt find what you searched for.</p>
        <p className="info">Try Searching again</p>
      </div>
    </div>
  );
}
