import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { modelService } from "services/modelService";

import NotFound from "components/FilterNotFound";
import CustomPagination from "components/Pagination/pagination";
import ProductCard from "components/ProductCard/Productcard";
import Search from "components/Search";
import UserLayout from "components/UserLayout/UserLayout";

import { DropDownIcon } from "constants/icons";

const SearchList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [itemPerPage, setItemPerPage] = useState(20);
  const [models, setModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const currentPage = models?.currentPage || 1;
  const [pageCount, setpageCount] = useState(0);
  
  const { search, genre, maker, pro_mod } = location.state;

  function getUpdatedData(pagedata) {
    modelService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id&search=${search},${genre},${maker},${pro_mod}`
      )
      .then((x) => setModels(x));
  }

  useEffect(() => {
    const total = models?.count;

    const pgCount = Math.ceil(total / itemPerPage);

    setpageCount(pgCount);
  }, [models, pageCount]);

  useEffect(() => {
    if (currentPage) {
      handleSearch();
    }
  }, [itemPerPage]);

  const handleSearch = () => {
    const pagedata = {
      page: 1,
      perPage: itemPerPage,
    };

    getUpdatedData(pagedata);
  };

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;

    fetchTransactions(currentPage);
  };

  const fetchTransactions = (currentPage) => {
    const pagedata = {
      page: currentPage,
      perPage: itemPerPage,
    };

    getUpdatedData(pagedata);
  };

  useEffect(() => {
    setSearchTerm(search)
    modelService
      .get(`?search=${search},${genre},${maker},${pro_mod}`)
      .then((x) => setModels(x));
  }, [location.state]);

  return (
    <UserLayout>
      <div className="search-section-wrap">
        <div className="container">
          <Search setSearchTerm={setSearchTerm} />
        </div>
      </div>
      <div className="learning-lists container d-block d-lg-flex pt-2">
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="subtitle">
              {`${t("label.Resultsfor")} "${search}" `}
            </h6>
            <div className="title-search">
              {models?.results?.length ? (
                <div className="filter d-flex align-items-center">
                  <p>{t("label.showperPage")}</p>
                  <button
                    className="toggle-dropdown dropdown-toggle show-items d-flex align-items-center px-3 py-2 ms-2"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p className="mb-0">{itemPerPage}</p>
                    <img src={DropDownIcon.SOURCE} className="ms-2" />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-right box-shadow-secondary page-options mt-2 text-align-left w-auto">
                    <li
                      className="px-3 cursor-pointer"
                      onClick={() => setItemPerPage(20)}
                    >
                      20
                    </li>
                    <li
                      className="px-3 cursor-pointer"
                      onClick={() => setItemPerPage(40)}
                    >
                      40
                    </li>
                    <li
                      className="px-3 cursor-pointer"
                      onClick={() => setItemPerPage(80)}
                    >
                      80
                    </li>
                    <li
                      className="px-3 cursor-pointer"
                      onClick={() => setItemPerPage(100)}
                    >
                      100
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          {models?.results?.length ? (
            <>
              <div className="learning-modules row mt-3">
                {models?.results?.map((item, idx) => (
                  <div
                    className="col-lg-3 col-sm-4 col-sm-6 col-xs-12"
                    key={idx}
                  >
                    <div
                      onClick={() => history.push(`home/model-detail/${item.id}`)}
                      className="text-dark cursor-pointer"
                    >
                      <ProductCard
                        image={item.image}
                        name={item.product_name}
                        productNum={item.model_number}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="page-wrap ">
                <p className="showing">
                  {t("label.Showing")}
                  &nbsp;{currentPage}
                  &nbsp;{t("label.to")}
                  &nbsp;{pageCount}
                  &nbsp;{t("label.of")}
                  &nbsp;{models?.count}
                  &nbsp;{t("label.entries")}.
                </p>
                <CustomPagination
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                />
              </div>
            </>
          ) : (
            <NotFound keyword={searchTerm} />
          )}
        </div>
      </div>
    </UserLayout>
  );
};

export default SearchList;
