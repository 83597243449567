import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

const dataProgress = (dataModel) => {
  const { enrolled_model_operation, model } = dataModel;

  const completedOperation = enrolled_model_operation.filter(
    (item) => item.operation.model == model.id
  );

  const progress = (completedOperation.length / model.models.length) * 100;

  return progress || 5;
};

const LearningProductCard = ({ item }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  let loadingTime;

  useEffect(() => {
    loadingTime = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTime);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="product-section">
          <div className="product-card mt-3 box-shadow-primary cursor-pointer leaning-div">
            <div className="product-image modules-image">
              <Skeleton className="learning-image flex-grow-1" />
            </div>

            <div className="module-information">
              <div className="title d-flex justify-content-between align-items-center">
                <p className="module-name">
                  <Skeleton />
                </p>
              </div>
              <div className="module-model">
                <Skeleton />
              </div>
            </div>
            <div className="progress-status mx-3 pb-3">
              <p className="progress-title">
                <Skeleton />
              </p>
              <div className="progress mt-2">
                <div className="d-block progress-bar">
                  <Skeleton />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="product-section"
          onClick={() => history.push(`home/model-detail/${item.model.id}`)}
        >
          <div className="product-card mt-3 box-shadow-primary cursor-pointer leaning-div">
            <div className="product-image modules-image">
              <img
                src={item.model.image}
                alt="learning list"
                className="learning-image flex-grow-1"
              />
            </div>

            <div className="module-information">
              <div className="title d-flex justify-content-between align-items-center">
                <p className="module-name">{item.model.product_name}</p>
              </div>
              <div className="module-model">{item.model.model_number}</div>
              {/* <p className="information">{item.model.instruction}</p> */}
            </div>
            <div className="progress-status mx-3 pb-3">
              <p
                className={`progress-title ${
                  dataProgress(item) === 100 ? "text-success" : "text-warning"
                }`}
              >
                {item.status}
              </p>
              <div className="progress mt-2">
                <div
                  className={`d-block progress-bar ${
                    dataProgress(item) === 100 ? "bg-success" : "bg-warning"
                  }`}
                  style={{ width: `${dataProgress(item)}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LearningProductCard;
