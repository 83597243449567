import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { alertService } from "services/alertService";
import { enrolledModelService } from "services/enrolledModelService";
import { enrolledOperationService } from "services/erolledOperationService";

import CongratulationModel from "./CongratulationModel";
import FailedModel from "./FailedModel";
import ModalLayout from "./ModalLayout";

import { BadgeIcon } from "constants/icons";

import { t } from "i18next";

const QuizQuestions = ({ question, setCurrentAnswer }) => {
  const [correctOption, setCorrectOption] = useState("");
  const { choice1, choice2, choice3, choice4 } = question;
  const [options, setOptions] = useState([]);

  const submit = (value) => {
    setCorrectOption(value);
    setCurrentAnswer(value);
  };

  useEffect(() => {
    setOptions(
      [choice1, choice2, choice3, choice4].sort(() => Math.random() - 0.5)
    );
  }, [question]);

  return (
    <div className="quiz-section">
      <p className="quiz-question">{question?.question}</p>
      <Form className="mt-3 pe-5">
        {options.map((item, index) => (
          <Form.Group
            className="mt-2 quiz-options d-flex align-items-center px-3 py-3 cursor-pointer"
            onClick={() => submit(item)}
            key={index}
          >
            <Form.Check
              inline
              id={item}
              name="correct"
              type="radio"
              checked={correctOption === item ? true : false}
              onChange={() => {}}
            />
            <Form.Label onClick={() => submit(item)} className="cursor-pointer">
              {item}
            </Form.Label>
          </Form.Group>
        ))}
      </Form>
    </div>
  );
};

const Result = ({
  isVisible,
  setIsVisible,
  answers,
  enrolledModel,
  enrolledModelOperation,
  operationId,
}) => {
  let correctAnswers = 0;
  const [enrolledModalId, setenrolledModalId] = useState();

  for (let i = 0; i < answers.length; i++) {
    if (answers[i].answer) {
      correctAnswers += 1;
    }
  }

  const percentage = correctAnswers / Object.keys(answers).length;

  useEffect(() => {
    enrolledModelService
      .get(`?paginate=false&modelId=${enrolledModel}`)
      .then((res) => {
        setenrolledModalId(res[0].id);
      })
      .catch((err) => {
        alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
      });
  }, [enrolledModel]);

  useEffect(() => {
    if (enrolledModalId) submitQuiz();
  }, [enrolledModalId]);

  const submitQuiz = () => {
    if (enrolledModalId) {
      let form_data = new FormData();

      form_data.append("enrolledModel", enrolledModalId);
      form_data.append("operation", operationId);
      form_data.append("score", correctAnswers);

      enrolledOperationService
        .update(enrolledModelOperation, form_data)
        .then((res) => {
          alertService.success(`${t("message.quizcompleted")}`, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        })
        .catch((err) => {
          alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        });
    }
  };

  if (percentage >= 1) {
    return (
      <CongratulationModel
        badge={t("message.goldBadge")}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        image={BadgeIcon.SOURCE}
      />
    );
  } else {
    return <FailedModel isVisible={isVisible} setIsVisible={setIsVisible} />;
  }
};

const QuizModel = ({
  isVisible,
  setIsVisible,
  quizes,
  enrolledModel,
  enrolledModelOperation,
  operationId,
}) => {
  const [correctAnswer, setCorrectAnswer] = useState("");

  const [quizQuestions, setQuizQuestions] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState();
  const [answers, setAnswers] = useState([]);

  const getCurrentQuizIndex = () => {
    const checkIndex = (item) => item === currentQuestion;

    return quizQuestions.findIndex(checkIndex);
  };

  const changeQuestion = () => {
    const answer = {
      question: currentQuestion.question,
      answer: correctAnswer == currentQuestion.correct_answer,
    };

    setAnswers((answers) => [...answers, answer]);
    if (getCurrentQuizIndex() < quizQuestions.length - 1) {
      const idx = getCurrentQuizIndex() + 1;

      setCurrentQuestion(quizQuestions[idx]);
    }
  };

  useEffect(() => {
    setAnswers("");
    let quizCollection = [];
    const map = {
      A: "choice1",
      B: "choice2",
      C: "choice3",
      D: "choice4",
    };

    for (let i = 0; i < quizes?.length; i++) {
      const item = {
        question: quizes[i].question,
        choice1: quizes[i].choice1,
        choice2: quizes[i].choice2,
        choice3: quizes[i].choice3,
        choice4: quizes[i].choice4,
        correct_answer: quizes[i][map[quizes[i].correct]],
      };

      quizCollection.push(item);
    }
    setQuizQuestions(quizCollection);
    setCurrentQuestion(quizCollection[0]);
  }, [quizes]);

  return (
    <ModalLayout
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      className="custom-modal box-shadow-primary"
    >
      {answers.length < quizQuestions.length ? (
        <div className="quiz-modal">
          <div className="modal-header quiz-header py-3 px-5">
            {t("label.quiz")}
          </div>

          <div className="flex-grow-1 h-100 content d-flex flex-column justify-content-end">
            <div className="edit-form py-4 ps-5">
              <QuizQuestions
                question={currentQuestion}
                setCurrentAnswer={setCorrectAnswer}
              />
            </div>
            <div className="total-quiz d-flex justify-content-center align-items-center mb-4">
              <p>
                {t("label.total")} {t("label.quiz")}:
              </p>
              <p className="count ms-2">
                <span>{getCurrentQuizIndex() + 1}</span>
                {`/${quizQuestions.length}`}
              </p>
            </div>
            <div className="modal-footer px-5 py-3 mt-auto d-flex jusitfy-content-end">
              <button
                className="btn btn-secondary px-3 py-2 mx-2 text-dark"
                onClick={() => setIsVisible(false)}
              >
                {t("action.cancel")}
              </button>
              <button
                className="btn btn-primary px-3 py-2 ms-2"
                onClick={() => changeQuestion()}
              >
                {t("buttons.Next")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Result
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          answers={answers}
          setAnswers={setAnswers}
          enrolledModel={enrolledModel}
          enrolledModelOperation={enrolledModelOperation}
          operationId={operationId}
        />
      )}
    </ModalLayout>
  );
};

export default QuizModel;
