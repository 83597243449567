import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { alertService } from "services/alertService";
import { authService } from "services/authService";
import { occupationService } from "services/occupationService";
import { userService } from "services/userService";

import UserLayout from "components/UserLayout/UserLayout";

import { ProfileIcon } from "constants/icons";
import { SITE } from "constants/routes";

import { AuthContext } from "context-api/AuthContext";

export default function Profile() {
  const { t } = useTranslation();
  const history = useHistory();
  const { setuserUpdated, userUpdated } = useContext(AuthContext);
  const [isEditable, setIsEditable] = useState(true);
  const [prevImage, setPrevImage] = useState();
  const [userDetails, setUserDetails] = useState({
    email: "",
    medical_license_number: "",
    password1: "",
    password2: "",
    name: "",
    surname: "",
    katakana1: "",
    katakana2: "",
    occupation: "",
    work_place: "",
    address: "",
    dob: "",
    gender: "",
    phone: "",
  });
  const [occupations, setOccupations] = useState([]);

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handlePictureChange = (e) => {
    if (e.target.name === "image") {
      setUserDetails({
        ...userDetails,
        [e.target.name]: e.target.files[0],
      });
      setPrevImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();

    !!userDetails?.image &&
      form_data.append("image", userDetails.image, userDetails.image.name);
    form_data.append("surname", userDetails.surname);
    form_data.append("name", userDetails.name);
    form_data.append("email", userDetails.email);
    form_data.append("katakana1", userDetails.katakana1);
    form_data.append("katakana2", userDetails.katakana2);
    form_data.append(
      "medical_license_number",
      userDetails.medical_license_number
    );
    form_data.append("occupation", userDetails.occupation);
    form_data.append("work_place", userDetails.work_place);
    form_data.append("address", userDetails.address);
    form_data.append("dob", userDetails.dob);
    form_data.append("phone", userDetails.phone);
    form_data.append("gender", userDetails.gender);

    authService
      .updateUserProfile(userDetails?.id, form_data)
      .then(() => {
        setIsEditable(true);
        setuserUpdated(!userUpdated);
        setUserDetails({});
        alertService.success(
          `${t("label.user")} ${t("message.updatedSuccessfully")}`,
          {
            keepAfterRouteChange: true,
            autoClose: true,
          }
        );
      })
      .catch((err) => {
        alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
      });
  };

  useEffect(() => {
    occupationService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setOccupations(x));
  }, []);

  useEffect(() => {
    let userToken = localStorage.getItem("token");

    userService.getUserToken(userToken).then((x) => {
      setUserDetails({
        id: x.id,
        email: x.email,
        medical_license_number: x.medical_license_number,
        password1: x.password1,
        password2: x.password2,
        name: x.name,
        surname: x.surname,
        katakana1: x.katakana1,
        katakana2: x.katakana2,
        occupation: x.occupation,
        work_place: x.work_place,
        address: x.address,
        dob: x.dob,
        gender: x.gender,
        phone: x.phone,
      });
      setPrevImage(x.image);
    });
  }, [userUpdated, isEditable]);

  const occupationOptions = occupations?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  return (
    <UserLayout>
      <div className="profile container my-5">
        <div className="profile-section box-shadow-primary">
          <div className="alert-section rounded-top"></div>
          <div className="profile-details p-5 position-relative">
            <div className="profile-avatar position-absolute d-flex align-items-end">
              <div className="image-section rounded-circle position-relative overflow-hidden">
                <img
                  src={prevImage ? prevImage : ProfileIcon.SOURCE}
                  alt="profile image"
                  className="profile-picture"
                />
                {!isEditable && (
                  <div className="change-profile-image text-white pt-1 pb-4 px-3 text-center position-absolute cursor-pointer">
                    <input
                      type="file"
                      name="image"
                      id="update-profile"
                      className="d-none"
                      onChange={(e) => handlePictureChange(e)}
                    />
                    <label htmlFor="update-profile">
                      <p className="cursor-pointer">Change Image</p>
                    </label>
                  </div>
                )}
              </div>
              <div className="user-details pb-4 ms-2">
                <p className="name p-0">{userDetails?.name}</p>
                <p className="email p-0">{userDetails?.furigana}</p>
                <p className="email p-0">{userDetails?.email}</p>
              </div>
            </div>
            <div className="mt-5 pt-3">
              <div className="d-block edit-title d-flex justify-content-between py-2">
                <p className=""> {t("message.editYourInformation")}</p>
                <div className="d-flex justify-content-between align-items-center">
                  {isEditable && (
                    <button
                      className="btn edit-button px-4"
                      onClick={() => setIsEditable(false)}
                    >
                      {t("buttons.edit")}
                    </button>
                  )}
                  <button
                    className="btn btn-primary ms-3"
                    onClick={() => history.push(SITE.ChangePassword.path)}
                  >
                    {t("label.changePassword")}
                  </button>
                </div>
              </div>
              <Form className="row mt-2">
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.UserIdEmail")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="email"
                    onChange={(e) => handleChange(e)}
                    value={userDetails?.email}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.surname")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="surname"
                    onChange={(e) => handleChange(e)}
                    value={userDetails?.surname}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.name")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={userDetails?.name}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.surnamek")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="katakana2"
                    value={userDetails?.katakana2}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.namek")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="katakana1"
                    onChange={(e) => handleChange(e)}
                    value={userDetails?.katakana1}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.med_lic_no")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="medical_license_number"
                    onChange={(e) => handleChange(e)}
                    value={userDetails?.medical_license_number}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.occupation")}</Form.Label>
                  <Form.Select
                    className="input-field"
                    name="occupation"
                    defaultValue={userDetails?.occupation?.id}
                    onChange={(e) => handleChange(e)}
                    disabled={isEditable}
                  >
                    {occupationOptions}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.placeofwork")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="work_place"
                    onChange={(e) => handleChange(e)}
                    value={userDetails?.work_place}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.address")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="address"
                    onChange={(e) => handleChange(e)}
                    value={userDetails?.address}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.dob")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="date"
                    name="dob"
                    value={userDetails?.dob}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.phoneNum")}</Form.Label>
                  <Form.Control
                    className="input-field"
                    disabled={isEditable}
                    type="text"
                    name="phone"
                    onChange={(e) => handleChange(e)}
                    value={userDetails?.phone}
                  />
                </Form.Group>
                <Form.Group
                  className={`mt-3 col-lg-4 col-md-6 col-sm-12 ${
                    isEditable && "editing"
                  }`}
                >
                  <Form.Label>{t("label.form.gender")}</Form.Label>

                  <Form.Select
                    disabled={isEditable}
                    defaultValue={userDetails?.gender}
                    className="input-field"
                    onChange={(e) => handleChange(e)}
                    name="gender"
                  >
                    <option value={"M"}>{t("label.Male")}</option>
                    <option value={"F"}>{t("label.Female")}</option>
                    <option value={"O"}>{t("label.Others")}</option>
                  </Form.Select>
                </Form.Group>
              </Form>
              {!isEditable && (
                <div className="update-section mt-4">
                  <button
                    className="btn edit-button  px-5"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {t("action.update")}
                  </button>
                  <button
                    className="btn btn-secondary ms-3  px-5"
                    onClick={() => {
                      setIsEditable(true);
                    }}
                  >
                    {t("action.cancel")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
