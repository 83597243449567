import { useEffect } from "react";

const ModalLayout = ({
  isVisible,
  className,
  children,
  footer = null,
  overlayClassName = "",
  onClose = () => {},
}) => {
  useEffect(() => {
    if (!isVisible) {
      return;
    }

    document.querySelector("body").classList.add("overflow-hidden");

    return () => {
      document.querySelector("body").classList.remove("overflow-hidden");
    };
  }, [isVisible]);

  return (
    isVisible && (
      <div
        className={`overlay w-100 h-100 overflow-auto position-fixed py-5 ${overlayClassName}`}
        onClick={() => onClose()}
      >
        <div className="d-flex align-items-center justify-content-center pt-5">
          <div
            className={`${className} overflow-hidden card border-0`}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default ModalLayout;
