import React from "react";

import UserProfileDropdown from "components/UserDropdown/UserProfileDropdown";

import { BrandLogo, HamburgerIcon } from "constants/icons";

const Header = ({ setCollapsible, collapsible }) => (
  <div className="header-section d-flex justify-content-between align-items-center px-4">
    <div className="d-flex align-items-center">
      <div
        className="menu-toggle ms-3"
        role="presentation"
        onClick={() => setCollapsible(!collapsible)}
        onKeyDown={() => setCollapsible(!collapsible)}
      >
        <img
          src={HamburgerIcon.SOURCE}
          alt="hamburger"
          className="hamburger-menu m-2"
        />
      </div>
      <img
        src={BrandLogo.SOURCE}
        alt="brand logo"
        className="brand-logo mx-2"
      />
    </div>
    <UserProfileDropdown />
  </div>
);

export default Header;
