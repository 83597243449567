import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { alertService } from "services/alertService";
import { authService } from "services/authService";
import { occupationService } from "services/occupationService";

import { BrandLogo, PasswordHideIcon, PasswordShowIcon } from "constants/icons";
import { RightArrow } from "constants/icons";
import { SITE } from "constants/routes";

import { t } from "i18next";

import { useValidation } from "utils/validation";

const ValidationSchemas = [
  {
    name: "password1",
    validate: (val) =>
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!#%/.*?&])[A-Za-z\d@$!#/%*.?&]{8,}$/.test(
        val
      ),
    message: t("message.mineightCharacter"),
  },
  {
    name: "medical_license_number",
    validate: (val) => !/[^A-Za-z0-9]+/.test(val),
    message: t("message.medicallicensenoSpecialChar"),
  },
  {
    name: "email",
    validate: (email) => /\S+@\S+\.\S+/.test(email),
    message: t("label.invalidEmail"),
  },
  {
    name: "phone",
    validate: (email) => /^\+?\d{10,}$/.test(email),
    message: t("label.invalidPhoneNumber"),
  },
];

const REQUIRED_FIELDS1 = [
  "name",
  "surname",
  "email",
  "password1",
  "medical_license_number",
];

const REQUIRED_FIELDS2 = ["occupation", "work_place"];

export default function Registration() {
  const { t } = useTranslation();

  return (
    <div className="landing-page-wrapper">
      <div className="registration-page d-block d-lg-flex justify-content-center align-items-center">
        <div className="container registration-container">
          <div className="row">
            <div className="col-md-12 col-lg-4 p-0">
              <div className="company-branding d-flex justify-content-center align-items-center h-100">
                <div className="img-wrapper">
                  <img src={BrandLogo.SOURCE} alt="brand logo" />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-8 p-0">
              <div className="sign-up-form-wrapper p-md-5 p-3">
                <div className="mb-3">
                  <h3>{t("label.SignUp")}</h3>
                  <p>{t("message.pleaseEnterInformation")}</p>
                </div>
                <RegistrationForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const RegistrationForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notMatchError, setNoMatchError] = useState(null);
  const [page, setPage] = useState(1);
  const [occupations, setOccupations] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    medical_license_number: "",
    password1: "",
    password2: "",
    name: "",
    surname: "",
    katakana1: "",
    katakana2: "",
    occupation: "",
    work_place: "",
    address: "",
    dob: "",
    gender: "M",
    phone: "",
  });
  const [termsChecked, setTermsChecked] = useState(false);

  const errors = useValidation({
    schema: ValidationSchemas,
    value: userDetails,
  });

  const handleChange = (event) => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    authService
      .register({
        ...userDetails,
        password2: confirmPassword,
        clientId: "70V28QzmFEC47AN4gOAODP9Kku3MTukC",
      })
      .then((res) => {
        setUserDetails("");
        alertService.success(res.detail, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setSubmitting(false);
        history.push(SITE.VerificationSent.path);
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    occupationService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setOccupations(x));
  }, []);

  useEffect(() => {}, [formSubmit]);

  const setRePassword = (value) => {
    if (value === userDetails?.password1) {
      setNoMatchError("");
      setConfirmPassword(value);
    } else {
      setNoMatchError(t("message.paswordMatchFailed"));
    }
  };

  useEffect(() => {
    let isSubmitActive = true;

    if (page === 1) {
      REQUIRED_FIELDS1.forEach((item) => {
        if (!userDetails[item]) {
          isSubmitActive = false;
        }
      });
    } else {
      REQUIRED_FIELDS2.forEach((item) => {
        if (!userDetails[item]) {
          isSubmitActive = false;
        }
      });
    }
    Object.keys(errors).forEach((key) => {
      if (errors[key]) isSubmitActive = false;
    });
    setFormSubmit(isSubmitActive);
  }, [userDetails, errors]);

  const occupationOptions = occupations?.map((x, index) => (
    <option value={x.id} key={index}>
      {x.name}
    </option>
  ));

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        {page === 1 ? (
          <>
            <Form.Group className="col-md-6 col-sm-12">
              <Form.Label>{t("label.form.UserIdEmail")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="email"
                value={userDetails?.email}
                onChange={(e) => handleChange(e)}
                errors={errors.email}
              />
              {errors.email && (
                <Form.Text className="text-error">{errors.email}</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 no-wrap">
              <Form.Label>{t("label.form.med_lic_no")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="medical_license_number"
                value={userDetails?.medical_license_number}
                onChange={(e) => handleChange(e)}
              />
              {errors.medical_license_number && (
                <Form.Text className="text-error">
                  {errors.medical_license_number}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.password")}</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password1"
                  placeholder="*******"
                  value={userDetails?.password}
                  className="password-field input-field"
                  onChange={(e) => handleChange(e)}
                />
                <InputGroup.Text
                  className="icons"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <img src={PasswordHideIcon.SOURCE} alt="hide password" />
                  ) : (
                    <img src={PasswordShowIcon.SOURCE} alt="show password" />
                  )}
                </InputGroup.Text>
              </InputGroup>
              {errors.password1 && (
                <Form.Text className="text-error">{errors.password1}</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.confirmPassword")}</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showRePassword ? "text" : "password"}
                  placeholder="********"
                  name="password2"
                  className="password-field input-field"
                  onChange={(e) => setRePassword(e.target.value)}
                />
                <InputGroup.Text
                  className="icons"
                  onClick={() => setShowRePassword(!showRePassword)}
                >
                  {showRePassword ? (
                    <img src={PasswordHideIcon.SOURCE} alt="hide password" />
                  ) : (
                    <img src={PasswordShowIcon.SOURCE} alt="show password" />
                  )}
                </InputGroup.Text>
              </InputGroup>
              {notMatchError !== "" && (
                <Form.Text className="text-error">{notMatchError}</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.name")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="name"
                value={userDetails?.name}
                onChange={(e) => handleChange(e)}
              />
              {errors.name && (
                <Form.Text className="text-error">{errors.name}</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.surname")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="surname"
                value={userDetails?.surname}
                onChange={(e) => handleChange(e)}
              />
              {errors.surname && (
                <Form.Text className="text-error">{errors.surname}</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.namek")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="katakana1"
                value={userDetails?.katakana1}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.surnamek")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="katakana2"
                value={userDetails?.katakana2}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <div className=" mt-4 d-flex flex-row-reverse align-items-center">
              <button
                className="d-flex flex-row-reverse bg-transparent"
                disabled={!formSubmit || notMatchError !== ""}
                onClick={() => setPage(2)}
              >
                <img src={RightArrow.SOURCE} alt="continue" />
                <p className="align-self-center mb-0 me-2">
                  {t("buttons.Next")}
                </p>
              </button>
            </div>
          </>
        ) : (
          <>
            <Form.Group className="col-md-6 col-sm-12">
              <Form.Label>{t("label.form.occupation")}</Form.Label>
              <Form.Select
                className="input-field"
                name="occupation"
                defaultValue={userDetails?.occupation}
                onChange={(e) => handleChange(e)}
              >
                <option value="">
                  {t("action.select")}&nbsp;
                  {t("label.form.occupation")}
                </option>
                {occupationOptions}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12">
              <Form.Label>{t("label.form.placeofwork")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="work_place"
                value={userDetails?.work_place}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.address")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="address"
                value={userDetails?.address}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.dob")}</Form.Label>
              <Form.Control
                className="input-field"
                type="date"
                name="dob"
                value={userDetails?.dob}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.gender")}</Form.Label>
              <Form.Select
                defaultValue={userDetails?.gender}
                className="input-field"
                onChange={(e) => handleChange(e)}
                name="gender"
                required
              >
                <option value="">
                  {t("action.select")}&nbsp;
                  {t("label.form.gender")}
                </option>
                <option value={"M"}>{t("label.Male")}</option>
                <option value={"F"}>{t("label.Female")}</option>
                <option value={"O"}>{t("label.Others")}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 mt-lg-2">
              <Form.Label>{t("label.form.phoneNum")}</Form.Label>
              <Form.Control
                className="input-field"
                type="text"
                name="phone"
                value={userDetails?.phone}
                onChange={(e) => handleChange(e)}
              />
              {errors.phone && (
                <Form.Text className="text-error">{errors.phone}</Form.Text>
              )}
            </Form.Group>
            <div className="mt-4">
              <Form.Group className="d-flex flex-row align-items-center cursor-pointer">
                <Form.Check
                  onChange={(e) => setTermsChecked(e.target.checked)}
                  type="checkbox"
                  label={t("label.form.AgreeTandC")}
                  style={{
                    "accent-color": "#e74c3c",
                  }}
                />
              </Form.Group>
              <button
                className="w-100 b-none registration-button mt-4"
                type="submit"
                disabled={!formSubmit || !termsChecked || isSubmitting}
              >
                {isSubmitting
                  ? `${t("label.signingUp")} ...`
                  : t("label.SignUp")}
              </button>
            </div>
          </>
        )}
      </div>
    </Form>
  );
};
