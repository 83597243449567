import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { alertService } from "services/alertService";
import { authService } from "services/authService";

import { BrandLogo, PasswordHideIcon, PasswordShowIcon } from "constants/icons";
import { ADMIN } from "constants/routes";

import { t } from "i18next";

import { useValidation } from "utils/validation";

const ValidationSchemas = [
  {
    name: "email",
    validate: (email) => /\S+@\S+\.\S+/.test(email),
    message: t("message.invalidEmail"),
  },
  {
    name: "password1",
    validate: (val) =>
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!#%/.*?&])[A-Za-z\d@$!#/%*.?&]{8,}$/.test(
        val
      ),
    message: t("message.mineightCharacter"),
  },
];

const REQUIRED_FIELDS = ["name", "furigana", "email", "password1"];

export default function Registration() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formSubmit, setFormSubmit] = useState(true);
  const [notMatchError, setNoMatchError] = useState(null);
  const [userDetails, setUserDetails] = useState({
    name: "",
    furigana: "",
    email: "",
    password1: "",
  });

  const errors = useValidation({
    schema: ValidationSchemas,
    value: userDetails,
  });

  const handleChange = (name, value) => {
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const setRePassword = (value) => {
    if (value === userDetails?.password1) {
      setNoMatchError("");
      setConfirmPassword(value);
    } else {
      setNoMatchError(t("message.paswordMatchFailed"));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    authService
      .register({
        ...userDetails,
        password2: confirmPassword,
        clientId: "8g3UTfqPNZDBhtuu9EqV9mm5FScX0Fa4",
      })
      .then((res) => {
        setUserDetails("");
        alertService.success(res.detail, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setSubmitting(false);
        history.push(ADMIN.VerificationSent.path);
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    let isSubmitActive = true;

    REQUIRED_FIELDS.forEach((item) => {
      if (!userDetails[item]) {
        isSubmitActive = false;
      }
    });
    Object.keys(errors).forEach((key) => {
      if (errors[key]) isSubmitActive = false;
    });
    setFormSubmit(isSubmitActive);
  }, [userDetails, errors]);

  return (
    <div className="landing-page-wrapper">
      <div className="registration-page  d-block d-lg-flex justify-content-center align-items-center">
        <div className="container registration-container">
          <div className="row">
            <div className="col-md-12 col-lg-4 p-0">
              <div className="company-branding d-flex justify-content-center align-items-center h-100">
                <div className="img-wrapper">
                  <img src={BrandLogo.SOURCE} alt="brand logo" />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-8">
              <div className="sign-up-form-wrapper p-md-5 p-3">
                <div className="mb-3">
                  <h3>{t("label.SignUp")}</h3>
                  <p>{t("message.pleaseEnterInformation")}</p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>{t("label.form.name")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder={`${t("action.enter")} ${t(
                        "label.form.name"
                      )}`}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mt-2">
                      {t("label.form.furigana")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="furigana"
                      placeholder={`${t("action.enter")} ${t(
                        "label.form.furigana"
                      )}`}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mt-2">
                      {t("label.form.UserIdEmail")}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={`${t("action.enter")} ${t(
                        "label.form.email"
                      )}`}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                    {errors.email && (
                      <Form.Text className="text-error">
                        {errors.email}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-2">
                      {t("label.form.password")}
                    </Form.Label>{" "}
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password1"
                        placeholder="*******"
                        className="password-field"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                      <InputGroup.Text
                        className="icons"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <img
                            src={PasswordHideIcon.SOURCE}
                            alt="hide password"
                          />
                        ) : (
                          <img
                            src={PasswordShowIcon.SOURCE}
                            alt="show password"
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.password1 && (
                      <Form.Text className="text-error">
                        {errors.password1}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mt-2">
                      {t("label.form.confirmPassword")}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showRePassword ? "text" : "password"}
                        placeholder="*******"
                        className="password-field"
                        onChange={(e) => setRePassword(e.target.value)}
                      />
                      <InputGroup.Text
                        className="icons"
                        onClick={() => setShowRePassword(!showRePassword)}
                      >
                        {showRePassword ? (
                          <img
                            src={PasswordHideIcon.SOURCE}
                            alt="hide password"
                          />
                        ) : (
                          <img
                            src={PasswordShowIcon.SOURCE}
                            alt="show password"
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    {notMatchError !== "" && (
                      <Form.Text className="text-error">
                        {notMatchError}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <button
                    className="w-100 b-none registration-button mt-4"
                    disabled={
                      !formSubmit || notMatchError !== "" || isSubmitting
                    }
                    type="submit"
                  >
                    {isSubmitting
                      ? `${t("label.signingUp")} ...`
                      : t("label.SignUp")}
                  </button>
                  <p className="text-center my-2">
                    {t("label.alreadyHaveAccount")}
                    <span
                      className="ms-2 text-primary cursor-pointer"
                      role="button"
                      onClick={() => history.push(ADMIN.Auth.path)}
                    >
                      {t("label.Login")}
                    </span>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
