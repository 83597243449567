import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Form, InputGroup } from "react-bootstrap";

import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faHeart as faHeartSolid,
  faPaperPlane,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertService } from "services/alertService";
import { enrolledModelService } from "services/enrolledModelService";
import { enrolledOperationService } from "services/erolledOperationService";
import { modelService } from "services/modelService";
import { operationService } from "services/operationService";

import Alertdetail from "components/Alert/Alertdetail";
import QuizModel from "components/Modal/QuizModel";
import UserLayout from "components/UserLayout/UserLayout";

import { LockIcon, PDFIcon } from "constants/icons";

import { AuthContext } from "context-api/AuthContext";

import { t } from "i18next";

const ModelDetail = ({ match }) => {
  let modelid = "";

  if (match !== undefined) {
    modelid = match.params.id;
  } else {
    modelid = null;
  }

  const { currentUser } = useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);
  const [isLearning, setIsLearning] = useState(false);
  const [operations, setOperations] = useState([]);
  const [modelDetails, setModelDetails] = useState({});
  const [currentOperation, setCurrentOperation] = useState(null);
  const [quizes, setQuizes] = useState([]);
  const [comment, setComment] = useState("");
  const [enrolledModel, setEnrolledModel] = useState();
  const [enrolledModelOperation, setEnrolledModelOperation] = useState();

  const [commentList, setCommentList] = useState([
    {
      id: 1,
      commentedBy: "user4765",
      content:
        " Aspernatur molestias ipsa possimus, atque omnis cupiditate saepe, aliquam at minima, accusamus sit amet sapiente nostrum quidem quasi? Veritatis cupiditate odio nobis",
      reaction: true,
    },
    {
      id: 2,
      commentedBy: "user999",
      content:
        " Aspernatur amet sapiente nostrum quidem quasi? Veritatis cupiditate odio nobis",
      reaction: false,
    },
  ]);

  const handleReaction = (e, index) => {
    let temp = [...commentList];

    temp[index - 1].reaction = e.target.checked;
    setCommentList(temp);
  };

  const addComment = () => {
    let temp = [...commentList];

    temp.push({
      id: temp[temp.length - 1].id + 1,
      commentedBy: "user999",
      content: comment,
      reaction: false,
    });
    setCommentList(temp);
    setComment("");
  };

  const onLearningStart = (e) => {
    e.preventDefault();
    let form_data = new FormData();

    if (modelid) form_data.append("model", modelid);
    if (currentUser) form_data.append("user", currentUser.id);

    enrolledModelService
      .create(form_data)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          alertService.success(
            `${t("label.model")} ${t("message.addedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setEnrolledModel(res);
          setCurrentOperation(operations[0]);
          setIsLearning(true);
        }
      })
      .catch((err) => {
        alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
      });
  };

  useEffect(() => {
    // curent Model
    modelService.getById(modelid).then((x) => {
      setModelDetails(x);
    });
    //operations of model
    operationService
      .get(`?paginate=false&orderBy=desc&sortBy=id&model_id=${modelid}`)
      .then((x) => {
        setOperations(x);
      });
    // enrolled Model
    enrolledModelService.get(`?paginate=false&modelId=${modelid}`).then((x) => {
      setEnrolledModel(x[0]);
    });
  }, [modelid]);

  useEffect(() => {
    // check if enrolled
    enrolledModelService
      .get(`?paginate=false&userId=${currentUser.id}`)
      .then((res) => {
        if (res.filter((item) => item.model.id == modelid).length) {
          setIsLearning(true);
          setCurrentOperation(operations[0]);
        }
      })
      .catch((err) => {
        alertService.error(err, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
      });
  }, [operations]);

  useEffect(() => {
    setQuizes(currentOperation?.operations);

    if (currentOperation && enrolledModel) {
      if (
        enrolledModel.enrolled_model_operation.filter(
          (item) => item.operation.id == currentOperation?.id
        ).length < 1
      ) {
        let form_data = new FormData();

        form_data.append("enrolledModel", enrolledModel?.id);
        form_data.append("operation", currentOperation?.id);

        enrolledOperationService
          .create(form_data)
          .then((res) => {
            setEnrolledModelOperation(res.data);
            if (res.status === 201 || res.status === 200) {
              alertService.success(`${t("message.enrolledSuccesfully")}`, {
                keepAfterRouteChange: true,
                autoClose: true,
              });
            } else {
              alertService.error(res.data, {
                keepAfterRouteChange: true,
                autoClose: true,
              });
            }
          })
          .catch((err) => {
            alertService.error(err, {
              keepAfterRouteChange: true,
              autoClose: true,
            });
          });
      }
    }

    if (currentOperation?.video) {
      const video = document.getElementById("operation-video");

      video.currentTime = 0;
      video.load();
      video.pause();
    }
  }, [currentOperation, isLearning]);

  return (
    <UserLayout>
      <div className="model-detail-container px-4">
        <div className="video-grid">
          <div className="row">
            <div className="col-xl-8 col-md-12">
              <div className="current-video">
                {!isLearning ? (
                  <div className="position-relative h-100">
                    <img
                      src={operations[0]?.thumbnail}
                      alt="current Video"
                      height={300}
                    ></img>
                    <div
                      className="position-absolute start-learning p-3 cursor-pointer"
                      onClick={(e) => onLearningStart(e)}
                    >
                      <div className="position-relative d-flex align-items-center">
                        <FontAwesomeIcon icon={faPlayCircle} className="icon" />
                        <p className="commence ms-3">
                          {t("message.startLearning")}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <video
                    controls
                    autoPlay
                    width={`100%`}
                    height={`100%`}
                    id="operation-video"
                    onEnded={() => setIsVisible(true)}
                  >
                    <source src={currentOperation?.video} type="video/ogg" />
                  </video>
                )}
              </div>

              <div className="video-info mt-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="video-info-title">
                    {currentOperation
                      ? currentOperation.title
                      : modelDetails?.product_name}
                  </h3>
                </div>
                <div>
                  <span>
                    <strong>{t("label.searchForm.productName")}</strong>:
                    {modelDetails?.product_name}
                  </span>
                  <span>
                    <strong>{t("label.searchForm.modelNumber")} </strong>:
                    {modelDetails?.model_number}
                  </span>
                  <span>
                    <strong>{t("label.serviceDuration")} </strong>:{" "}
                    {`${modelDetails?.service_duration} years`}
                  </span>
                </div>
              </div>

              {/* mobile alert nitification */}
              <Alertdetail />
              <div className="d-lg-flex align-items-center justify-content-between py-3 mb-2">
                <div className="mb-3">
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}${modelDetails?.instruction}`}
                    target="_blank"
                    className="d-flex align-items-center"
                  >
                    <img src={PDFIcon.SOURCE} alt="instruction pdf" />
                    <p className="ps-1">{t("label.instructionMannual")}</p>
                  </a>
                </div>
                <div className="mb-3">
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}${modelDetails?.package_insert}`}
                    target="_blank"
                    className="d-flex align-items-center"
                  >
                    <img src={PDFIcon.SOURCE} alt="instruction pdf" />
                    <p className="ps-1">{t("label.packageInsert")}</p>
                  </a>
                </div>
                <div className="mb-3">
                  <p>
                    <strong>{t("label.productInquiry")}</strong>:
                    <a
                      href={modelDetails?.product_inquiry}
                      target="_blank"
                      className="ps-1"
                    >
                      {modelDetails?.product_inquiry}
                    </a>
                  </p>
                </div>
              </div>
              <div className="instruction-section p-4 border rounded-2">
                <h4 className="fw-medium">{t("label.instruction")}</h4>
                <div className="instructions">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: modelDetails?.instruction,
                    }}
                  />
                </div>
              </div>

              {isLearning && (
                <div className="border rounded-2 p-3 d-none">
                  <div className="accordion accordion-flush">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          {t("label.comment")}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        {commentList.map((elem, index) => (
                          <div className="accordion-body" key={index}>
                            <strong>{elem.commentedBy}</strong>
                            <br />
                            {elem.content}

                            <span>
                              <input
                                type="checkbox"
                                id={`heart${elem.id}`}
                                checked={elem.reaction}
                                onClick={(e) => handleReaction(e, elem.id)}
                                onChange={(e) => handleReaction(e, elem.id)}
                              />
                              <label htmlFor={`heart${elem.id}`}>
                                <FontAwesomeIcon
                                  icon={elem.reaction ? faHeartSolid : faHeart}
                                />
                              </label>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <InputGroup className="comment">
                    <Form.Control
                      className="comment-input"
                      onChange={(e) => setComment(e.target.value)}
                      placeholder={t("message.writeacomment")}
                      value={comment}
                    />
                    <button
                      className="comment-button"
                      onClick={() => addComment()}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </InputGroup>
                </div>
              )}
            </div>
            <div className="col-xl-4 col-md-12">
              <div className="next-videos position-relative">
                <h5 className="ms-1 mt-3">{t("label.operation")}</h5>
                <div className="next-videos-list px-2 py-3">
                  <div className="row">
                    {operations?.map((item, idx) => (
                      <div className="col-xl-12 col-md-6" key={idx}>
                        <div
                          className={`next-videos-item ${
                            currentOperation === item ? "active" : ""
                          }`}
                          onClick={() => setCurrentOperation(item)}
                        >
                          <img
                            width="100%"
                            src={item?.thumbnail}
                            alt="next video"
                          />
                          <div className="next-videos-title">{item?.title}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {!isLearning && (
                  <div className="text-center rounded-2 locked-section position-absolute d-flex justify-content-center align-items-center">
                    <div>
                      <img src={LockIcon.SOURCE} alt="locked" />
                      <p className="locked-information px-5 text-white mt-4">
                        {t("message.lockedStateMessage")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <QuizModel
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          quizes={quizes}
          enrolledModel={modelid}
          enrolledModelOperation={enrolledModelOperation?.id}
          operationId={currentOperation?.id}
        />
      </div>
    </UserLayout>
  );
};

export default ModelDetail;
