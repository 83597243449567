import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASE_URL}/api`;
const UPLOAD_ENDPOINT = "ckeditor";

function getCookie(name) {
  let cookieValue = null;

  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?

      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }

  return cookieValue;
}

export function uploadAdapter(loader) {
  const csrftoken = getCookie("csrftoken");
  const token = localStorage.getItem("token");

  return {
    upload: () =>
      new Promise((resolve, reject) => {
        const body = new FormData();

        loader.file.then((file) => {
          body.append("upload", file);
          axios({
            method: "POST",
            url: `${API_URL}/${UPLOAD_ENDPOINT}/upload/`,
            data: body,
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "multipart/form-data",
              "X-CSRFToken": csrftoken,
            },
          })
            .then((res) => res.data)
            .then((res) => {
              resolve({
                default: `${API_URL}${res.url}/`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      }),
  };
}

export function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
    uploadAdapter(loader);
}
