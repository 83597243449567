import React, { useContext, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory } from "react-router-dom";

import { alertService } from "services/alertService";
import { authService } from "services/authService";

import { BrandLogo, PasswordHideIcon, PasswordShowIcon } from "constants/icons";
import { ADMIN } from "constants/routes";

import { AuthContext } from "context-api/AuthContext";

export default function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const { isTokenValidated, setIsLoggedIn } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  if (isTokenValidated) {
    return <Redirect to={{ pathname: ADMIN.Index.path }} />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const { from } = location.state || { from: ADMIN.Index.path };

    authService
      .login(username, password, from)
      .then((user) => {
        if (user.key) {
          localStorage.setItem("token", user.key);
          alertService.success(t("message.loginSuccessfull"), {
            keepAfterRouteChange: true,
            autoClose: true,
          });
          setIsLoggedIn(true);
          setSubmitting(false);
          window.location.href = from;
        } else {
          setInputs("");
        }
      })
      .catch((err) => {
        alertService.error(err + t("message.pleaseEnterValidEmailorPassword"), {
          keepAfterRouteChange: true,
          autoClose: true,
        });
        setSubmitting(false);
      });
  };

  return (
    <div className="landing-page-wrapper">
      <div className="login-page d-block d-lg-flex justify-content-center align-items-center">
        <div className="container login-container">
          <div className="row">
            <div className="col-md-12 col-lg-6 p-0">
              <div className="company-branding d-flex justify-content-center align-items-center h-100">
                <div className="img-wrapper">
                  <img src={BrandLogo.SOURCE} alt="brand logo" />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="login-form-wrapper p-md-5 p-3">
                <div className="mb-3">
                  <h3>{t("label.Login")}</h3>
                  <p>{t("message.pleaseEnterInformation")}</p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>{t("label.form.UserIdEmail")}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={`${t("action.enter")} ${t(
                        "label.form.email"
                      )}`}
                      className="credentials-input"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-2">
                      {t("label.form.password")}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="*******"
                        className="password-field credentials-input"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputGroup.Text
                        className="icons"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <img
                            src={PasswordHideIcon.SOURCE}
                            alt="hide password"
                          />
                        ) : (
                          <img
                            src={PasswordShowIcon.SOURCE}
                            alt="show password"
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="d-flex justify-content-between mt-3">
                    <Form.Check type="checkbox" label={t("label.rememberMe")} />
                    <Link to={ADMIN.ForgotPassword.path}>
                      <p className="text-primary cursor-pointer">
                        {t("label.forgotPass")}
                      </p>
                    </Link>
                  </Form.Group>
                  <button
                    className="w-100 login-button mt-4"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("label.Login")}
                  </button>
                  <p className="text-center my-2">
                    {t("label.dontHaveAccount")}
                    <span
                      className="ms-2 text-primary cursor-pointer"
                      role="button"
                      onClick={() => history.push(ADMIN.Register.path)}
                    >
                      {t("label.SignUp")}
                    </span>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
