import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";

import { faEye, faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { medicalInstituteService } from "services/medicalInstituteService";

import { CustomTable } from "components/Table/CustomTable";

import { ADMIN } from "constants/routes";

import { t } from "i18next";

const TableRow = ({ data, columns }) => {
  const { path } = useRouteMatch();

  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{t("message.noResultsFound")}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.person_in_charge}</td>
      <td className="text-wrap">{pt.name_of_medical_inc}</td>
      <td>
        <Link as={Link} to={`${path}/view/${pt?.id}`}>
          <FontAwesomeIcon icon={faEye} className="me-2" />
          {t("label.viewDetails")}
        </Link>
      </td>
    </tr>
  ));
};

const MedicalInstitution = () => {
  const [medicalInstitute, setmedicalInstitute] = useState({
    count: 2,
    currentPage: 1,
    links: { next: null, previous: null },
    results: [
      {
        person_in_charge: "Shankhar",
        name_of_medical_inc: "Kist college of medical",
      },
      {
        person_in_charge: "Shankhar",
        name_of_medical_inc: "Kist college of medical",
      },
      {
        person_in_charge: "Shankhar",
        name_of_medical_inc: "Kist college of medical",
      },
      {
        person_in_charge: "Shankhar",
        name_of_medical_inc: "Kist college of medical",
      },
      {
        person_in_charge: "Shankhar",
        name_of_medical_inc: "Kist college of medical",
      },
      {
        person_in_charge: "Shankhar",
        name_of_medical_inc: "Kist college of medical",
      },
      {
        person_in_charge: "Shankhar",
        name_of_medical_inc: "Kist college of medical",
      },
      {
        person_in_charge: "Shankhar",
        name_of_medical_inc: "Kist college of medical",
      },
    ],
  });
  const [update, setUpdate] = useState(false);

  const columns = [
    { name: t("label.personIncharge") },
    { name: t("label.affilatedMedicalInstitute") },
    { name: t("label.form.action") },
  ];

  // useEffect(() => {
  //   medicalInstituteService.get("?orderBy=desc&sortBy=id").then((x) => setmedicalInstitute(x));
  // }, [update]);

  function getUpdatedData(pagedata) {
    // medicalInstituteService
    //   .get(
    //     `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
    //   )
    //   .then((x) => setmedicalInstitute(x));
  }

  return (
    <div className="box-shadow-primary p-lg-5 p-3 mt-5">
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("label.medicalInstitution")}</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {t("label.medicalInstitution")} {t("action.list")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("label.medicalInstitution")}</h4>
        </div>
        <div className="addBtn-wrapper mb-4 mb-xl-0">
          <Link
            className="px-3 py-2 btn-primary rounded-1"
            to={`${ADMIN.Users.path}/add/`}
          >
            <FontAwesomeIcon icon={faPlus} /> &nbsp;
            {`${t("action.add")} ${t("label.medicalInstitution")}`}
          </Link>
        </div>
      </div>

      <CustomTable
        columns={columns}
        data={medicalInstitute}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
      >
        <TableRow
          data={medicalInstitute?.results}
          columns={columns}
          setUpdate={setUpdate}
          update={update}
        />
      </CustomTable>
    </div>
  );
};

export default MedicalInstitution;
