import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SITE } from "constants/routes";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-wrap">
      <div className="container">
        <div className="footer-action">
          <ul>
            <li>
              <Link to={SITE.TermsofService.path}>
                {t("label.termsofservice")}
              </Link>
            </li>
            <li>
              <Link to={SITE.PrivacyPolicy.path}>
                {t("label.privacyPolicy")}
              </Link>
            </li>
            <li>
              <Link to={SITE.Inquiry.path}>
                {t("label.inquiry")}
              </Link>
            </li>
            <li>
              <Link to={SITE.FAQ.path}>
                {t("message.frequentlyAskedQuestion")}
              </Link>
            </li>
            <li>{t("message.copyright")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
