import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { genreService } from "services/genreService";

import AchievementCard from "components/AchievementCard/Achievement";
import Maker from "components/Maker";
import Search from "components/Search";
import UserLayout from "components/UserLayout/UserLayout";

import { BannerImage } from "constants/images";

const Home = () => {
  const { t } = useTranslation();

  const [genres, setGenres] = useState([]);

  useEffect(() => {
    genreService
      .get("?paginate=false&orderBy=desc&sortBy=id")
      .then((x) => setGenres(x));
  }, []);

  return (
    <UserLayout>
      <div className="landing-container">
        <div className="banner-section container content-container d-flex align-items-center">
          <img src={BannerImage.SOURCE} alt="Banner " />
          <div className="banner-txt">
            <h1>{t("message.getMedicalToolsReadywithUs")}</h1>
            <h3>{t("message.weareHereToHelpYou")}</h3>
          </div>
        </div>

        <AchievementCard />

        <div className="search-section-wrap">
          <div className="container">
            <Search setSearchTerm={() => {}} />
          </div>
        </div>

        {genres?.map((genre, index) => (
          <Maker genre={genre} key={index} />
        ))}
      </div>
    </UserLayout>
  );
};

export default Home;
