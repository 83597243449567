import axios from "axios";
import { alertService } from "services/alertService";

export const fetchWrapper = {
  get,
  post,
  put,
  setLanguage,
  filter,
  delete: _delete,
};
const csrftoken = getCookie("csrftoken");
const token = localStorage.getItem("token");

function getCookie(name) {
  let cookieValue = null;

  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?

      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }

  return cookieValue;
}

function get(url, params) {
  if (params) {
    return axios.get(`${url}/${params}/`).then(
      (response) => response.data,
      (error) => error
    );
  } else {
    return axios.get(`${url}/`).then(
      (response) => response.data,
      (error) => error
    );
  }
}

function filter(url, params) {
  if (params) {
    return axios.get(`${url}/${params}`).then(
      (response) => response.data,
      (error) => error
    );
  } else {
    return axios.get(`${url}`).then(
      (response) => response.data,
      (error) => error
    );
  }
}

function post(url, body) {
  return axios({
    method: "POST",
    url: `${url}/`,
    data: body,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
      "X-CSRFToken": csrftoken,
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

function setLanguage(url, body) {
  return axios({
    method: "POST",
    url: `${url}`,
    data: body,
    mode: "same-origin",
    config: {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
        "X-CSRFToken": csrftoken,
      },
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

function put(url, body, id) {
  return axios({
    method: "PUT",
    url: `${url}/${id}/`,
    data: body,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
      "X-CSRFToken": csrftoken,
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, id) {
  const headers = { headers: { Authorization: `Token ${token}` } };

  return axios
    .delete(`${url}/${id}/`, headers)
    .then((response) => {
      if (response.data != null) {
        alertService.error(t("message.deletedSuccessfuly"), {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      }
    })
    .catch((err) => err);
}
