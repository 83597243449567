import { fetchWrapper } from "lib/FetchWrapper";

const API_URL = `${process.env.REACT_APP_BASE_URL}/accounts`;
const baseUrl = `${API_URL}/users`;

export const userService = {
  register,
  login,
  forgotPassword,
  logout,
  getUserToken,
  get,
  getAll,
  getById,
  update,
  filter,
  confirmVerification,
  passwordTokenCheckAPI,
  resetPassword,
  delete: _delete,
};

function register(user) {
  // return fetchWrapper.post(user);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${baseUrl}/register/`, requestOptions).then(handleResponse);
}

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${API_URL}/login/`, requestOptions)
    .then(handleResponse)
    .then(
      (user) =>
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        user
    );
}

function logout() {
  // remove user from local storage to log user out
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return fetch(`${baseUrl}/logout/`, requestOptions)
    .then((res) => res.json())
    .then(() => {
      localStorage.removeItem("token");
    });
}

function forgotPassword(email) {
  // remove user from local storage to log user out
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    Authorization: `Token ${localStorage.getItem("token")}`,
    body: JSON.stringify({ email }),
  };

  return fetch(`${baseUrl}/password/reset/`, requestOptions)
    .then(handleResponse)
    .then((res) => res);
}

function getUserToken(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(`${API_URL}/auth/token/`, requestOptions)
    .then(handleResponse)
    .then((res) => res);
}

function confirmVerification(params) {
  return fetchWrapper.get(`${API_URL}/email-verify/${params}`);
}

function passwordTokenCheckAPI(params) {
  return fetchWrapper.get(`${API_URL}/password-reset/${params}`);
}

function resetPassword(params) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };

  return fetch(`${API_URL}/set-new-password/`, requestOptions)
    .then(handleResponse)
    .then((res) => res);
}

function get(params) {
  return fetchWrapper.get(`${baseUrl}/${params}`);
}

function filter(params) {
  return fetchWrapper.filter(`${baseUrl}/${params}`);
}

function getAll() {
  return fetchWrapper.get(`${baseUrl}`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (data.errors) {
      return data.errors;
    }

    if (!response.ok) {
      if (response.status === 401 || response.status === 400) {
        // auto logout if 401 response returned from api
        logout();
        // Location.reload(true);
      }

      const error = (data && data.detail) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}
