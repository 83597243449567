import React, { useState } from "react";

import Header from "components/layout/header";
import Sidebar from "components/layout/sidebar";

const DashboardLayout = ({ children }) => {
  const [collapsible, setCollapsible] = useState(false);

  return (
    <div className="dashboard">
      <Header setCollapsible={setCollapsible} collapsible={collapsible} />

      <div className="d-flex justify-content-start position-relative">
        <Sidebar collapsed={collapsible} setCollapsed={setCollapsible}/>
        <div
          className={`main-content flex-grow-1 ${
            collapsible ? "show" : "hide"
          }`}
        >
          <div className="content-section m-3">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
