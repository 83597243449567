import React, { useEffect, useState } from "react";

import { enrolledModelService } from "services/enrolledModelService";
import { userService } from "services/userService";

import { CustomTable } from "components/Table/CustomTable";

import { BackIcon } from "constants/icons";

import { t } from "i18next";

const dataProgress = (dataModel) => {
  const { enrolled_model_operation, model } = dataModel;

  const completedOperation = enrolled_model_operation.filter(
    (item) => item.operation.model == model.id
  );

  const progress = (completedOperation.length / model.models.length) * 100;

  return progress || 5;
};

const TableRow = ({ data, columns, searchTerm }) => {
  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.created_at}</td>
      <td className="text-wrap">{pt.model.maker.name}</td>
      <td className="text-wrap">{pt.model.product_name}</td>
      <td className="text-wrap">{pt.model.model_number}</td>
      <td className="text-wrap">
        <div className="progressbar-container d-flex align-items-center mt-2">
          <div
            className={`progress-value ${
              dataProgress(pt) === 100 ? "bg-success" : "bg-warning"
            }`}
            style={{ width: `${dataProgress(pt)}%` }}
          ></div>
        </div>
      </td>
      <td className="text-wrap">
        <div
          className={`text-center p-1 ${
            dataProgress(pt) === 100 ? "learning-completed" : "learning-pending"
          }`}
        >
          {dataProgress(pt) === 100 ? "Completed" : "Not Yet"}
        </div>
      </td>
    </tr>
  ));
};

const DetailCard = ({ user }) => {
  if (!user) return null;

  return Object.keys(user).map((key, index) => (
    <div className="col-sm-12 col-md-6 col-lg-3 mb-3" key={index}>
      <div className="box-shadow-primary detail-card p-4">
        <p className="title">{key}</p>
        <p className="number mt-3">{user[key]}</p>
      </div>
    </div>
  ));
};

const UserDetail = ({ match }) => {
  let userid = "";

  if (match !== undefined) {
    userid = match.params.id;
  } else {
    userid = null;
  }

  const [user, setuser] = useState({});

  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    userService.getById(userid).then((x) =>
      setuser({
        Name: x?.name,
        Surname: x?.surname,
        "Katakana (name)": x?.katakana1,
        "katakana (surname)": x?.katakana2,
        "Medical Affiltation Institution": x?.work_place,
        Gender: x?.gender,
        "Date of Birth": x?.dob,
        Address: x?.address,
        "Medical License Number": x?.medical_license_number,
      })
    );
  }, [update]);

  const columns = [
    { name: t("label.date") },
    { name: t("label.searchForm.makerName") },
    { name: t("label.searchForm.productName") },
    { name: t("label.searchForm.modelNumber") },
    { name: t("label.operation") },
    { name: t("label.searchForm.learningCondition") },
  ];

  const [learningList, setlearningList] = useState({});

  useEffect(() => {
    enrolledModelService
      .get(`?orderBy=desc&sortBy=id&userId=${userid}`)
      .then((x) => {
        if (x.results) setlearningList(x);
      });
  }, [userid]);

  function getUpdatedData(pagedata) {
    enrolledModelService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id&userId=${userid}`
      )
      .then((x) => setlearningList(x));
  }


  useEffect(() => {
    if (searchInput)
      enrolledModelService.filter(`?search=${searchInput}`).then((x) => setlearningList(x));
  }, [searchInput]);

  return (
    <div className=" user-details box-shadow-primary p-lg-5 p-3 mt-5">
      <div className="d-flex mb-xl-0 align-items-center">
        <img
          className="align-self-center mb-2 cursor-pointer"
          src={BackIcon.SOURCE}
          onClick={() => history.back()}
        />
        <h4 className="ms-3 user-name">{`${user?.Name} ${user?.Surname}`}</h4>
      </div>
      <div className="my-4">
        <div className="row justify-content-start flex-wrap">
          <DetailCard user={user} />
        </div>
      </div>
      <hr />
      <h4>{t("label.learningList")}</h4>
      <CustomTable
        columns={columns}
        data={learningList}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
        setSearchInput={setSearchInput}
      >
        <TableRow data={learningList?.results} columns={columns} searchTerm={searchInput}/>
      </CustomTable>
    </div>
  );
};

export default UserDetail;
