import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { alertService } from "services/alertService";
import { termsOfService } from "services/termsOfService";

import { CustomTable } from "./CustomTable";

import { DeleteIcon, EditIcon } from "constants/icons";

import { t } from "i18next";

import { uploadPlugin } from "utils/ckeditorUploader";

const TableRow = ({
  data,
  columns,
  deleteCondition,
  setActiveTerm,
  activeTerm,
  searchTerm,
}) => {
  if (!data) {
    return null;
  }

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <h4>{`${t("message.noResultsFound")} for ${searchTerm}`}</h4>
        </td>
      </tr>
    );
  }

  return data?.map((pt, index) => (
    <tr key={`page-traffic-${index}`}>
      <td className="text-wrap">{pt.created_at}</td>
      <td className="text-wrap">
        <span
          dangerouslySetInnerHTML={{
            __html: pt.text
              ? pt.text.length > 50
                ? `${pt.text.substring(0, 50)}...`
                : pt.text
              : "N/A",
          }}
        />
      </td>
      <td className="text-wrap">{pt.updated_at}</td>
      <td className="text-wrap">
        <div className={`action-icons ${pt.id === activeTerm ? "d-none" : ""}`}>
          <img
            src={EditIcon.SOURCE}
            className="ms-2 cursor-pointer"
            onClick={() => setActiveTerm(pt.id)}
          />
          <img
            src={DeleteIcon.SOURCE}
            className="mx-2 cursor-pointer"
            onClick={() => deleteCondition(pt.id)}
          />
        </div>
      </td>
    </tr>
  ));
};

const TermsOfServiceTable = () => {
  const { t } = useTranslation();

  const [activeTerm, setActiveTerm] = useState("");
  const [newTerm, setNewTerm] = useState("");
  const [terms, setTerms] = useState([]);
  const [update, setUpdate] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    { name: t("label.date") },
    { name: t("label.form.name") },
    { name: t("label.updateAt") },
    { name: t("label.form.action") },
  ];

  useEffect(() => {
    termsOfService.get("?orderBy=desc&sortBy=id").then((x) => setTerms(x));
  }, [update]);

  function getUpdatedData(pagedata) {
    termsOfService
      .get(
        `?page_size=${pagedata?.perPage}&page=${pagedata?.page}&orderBy=desc&sortBy=id`
      )
      .then((x) => setTerms(x));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let form_data = new FormData();

    form_data.append("text", newTerm);

    if (!activeTerm) {
      addTerms(form_data);
    } else {
      updateTerms(activeTerm, form_data);
    }
    setActiveTerm(null);
    setNewTerm("");
  };

  const addTerms = (fields) => {
    termsOfService
      .create(fields)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          alertService.success(
            `${t("label.terms")} ${t("message.addedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setNewTerm("");
  };
  const updateTerms = (id, fields) => {
    termsOfService
      .update(id, fields)
      .then((res) => {
        if (res.status === 200) {
          alertService.success(
            `${t("label.terms")} ${t("message.updatedSuccessfully")}`,
            {
              keepAfterRouteChange: true,
              autoClose: true,
            }
          );
          setUpdate(!update);
        } else {
          alertService.error(res.data, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      })
      .catch((err) => {
        alertService.error(err, {
          keepAfterRouteChange: true,
          autoClose: true,
        });
      });
    setActiveTerm("");
    setNewTerm("");
  };

  const deleteCondition = (id) => {
    termsOfService.delete(id).then(() => {
      alertService.success(t("message.deletedSuccessfuly"), {
        keepAfterRouteChange: true,
        autoClose: true,
      });
      setUpdate(!update);
    });
  };

  const inputHandler = (event, editor) => {
    const data = editor.getData();

    setNewTerm(data);
  };

  useEffect(() => {
    if (activeTerm) {
      termsOfService.getById(activeTerm).then((x) => setNewTerm(x.name));
    }
  }, [activeTerm]);

  useEffect(() => {
    if (searchInput)
      termsOfService.filter(`?search=${searchInput}`).then((x) => setTerms(x));
  }, [searchInput]);

  return (
    <div className="p-3  mt-5 h-100">
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-end mb-3">
        <div className="d-block mb-4 mb-xl-0">
          <h4>{t("label.termsOfService")}</h4>
        </div>
      </div>
      <div className="border-bottom pb-3 mb-4">
        <Form className="row" onSubmit={handleSubmit}>
          <Form.Group className="col-12">
            <div className="text-editor h-100 align-self-stretch">
              <Form.Group className="mb-3">
                <Form.Label className="sub-title">
                  {t("label.description")}
                </Form.Label>
                <div className="h-100 ">
                  <CKEditor
                    id="inputText"
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                    editor={ClassicEditor}
                    onChange={inputHandler}
                    data={newTerm}
                  />
                </div>
              </Form.Group>
            </div>
          </Form.Group>
          <div className="mt-3 text-align-center col-12">
            <button className="add-btn px-3 py-2 w-100">
              {`${activeTerm ? t("action.update") : t("action.add")} ${t(
                "label.termsOfService"
              )}`}
            </button>
          </div>
        </Form>
      </div>
      <CustomTable
        columns={columns}
        data={terms}
        setUpdate={setUpdate}
        update={update}
        getUpdatedData={getUpdatedData}
        setSearchInput={setSearchInput}
      >
        <TableRow
          data={terms?.results}
          columns={columns}
          setActiveTerm={setActiveTerm}
          activeTerm={activeTerm}
          deleteCondition={deleteCondition}
          searchTerm={searchInput}
        />
      </CustomTable>
    </div>
  );
};

export default TermsOfServiceTable;
